import React, { useState, useEffect } from "react";
import {
  GET_CAMPAIGNS_DETAILS_OF_USER,
  GET_CREDIT_POINTS_OF_USER,
  UPDATE_USED_CREDIT_POINTS,
  getFullApiUrl,
  BASE_API_URL_MAIN,
  GET_CAMPAIGN_DATA,
  CHECK_USER_CAMPAIGN_ACCESS,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
} from "../../utils/apiPath";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import {
  Button,
  TextField,
  Typography,
  Snackbar,
  Box,
  Breadcrumbs,
  Link,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import Robo from "../common/Robo/Robo";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
// import ListHeader from './../common/ListHeader';
import LinkInsertionCampaignFlow from "./../components/LinkInsertionCampaignFlow";
import SerperSearchComponent from "../CheckActiveBloggersUsingSerperPage/SerperSearchComponent/SerperSearchComponent";
import ClassifyResultPagesComponent from "../ClassifyResultPages/ClassifyResultPagesComponent";
import PageQualityCheckComponent from "../PageQualityCheckPage/PageQualityCheckComponent";
import flow_icon from "../../assets/icons/flow_icon.png";
import "../LinkInsertionCampaignPage/LinkInsertionCampaignPage.css";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";
import { FaProjectDiagram } from "react-icons/fa";
import { NavigateNext } from "@mui/icons-material";
import CheckActiveBloggersUsingApifyPage from "../CheckActiveBloggersUsingApifyPage/CheckActiveBloggersUsingApifyPage";

const MyWorkspaceCampaign = ({ toggleTheme }) => {
  const { campaignId } = useParams();
  const navigate = useNavigate();

  const [campaignDetails, setCampaignDetails] = useState({});
  const [totalCreditPointsLimitThisWeek, setTotalCreditPointsLimitThisWeek] =
    useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [totalCreditPointsOfUser, setTotalCreditPointsOfUser] = useState();

  const [usedCreditPointsThisWeek, setUsedCreditPointsThisWeek] = useState(0);

  const fetchData = async () => {
    await fetchCampaignDetails();
  };
  useEffect(() => {
    fetchUserCreditPoints();
    fetchData();
  }, [1000]);

  // useEffect(() => {
  //   if (campaignDetails.team_member) {
  //     setUsedCreditPointsThisWeek(campaignDetails.team_member.used_credit_points_this_week);
  //     setTotalCreditPointsLimitThisWeek(campaignDetails.team_member.credit_points_week_limit);
  //   }
  // }, [campaignDetails]);

  const fetchCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_CAMPAIGNS_DETAILS_OF_USER
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem("user_id")}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCampaignDetails(data);
      // setTotalCreditPointsOfUser(data.credit_points)
      setTotalCreditPointsLimitThisWeek(data.credit_points_week_limit);
      setUsedCreditPointsThisWeek(data.used_credit_points_this_week);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchUserCreditPoints = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_CREDIT_POINTS_OF_USER
        )}?user_id=${localStorage.getItem("user_id")}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTotalCreditPointsOfUser(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeductPoints = async (points) => {
    if (campaignDetails.status !== "Progress") {
      setNotificationMessage(
        "Campaign implementation has not started. Please update the status to 'Progress' to proceed."
      );
      setOpenSnackbar(true);
      return;
    }
    if (
      totalCreditPointsOfUser < points ||
      totalCreditPointsLimitThisWeek < points
    ) {
      setNotificationMessage("Insufficient Credit Points");
      setOpenSnackbar(true);
      return;
    }

    const updatedPoints = totalCreditPointsLimitThisWeek - points;
    const updatedUsedPoints = usedCreditPointsThisWeek + points;

    if (updatedPoints < 0) {
      setNotificationMessage(
        "Deduction exceeds the total credit points week limit. Ask your owner to refill this"
      );
      setOpenSnackbar(true);
    } else {
      try {
        const user_id = localStorage.getItem("user_id");
        const response = await fetch(
          `${getFullApiUrl(
            UPDATE_USED_CREDIT_POINTS
          )}?campaign_id=${campaignId}&user_id=${user_id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              points_deducted: points,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to deduct points");
        }
        setTotalCreditPointsLimitThisWeek(updatedPoints);
        setUsedCreditPointsThisWeek(updatedUsedPoints);

        // Fetch updated user credit points
        fetchUserCreditPoints();
      } catch (error) {
        console.error("Error deducting points:", error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // const navigate = useNavigate();

  // const { campaignId } = useParams();
  const [which_component, set_which_component] = useState("flow");
  const [data_from_LIC_to_Flow, set_data_from_LIC_to_Flow] = useState({});

  useEffect(() => {
    fetch(getFullApiUrl(GET_CAMPAIGN_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("************************************************")
        //console.log(data)
        set_data_from_LIC_to_Flow({ campaign_data: data["data"] });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const openFlowComponent = () => {
    set_which_component("flow");
  };

  const openSerperSearchComponent = () => {
    set_which_component("check_active_bloggers_using_serper");
  };

  const openCheckActiveBloggersComponent = () => {
    set_which_component("check_active_bloggers_using_apify");
  };
  /*
  const openClassifyResultPagesComponent = () => {
    set_which_component("classify_result_pages");
  };
*/
  const receive_data_from_Flow_to_LIC = (data) => {
    set_which_component(data["which_component"]);
  };

  useEffect(() => {
    getCampaignDetails();
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                MyTeams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/solutions/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.campaign_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              All-In-One
            </div>
          </Breadcrumbs>

          <FloatingMenuBar campaignId={campaignId} />
          <div className="flow_toggle_container">
            <div
              onClick={openFlowComponent}
              className={`flow_toggle_button ${
                which_component === "flow" ? "active" : ""
              }`}
              // style={{ backgroundImage: `url(${flow_icon})` }}
            >
              <FaProjectDiagram fontSize={32} />
            </div>
          </div>
          <div className="components_Container">
            <div
              className={`component-transition ${
                which_component === "flow" ? "" : "detached"
              }`}
            >
              {data_from_LIC_to_Flow.hasOwnProperty("campaign_data") &&
                which_component === "flow" && (
                  <LinkInsertionCampaignFlow
                    data_from_LIC_to_Flow={data_from_LIC_to_Flow}
                    send_data_from_Flow_to_LIC={receive_data_from_Flow_to_LIC}
                  />
                )}
            </div>
            <div
              className={`component-transition ${
                which_component === "serper" ? "" : "detached"
              }`}
            >
              {which_component === "serper" && <SerperSearchComponent />}
            </div>
            <div
              className={`component-transition ${
                which_component === "active_bloggers" ? "" : "detached"
              }`}
            >
              {which_component === "check_active_bloggers_using_apify" && (
                <CheckActiveBloggersUsingApifyPage />
              )}
            </div>
            <div
              className={`component-transition ${
                which_component === "classify_result_pages" ? "" : "detached"
              }`}
            >
              {which_component === "classify_result_pages" && (
                <ClassifyResultPagesComponent />
              )}
            </div>
            <div
              className={`component-transition ${
                which_component === "page_quality_check" ? "" : "detached"
              }`}
            >
              {which_component === "page_quality_check" && (
                <PageQualityCheckComponent />
              )}
            </div>
          </div>

          <Robo />
          {/* <div className="campaign_details">
            Total Credit points of {campaignDetails.user_name} :{" "}
            {totalCreditPointsOfUser}
          </div>

          <div className="credit_points_container">
            <h3>Total Credit Points Limit This week</h3>
            <TextField
              id="outlined-basic"
              label="Total Credit Points"
              variant="outlined"
              value={totalCreditPointsLimitThisWeek}
              disabled
            />

            <div className="deduction-buttons">
              <h3>Deduct Points</h3>
              <Grid container spacing={2} alignItems="center">
                {[
                  { points: 10, label: "Deduct 10" },
                  { points: 5, label: "Deduct 5" },
                  { points: 2, label: "Deduct 2" },
                  { points: 3, label: "Deduct 3" },
                ].map((item, index) => (
                  <Grid item xs={3} key={index}>
                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => handleDeductPoints(item.points)}
                        startIcon={<RemoveIcon />}
                      >
                        {item.label}
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div> */}
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={notificationMessage}
      />
    </div>
  );
};

export default MyWorkspaceCampaign;
