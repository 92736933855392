import React, { useState, useEffect } from "react";
import PrimarySection from "../LandingPage/PrimarySection/PrimarySection";
import useAuth from "../../hooks/authentication/useAuth";
import { IoIosAddCircleOutline } from "react-icons/io";

import AboutUs from "../LandingPage/AboutUsSection/AboutUs";
import FAQsSection from "../LandingPage/FAQsSection/FAQsSection";
import Footer from "../common/Footer/Footer";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import ContactUs from "../LandingPage/ContactUsSection/ContactUs";
import {
  Alert,
  Button,
  fabClasses,
  Skeleton,
  TextField,
  IconButton,
  Tooltip,
  Modal,
  Box,
  Autocomplete,
  Typography,
} from "@mui/material";
import { MdOutlineCampaign } from "react-icons/md";
import { CgArrowRight } from "react-icons/cg";
import "./HomePage.css";
import {
  GET_PASSCODE,
  RECENTLY_USED_CAMPAIGNS,
  GET_CREDIT_RECORD,
  SEARCH_WORKSPACE_TO_ADD_CAMPAIGN,
  getFullApiUrl,
  GET_USER_LIST_ACCESS_TO_CAMPAIGN,
  ADD_NEW_CAMPAIGN,
  UPDATE_IN_CHARGE_OF_CAMPAIGN,
} from "../../utils/apiPath";
import { useNavigate, Link } from "react-router-dom";
import Robo from "../common/Robo/Robo";
import "./HomePage.css";
import bigToolVideo from "../../assets/big_tool_video.mp4";
import classifying_video from "../../assets/classifying_video.mp4";
import identify_paragraph from "../../assets/identify_paragraph.mp4";
import CloseIcon from "@mui/icons-material/Close";
import { RiExchangeFundsLine } from "react-icons/ri";
import { LuHistory } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";
import StatusModalComponent from "../../components/StatusModalComponent/StatusModalComponent";

const HomePage = ({ toggleTheme }) => {
  const { loggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [creditRecords, setCreditRecords] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignsLoaded, setCampaignsLoaded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showArrow, setShowArrow] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [lastVisitedCampaign, setLastVisitedCampaign] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [searchTermworkspace, setSearchTermworkspace] = useState("");
  const [searchWorkspaceResults, setSearchWorkspaceResults] = useState([]);
  const [new_campaign_data, set_new_campaign_data] = useState({
    workspace_id: "",
    campaign_name: "",
    campaign_description: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [workspace_data, set_workspace_data] = useState([]);

  const [fetchError, setFetchError] = useState(false); // New state for fetch error

  const fetchCampaignList = async () => {
    try {
      const response = await fetch(getFullApiUrl(RECENTLY_USED_CAMPAIGNS), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: localStorage.getItem("user_id") }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.length === 0) {
        // Handle empty response
        setCampaignList([]);
        setSelectedCampaign({
          campaign_name: "No Campaigns Available",
          owner_name: "N/A",
          total_credits_of_owner: "N/A",
          created_by: "N/A",
          in_charge_of: "N/A",
          used_credit_points_today: 0,
          used_credit_points_this_week: 0,
          used_credit_points_this_month: 0,
          threshold_value: 0,
        });
      } else {
        setCampaignList(data);
        setSelectedCampaign(data[0]);
        setCampaignsLoaded(false);
        setFetchError(false);
      }
      setCampaignsLoaded(true);
      setFetchError(false); // Reset fetch error state if successful
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetchError(true); // Set fetch error state if an error occurs
    }
  };
  useEffect(() => {
    fetchCampaignList();
  }, []);

  const handleCampaignClick = (campaignId) => {
    navigate(`/solutions/${campaignId}`);
  };

  const handleShowArrow = (campaign) => {
    setSelectedCampaign(campaign);
    setLastVisitedCampaign(campaign.campaign_id);
    setShowArrow(campaign.campaign_id);
  };

  const handleHideArrow = () => {
    setShowArrow(lastVisitedCampaign);
  };

  const renderCampaigns = () => {
    const campaignsToShow = Array.isArray(campaignList)
      ? (showAll === true ? campaignList : campaignList.slice(0, 6))
      : []; 

    return (
      <div className="render_campaign_container">
        <div className="campaign_table">
          <div className="campaign_table_header">
            <div className="campaign_table_header_item">
              
                <div className="header_container">
                  <div style={{
                    display:"flex",
                    alignItems:"center",
                    gap:"5px"
                  }}>
                    <div>Your Recent Campaigns</div>
                    <div>📢 </div>
                   
                   
                  </div>
                  <Tooltip title={`Create  New Campaign`}>
                    
                    <div onClick={handleAddOpen} > <FaPlus /> </div>
                    </Tooltip>
                  
                </div>
            </div>
          </div>
          <div className="campaign_table_body">
            {campaignsLoaded ? (
              campaignsToShow.length > 0 ? (
                campaignsToShow.map((campaign) => (
                  <div
                    key={campaign.campaign_id}
                    className={`campaign_table_row ${campaign.campaign_id === showArrow ? "highlight" : ""
                      }`}
                    onMouseEnter={() => handleShowArrow(campaign)}
                    onMouseLeave={handleHideArrow}
                    onClick={() => handleCampaignClick(campaign.campaign_id)}
                  >
                    <div className="campaign_table_cell">
                      <a
                        href={`/solutions/${campaign.campaign_id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleCampaignClick(campaign.campaign_id);
                        }}
                        className="campaign_link"
                      >
                        <div className="campaign_table_cell_icon">
                          <MdOutlineCampaign />
                        </div>
                        <div className="campaign_table_cell_text">
                          <div>{campaign.campaign_name}</div>
                          {campaign.campaign_id === showArrow && (
                            <div className="campaign_table_cell_icon">
                              <CgArrowRight />
                            </div>
                          )}
                        </div>
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <div className="campaign_table_row">
                  <div className="campaign_table_cell">
                    {/* <Skeleton variant="text" width="80%" /> */}
                    <div>No campaigns found. You can add a new campaign by clicking the plus icon above or go to <a href="/user_campaign">My Campaigns</a> to create one.</div>
                  </div>
                </div>
              )
            ) : (
              Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="campaign_table_row">
                  <div className="campaign_table_cell">
                    <Skeleton
                      variant="rectangular"
                      width={24}
                      height={24}
                      className="campaign_table_cell_icon"
                    />
                    <div className="campaign_table_cell_text">
                      <Skeleton variant="text" width="80%" />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {campaignsLoaded ? (
            <div className="more_less_button_container">
              {!showAll && campaignList.length > 5 && (
                <Button onClick={() => setShowAll(true)}>
                  {campaignsLoaded ? (
                    "More"
                  ) : (
                    <Skeleton variant="text" width="50%" />
                  )}
                </Button>
              )}
              {showAll && (
                <Button onClick={() => setShowAll(false)}>
                  {campaignsLoaded ? (
                    "Less"
                  ) : (
                    <Skeleton variant="text" width="50%" />
                  )}
                </Button>
              )}
            </div>
          ) : (
            <div className="more_less_button_container">
              <Skeleton variant="text" width="80%" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCreditRecords = () => {
    // Initial checks for campaignsLoaded and selectedCampaign
    const isCampaignsLoaded = typeof campaignsLoaded === 'boolean' && campaignsLoaded;
    const isSelectedCampaignValid = selectedCampaign && typeof selectedCampaign === 'object' && !Array.isArray(selectedCampaign);

  
    return (
      <div className="credit_records_container">
        {/* Owner Details Section */}
        <div className="credit_records_container_body">
          <div className="credit_records_header">
            <div className="credit_records_header_item">
              <div className="credit_records_header_item_text">
                {isCampaignsLoaded ? (
                  `${selectedCampaign?.campaign_name || "N/A"}'s Details`
                ) : (
                  <Skeleton variant="text" width="60%" />
                )}
              </div>
              <div className="credit_records_header_item_icon">👑</div>
            </div>
          </div>
          <div className="credit_records_body">
            {isCampaignsLoaded ? (
              <div className="credit_record_box">
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">👑</div>
                    <div className="credit_record_heading_text">
                      Owner Name:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {isSelectedCampaignValid ? selectedCampaign.owner_name || "N/A" : "N/A"}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">🪙</div>
                    <div className="credit_record_heading_text">
                      Owner Credits Left:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {isSelectedCampaignValid ? selectedCampaign.total_credits_of_owner || "N/A" : "N/A"}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">👤</div>
                    <div className="credit_record_heading_text">
                      Created By:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {isSelectedCampaignValid ? selectedCampaign.created_by || "N/A" : "N/A"}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">👥</div>
                    <div className="credit_record_heading_text ">
                      <div>In Charge: </div>
                      <div className="in_charge_container">
                        <Tooltip title={`Change In-Charge of ${selectedCampaign?.campaign_name || "N/A"} Campaign`}>
                          <div onClick={() => handleUpdateInChargeOfModalOpen(selectedCampaign?.campaign_id)}>
                            <RiExchangeFundsLine />
                          </div>
                        </Tooltip>
                        <Tooltip title={`Check History of In-Charge in ${selectedCampaign?.campaign_name || "N/A"} Campaign`}>
                          <Link to={`/my_campaign/${selectedCampaign?.campaign_id || "N/A"}/campaign_in_charge_history`}>
                            <div>
                              <LuHistory />
                            </div>
                          </Link>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {isSelectedCampaignValid ? selectedCampaign.in_charge_of || "N/A" : "N/A"}
                  </div>
                </div>
              </div>
            ) : (
              <div className="credit_record_box">
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="credit_record_field">
                    <Skeleton variant="circular" width={24} height={24} />
                    <Skeleton variant="text" width="60%" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
  
        {/* Credit Usage Section */}
        <div className="credit_records_container_body">
          <div className="credit_records_header">
            <div className="credit_records_header_item">
              <div className="credit_records_header_item_text">
                {isCampaignsLoaded ? (
                  `Your Credit Usage Details`
                ) : (
                  <Skeleton variant="text" width="60%" />
                )}
              </div>
              <div className="credit_records_header_item_icon">💰</div>
            </div>
          </div>
          <div className="credit_records_body">
            {isCampaignsLoaded ? (
              <div className="credit_record_box">
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">📅</div>
                    <div className="credit_record_heading_text">
                      Credits Used Today:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {isSelectedCampaignValid ? selectedCampaign.used_credit_points_today || "0.0" : "0.0"}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">⏳</div>
                    <div className="credit_record_heading_text">
                      Credits Used This Week:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {isSelectedCampaignValid ? selectedCampaign.used_credit_points_this_week || "0.0" : "0.0"}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">📆</div>
                    <div className="credit_record_heading_text">
                      Credits Used This Month:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {isSelectedCampaignValid ? selectedCampaign.used_credit_points_this_month || "0.0" : "0.0"}
                  </div>
                </div>
                <div className="credit_record_field">
                  <div className="credit_record_heading">
                    <div className="credit_record_heading_sticker">⚖️</div>
                    <div className="credit_record_heading_text">
                      Threshold Value:
                    </div>
                  </div>
                  <div className="credit_record_value">
                    {isSelectedCampaignValid ? selectedCampaign.threshold_value || "0.0" : "0.0"}
                  </div>
                </div>
              </div>
            ) : (
              <div className="credit_record_box">
                {Array.from({ length: 4 }).map((_, index) => (
                  <div key={index} className="credit_record_field">
                    <Skeleton variant="circular" width={24} height={24} />
                    <Skeleton variant="text" width="60%" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  


  const renderwelcomeCard = () => {
    return (
      <div className="welcome_section">
        <div className="welcome_header">Welcome to Linkomatic 🚀</div>
        <div className="welcome_content">
          <div className="welcome_item">
            <div className="welcome_icon">🔍</div>
            <div className="welcome_text">
              <div className="welcome_title">Bulk Scrape Google</div>
              <div className="welcome_description">
                Scrape Google for thousands of keywords at a time.
              </div>
            </div>
          </div>
          <div className="welcome_item">
            <div className="welcome_icon">🤖</div>
            <div className="welcome_text">
              <div className="welcome_title">Find Relevant Sites with AI</div>
              <div className="welcome_description">
                We use AI to rank your prospecting list from most to least
                relevant.
              </div>
            </div>
          </div>
          <div className="welcome_item">
            <div className="welcome_icon">📈</div>
            <div className="welcome_text">
              <div className="welcome_title">Blow Past Link Insert Targets</div>
              <div className="welcome_description">
                Analyze thousands of sites to find link insert suggestions.
              </div>
            </div>
          </div>
          <div className="welcome_item">
            <div className="welcome_icon">👥</div>
            <div className="welcome_text">
              <div className="welcome_title">Empower Your Team</div>
              <div className="welcome_description">
                Invite your team, manage their credits, and watch them work
                faster.
              </div>
            </div>
          </div>
          <div className="welcome_item">
            <div className="welcome_icon">🔗</div>
            <div className="welcome_text">
              <div className="welcome_title">By Link Builders</div>
              <div className="welcome_description">
                For link builders, by link builders. Built to serve the needs of
                agencies and link builders.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCampaignLinks = () => {
    // List of campaign pages to display as links
    const campaignPages = [
      { title: "Add Target URLs", path: "client_urls", icon: "🔗" },
      {
        title: "Scrape Prospect URLs From Google",
        path: "check_active_bloggers_using_serper",
        icon: "👥",
      },
      {
        title: "Black List",
        path: "black_list",
        icon: "🚫",
      },
      {
        title: "Prepare Prospects for Analysis",
        path: "blog_urls",
        icon: "📝",
      },
      {
        title: "Match Prospects with Target URLs",
        path: "bulk_process",
        icon: "🔄",
      },
      {
        title: "Classify Prospect Page Types",
        path: "classify_result_page",
        icon: "📊",
      },
      { title: "Page Quality Check", path: "page_quality_check", icon: "🌟" },
      {
        title: "Inspect Matches Individually",
        path: "identify_paragraphs",
        icon: "🔍",
      },
      {
        title: "Link Insertion Suggestion",
        path: "link_insertion_suggestion",
        icon: "🔗",
      },
      {
        title: "Screenshot Tool",
        path: "screenshot",
        icon: "📸",
      },
    ];

    // Check if campaigns are loaded and if there is a selected campaign with a valid campaign_id
    const hasCampaignData =
      campaignsLoaded && selectedCampaign && selectedCampaign.campaign_id;

    return (
      <div className="campaign_links_container">
        {hasCampaignData ? (
          <>
            <div className="campaign_links_header">
              <div className="campaign_links_header_item">
                <div className="campaign_links_header_item_text">
                  {`${selectedCampaign.campaign_name} Links`}
                </div>
                <div className="campaign_links_header_item_icon">🌟</div>
              </div>
            </div>
            <div className="campaign_links_body">
              {campaignPages.map((page, index) => (
                <div key={index} className="campaign_links_item">
                  <a
                    href={`/my_campaign/${selectedCampaign.campaign_id}/${page.path}`}
                    className="campaigns_link"
                  >
                    <div className="campaign_link_text">
                      <div className="campaign_link_text_icon">{page.icon}</div>
                      <div className="campaign_link_texts">{page.title}</div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            {/* Show skeleton loading if campaigns are being loaded */}
            {!campaignsLoaded &&
              Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="campaign_links_item">
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </div>
              ))}

            {campaignsLoaded && (
              <>
                <div className="campaign_links_header">
                  <div className="campaign_links_header_item">
                    <div className="campaign_links_header_item_text">
                      {`Campaign Links`}
                    </div>
                    <div className="campaign_links_header_item_icon">🌟</div>
                  </div>
                </div>
                <div className="campaign_links_body">No Campaign Available</div>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const videoUrls = [bigToolVideo, classifying_video, identify_paragraph];

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoUrls.length);
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex(
      (prevIndex) => (prevIndex - 1 + videoUrls.length) % videoUrls.length
    );
  };

  const renderVideoPlayCard = () => {
    return (
      <div className="video_play_section">
        <div className="video_play_header">Featured Video 🎥</div>
        <div className="video_play_content">
          <video
            className="video_player"
            controls // Optional: Add controls for user interaction
            autoPlay // Autoplay the video
            loop={false} // No need for loop as we'll control it through carousel
            key={currentVideoIndex} // Ensure key changes to force re-render on source change
          >
            <source src={videoUrls[currentVideoIndex]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="video_controls">
            <Button onClick={handlePrevVideo}>Previous</Button>
            <Button onClick={handleNextVideo}>Next</Button>
          </div>
        </div>
      </div>
    );
  };

  // Add New Campaign

  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  
  const handleAddcampaignSubmit = async () => {
    if (!new_campaign_data.workspace_id || !new_campaign_data.campaign_name) {
      setErrorMessage(
        "Please add Campaign name and select Workspace before adding."
      );
      return;
    }
    try {
      setShowAddcampaignForm(false);
      // Send a POST request to the server
      setModalState({
        isOpen: true,
        type: "loading",
        message: "Adding new campaign...",
      });
      
      const response = await fetch(`${getFullApiUrl(ADD_NEW_CAMPAIGN)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaign_name: new_campaign_data.campaign_name,
          workspace_id: new_campaign_data.workspace_id,
          campaign_description: new_campaign_data.campaign_description,
          created_by: localStorage.getItem("user_id"),
        }),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to add new workspace member");
      }

      // Handle success
      console.log("New workspace member added successfully");
      setModalState({
        isOpen: true,
        type: "success",
        message: "New campaign added successfully!",
      });

      setTimeout(() => {
        setModalState({ isOpen: false, type: "", message: "" });
      }, 3000);

      // Close the modal or perform any other actions as needed
      handleAddClose();

      // Fetch the updated list of workspace members
      fetchCampaignList();
    } catch (error) {
      // Handle errors
      console.error("Error adding new workspace member:", error.message);
      setModalState({ isOpen: false, type: "", message: "" });
    }
  };

  useEffect(() => {
    if (!searchTermworkspace) {
      setSearchWorkspaceResults(workspace_data); // Display all workspace data
      return;
    }

    const filteredWorkspaces = workspace_data.filter((workspace) =>
      workspace.workspace_name
        .toLowerCase()
        .includes(searchTermworkspace.toLowerCase())
    );
    setSearchWorkspaceResults(filteredWorkspaces);
  }, [searchTermworkspace, workspace_data]);

  const [showAddcampaignForm, setShowAddcampaignForm] = React.useState(false);
  const handleAddOpen = () => {
    fetchworkspaceListForSearch();
    setShowAddcampaignForm(true);
  };
  const handleAddClose = () => {
    setShowAddcampaignForm(false);
    setSelectedWorkspace(null);
    set_new_campaign_data({
      workspace_id: "",
      campaign_name: "",
      campaign_description: "",
    });
    setErrorMessage("");
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    set_new_campaign_data({ ...new_campaign_data, [name]: value });
  };

  const handleSearchWorkspaceInputChange = (e) => {
    setSearchTermworkspace(e.target.value);
    // Reset new_campaign_data.workspace_id when input changes
    set_new_campaign_data({
      ...new_campaign_data,
      workspace_id: null,
    });
  };
  const handleWorkspaceSelect = (workspace) => {
    if (workspace) {
      set_new_campaign_data({
        ...new_campaign_data,
        workspace_id: workspace.workspace_id,
      });
      // Set the selected user
      setSelectedWorkspace(workspace);
    }
  };
  const [isLoading, setIsLoading] = React.useState(true); // Add loading state

  const fetchworkspaceListForSearch = async () => {
    try {
      setIsLoading(true); // Start loading
      const userId = localStorage.getItem("user_id");
      const response = await fetch(
        getFullApiUrl(SEARCH_WORKSPACE_TO_ADD_CAMPAIGN),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const myWorkspaceListData = await response.json();
      set_workspace_data(myWorkspaceListData);
    } catch (error) {
      set_workspace_data([]);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };


  // change in-change of campaign

  const [campaign_data_to_update_incharge, set_campaign_data_to_update_incharge] = useState({
    campaign_id: "",
    incharge_of: "",
    updated_by_user_id: ""
  })
  const [show_update_incharge_of_campaign_modal, set_show_update_incharge_of_campaign_modal] = useState(false)
  const [searchTermToUpdateInCharge, setSearchTermToUpdateInCharge] = useState("");
  const [searchResultsToUpdateInCharge, setSearchResultsToUpdateInCharge] = useState([]);
  const [user_list_access_to_campaign, set_user_list_access_to_campaign] = useState()
  const [selected_user_to_update_incharge_of, set_selected_user_to_update_incharge_of] = useState(null)

  const fetchUserListToSearch = async (campaign_id) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_USER_LIST_ACCESS_TO_CAMPAIGN)}`,
        {
          method: 'POST', // Change to POST method
          headers: {
            'Content-Type': 'application/json', // Set the content type
          },
          body: JSON.stringify({ campaign_id }) // Pass campaign_id in the body
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      set_user_list_access_to_campaign(data.team_member_list || []);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdateInChargeOfModalOpen = async (campaign_id) => {
    console.log("camapign")
    console.log(campaign_id)

    await fetchUserListToSearch(campaign_id);
    set_campaign_data_to_update_incharge({
      campaign_id: campaign_id,
      incharge_of: "",
      updated_by_user_id: localStorage.getItem("user_id")
    })
    set_show_update_incharge_of_campaign_modal(true);

  };

  const handleUpdateInChargeOfModalClose = () => {
    set_show_update_incharge_of_campaign_modal(false);
    set_user_list_access_to_campaign(null);
    set_campaign_data_to_update_incharge({
      campaign_id: "",
      incharge_of: "",
      updated_by_user_id: localStorage.getItem("user_id")
    });
    setErrorMessage("");
  };

  const handleUpdateInChargeOfData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_IN_CHARGE_OF_CAMPAIGN), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(campaign_data_to_update_incharge),
      });

      if (!response.ok) {
        throw new Error("Failed to edit campaign");
      }

      console.log("campaign edited successfully");
      handleUpdateInChargeOfModalClose(false);
      // Refresh campaign list after editing
      fetchCampaignList();
    } catch (error) {
      console.error("Error editing campaign:", error.message);
    }
  };


  const handleUserSelect = (user) => {
    if (user) {
      set_campaign_data_to_update_incharge({
        ...campaign_data_to_update_incharge,
        incharge_of: user.team_member_id,
        updated_by_user_id: localStorage.getItem("user_id")

      });
      // Set the selected user
      set_selected_user_to_update_incharge_of(user);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTermToUpdateInCharge(e.target.value);
  };

  useEffect(() => {
    if (!searchTermToUpdateInCharge) {
      setSearchResultsToUpdateInCharge(user_list_access_to_campaign);
      return;
    }

    const searchTermLower = searchTermToUpdateInCharge.toLowerCase();
    const filteredUsers = user_list_access_to_campaign.filter(
      (user) =>
        user.user_name.toLowerCase().includes(searchTermLower) ||
        user.email_id.toLowerCase().includes(searchTermLower) ||
        (user.first_name &&
          user.first_name.toLowerCase().includes(searchTermLower)) ||
        (user.last_name &&
          user.last_name.toLowerCase().includes(searchTermLower))
    );
    setSearchResultsToUpdateInCharge(filteredUsers);
  }, [searchTermToUpdateInCharge, user_list_access_to_campaign]);



  return (
    <div className="page_Section">
      {loggedIn && (
        <div className="leftSection_withMenu">
          <LeftSectionWithMenu />
        </div>
      )}
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} loggedIn={loggedIn} />
        </div>
        <div className="bodySection">
          {fetchError ? (
            <div className="home_page_primary_container">
              {/* <Alert severity="error">Failed to fetch campaigns due to some technical error</Alert> */}
            </div>
          ) : (
            <div className="homepage">
              {/* <div className="add_campaign_container" onClick={handleAddOpen}>
                <div>Add New Campaign</div>
                <div
                // className="bulk_classify_button download_icons"
                // onClick={handleAddOpen}
                >
                  <IoIosAddCircleOutline />
                </div>
              </div> */}
              <div className="home_page_primary_container">
                <div className="recently_used_campaign_container">
                  {renderCampaigns()}
                </div>
                {renderCampaignLinks()}
                {renderCreditRecords()}
              </div>
            </div>
          )}

          <div className="home_page_secondary_container">
            {renderwelcomeCard()}
            {renderVideoPlayCard()}
          </div>

          <ContactUs />
        </div>
      </div>

      {/*  Add New Campaign */}
      <Modal
        open={showAddcampaignForm}
        onClose={handleAddClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="model_box">
          <div className="add-user-form">
            {isLoading ? ( // Check for loading state
              <div style={{ padding: "20px", textAlign: "center" }}>
                <Typography variant="h6">Loading ...</Typography>
              </div>
            ) : (
              <>
                {workspace_data.length > 0 && (
                  <div className="modal_header_container">
                    <div className="modal_header_text">Add New Campaign</div>
                    <div className="modal_header_close_icon">
                      <IconButton onClick={handleAddClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                )}
                <div className="modal_content_container">
                  {workspace_data.length === 0 ? (
                    // Display if no workspaces are found
                    <div style={{ padding: "20px", textAlign: "center" }}>
                      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                        🚫 Sorry, you currently don't have any workspaces available to create a new campaign.
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        Please go to{" "}
                        <a href="/my_workspaces" style={{ color: "blue", textDecoration: "underline" }}>
                          My Workspaces
                        </a>{" "}
                        to create a new workspace.
                      </Typography>
                      <Typography variant="body1">
                        Alternatively, visit{" "}
                        <a href="/my_teams" style={{ color: "blue", textDecoration: "underline" }}>
                          My Teams
                        </a>{" "}
                        to create a new team, then add a workspace within that team.
                      </Typography>
                    </div>
                  ) : (
                    // Display if workspaces are available
                    <>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": {
                            width: "80%",
                            marginLeft: "10%",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="campaign_name"
                          label="Campaign Name"
                          variant="standard"
                          name="campaign_name"
                          value={new_campaign_data.campaign_name}
                          onChange={handleNewInputChange}
                          className="modal_form_input"
                        />
                      </Box>

                      {/* Search Workspace Autocomplete */}
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": {
                            width: "80%",
                            marginLeft: "10%",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Autocomplete
                          id="search_workspace"
                          options={searchWorkspaceResults}
                          getOptionLabel={(option) => `${option.workspace_name}`}
                          onChange={(event, newValue) => handleWorkspaceSelect(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Workspace"
                              variant="standard"
                              onChange={handleSearchWorkspaceInputChange}
                              className="modal_form_input"
                            />
                          )}
                        />
                      </Box>
                      {selectedWorkspace && (
                        <div className="modal_text">
                          <Typography>
                            Selected Workspace: {selectedWorkspace.workspace_name}
                          </Typography>
                        </div>
                      )}
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": {
                            width: "80%",
                            marginLeft: "10%",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="campaign_description"
                          label="Description"
                          variant="standard"
                          name="campaign_description"
                          value={new_campaign_data.campaign_description}
                          onChange={handleNewInputChange}
                          className="modal_form_input"
                          multiline
                          rows={4}
                        />
                      </Box>
                      {errorMessage && (
                        <div
                          style={{
                            color: "red",
                            paddingLeft: "58px",
                            textAlign: "left",
                          }}
                        >
                          {errorMessage}
                        </div>
                      )}
                      <button onClick={handleAddcampaignSubmit} className="add-user-btn">
                        Add{" "}
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>




      {/* Change in change of campaign */}

      <Modal
        open={show_update_incharge_of_campaign_modal}
        onClose={handleUpdateInChargeOfModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="model_box">
          <div className="add-user-form">
            <div className="modal_header">Update Incharge Of This Campaign</div>

            <Box
              component="form"
              sx={{
                "& > :not(style)": {
                  m: 2,
                  width: "80%",
                  marginLeft: "10%",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <Autocomplete
                id="search_user"
                options={searchResultsToUpdateInCharge}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name} (${option.user_name}) - ${option.email_id}`
                }
                onChange={(event, newValue) => handleUserSelect(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search User"
                    variant="standard"
                    onChange={handleSearchInputChange}
                    className="modal_form_input"
                  />
                )}
              />
            </Box>
            {selected_user_to_update_incharge_of && (
              <div className="modal_text">
                <Typography>
                  Selected User: {selected_user_to_update_incharge_of.first_name}
                </Typography>
              </div>
            )}




            <button
              onClick={handleUpdateInChargeOfData}
              className="add-user-btn"
            >
              Update In Charge Of The Campaign
            </button>
          </div>
        </Box>
      </Modal>
      <StatusModalComponent
        isOpen={modalState.isOpen}
        type={modalState.type}
        message={modalState.message}
        onClose={() => setModalState({ isOpen: false, type: "", message: "" })}
      />
    </div>
  );
};

export default HomePage;
