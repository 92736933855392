import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Button } from "@mui/material"; // Import AutoComplete from Material UI
import "./KeywordsSearch.css"; // Import the CSS file for styling
import { IoArrowBackOutline } from "react-icons/io5";
import { CiGlobe } from "react-icons/ci";

const KeywordsSearch = ({ selectedItem, screenshotData, goBack }) => {
    const [selectedKeyword, setSelectedKeyword] = useState("");
    const [manualSearchInput, setManualSearchInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [blogUrls, setBlogUrls] = useState([]); // To store the blog URLs for AutoComplete

    // Function to handle keyword click
    const handleKeywordClick = (keyword) => {
        console.log("selectedItem")
        console.log(selectedItem)
        console.log("screenshotData")
        console.log(screenshotData)
        // setSelectedKeyword(keyword);
        // screenshotData(keyword); // Trigger search for the selected keyword
    };

    // Function to handle manual search input
    const handleManualSearch = () => {
        if (manualSearchInput.trim() !== "") {
            screenshotData(manualSearchInput); // Trigger search for the manual input
        }
    };

    const handleGoToPreviousSection = () => {
        goBack(); // Call the goBack function passed from the parent component
    };

    // Filter screenshot data based on client_url match with selectedItem
    useEffect(() => {
        if (selectedItem && selectedItem.client_url) {
            const matchingItems = screenshotData.filter(
                (item) => item.client_url === selectedItem.client_url
            );
            setFilteredData(matchingItems);

            // Populate the AutoComplete with blog URLs
            const urls = matchingItems.map((item) => item.blog_url).filter((url) => url); // Extract blog URLs
            setBlogUrls(urls);
        }
    }, [selectedItem, screenshotData]);

    return (
        <div className="keywords_search_container">
            <div className="keywords_search_header_container">
                <div className="keywords_search_go_back_icon" onClick={handleGoToPreviousSection}>
                    <IoArrowBackOutline />
                </div>
                <div className="keywords_search_header_text">Find Own Matched</div>
            </div>
            <div className="predefined_matches">
                <div className="predefined_matches">Pre-defined Matches:</div>
                <div className="matches_list">
                    {/* Extract and display keywords from selectedItem */}
                    {selectedItem.keywords &&
                        selectedItem.keywords.map((keywordObj, index) => {
                            // Extract the first key-value pair (e.g., "keyword_1": "Cricket match")
                            const [keywordKey, keywordValue] = Object.entries(keywordObj).find(([key]) => key.startsWith("keyword_"));
                            const keywordLabel = `${keywordValue} (${keywordObj.occurrence})`; // Format as: "Cricket match (1)"

                            return (
                                <button
                                    key={index}
                                    className={`match-button ${selectedKeyword === keywordLabel ? "active" : ""
                                        }`}
                                    onClick={() => handleKeywordClick(keywordValue)} // Pass only the keyword value
                                >
                                    {keywordLabel}
                                </button>
                            );
                        })}

                </div>
            </div>

            {/* Manual Search Section with AutoComplete */}
            <div className="manual_search">
                <div className="manual_search_header">Manual Search:</div>
                <div className="keywords_search_container_search_bar">
                    {/* AutoComplete for Blog URL */}
                    <Autocomplete
                        options={blogUrls} // Use the list of blog URLs
                        getOptionLabel={(option) => option} // Display the option as a string
                        onInputChange={(event, newInputValue) => setManualSearchInput(newInputValue)} // Set the input value
                        renderInput={(params) => <TextField {...params} label="Search Blog URL" variant="outlined" />}
                        freeSolo // Allow input that doesn't match the options
                    />
                    <Button
                        className="search_button"
                        variant="contained"
                        onClick={handleManualSearch}
                    >
                        SEARCH BLOG
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default KeywordsSearch;
