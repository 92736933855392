import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

//import {Router, browserHistory} from 'react-router';

import { Box, Grid, Button, Snackbar } from "@mui/material";
import {
  Link as RouterLink,
  useParams,
  navigate,
  useNavigate,
} from "react-router-dom";

import { IconButton, Modal } from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  Breadcrumbs,
  MenuItem,
} from "@mui/material";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  TextareaAutosize,
  LinearProgress,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
//import LinearProgress from '@mui/material/LinearProgress';
import Typography from "@mui/material/Typography";
//import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SaveIcon from "@mui/icons-material/Save";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
//import CloseIcon from '@mui/icons-material/Close';

import LeftBar from "./../common/LeftBar";
import ListHeader from "./../common/ListHeader";

import Tooltip from "@mui/material/Tooltip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

/*

*/
//import delete from '../../assets/icons/delete.png';
import delete_button_icon from "../../assets/icons/delete_button_icon.png";
import page_classification_icon from "../../assets/icons/page_classification_icon2.png";
import scrap_icon from "../../assets/icons/scrap_icon.png";
import score_icon from "../../assets/icons/score_icon.png";
import "./LinkExchangeCampaignPage.css";
import Robo from "./../common/Robo";
import {
  CHECK_CREDIT_CONDITION,
  CHECK_USER_CAMPAIGN_ACCESS,
  GET_CAMPAIGN_FROM_S3,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_LINKS_FROM_PAGE,
  GET_POINTS_DETAILS,
  LOAD_CAMPAIGN_FROM_S3,
  SAVE_CAMPAIGN_FROM_S3,
  SCRAPE_URLS,
  SCRAPE_WEBSITE_TO_FIND_AUTHOR,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_USED_CREDIT_POINTS,
  getFullApiUrl,
} from "../../utils/apiPath";
import Header from "../common/Header/Header";
import { NavigateNext } from "@mui/icons-material";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdDeleteSweep, MdMan2, MdOutlineDeleteOutline } from "react-icons/md";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { GiFlatHammer } from "react-icons/gi";
import { BsHammer, BsSpeedometer } from "react-icons/bs";
import { LiaProjectDiagramSolid } from "react-icons/lia";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import TableWithPagination from "../../utils/TableWithPagination";

const LinkExchangeCampaignPage = ({ toggleTheme }) => {
  const [scrapingPerformed, setScrapingPerformed] = useState(false);
  const [show_scrap_progress_section, setShowScrapProgressSection] =
    useState(false);
  const [scraping_progress, setScrapingProgress] = useState({
    scrap_processed: 0,
    scrapped_count: 0,
    not_scrapped_count: 0,
    total_count: 0,
  });

  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [selected_author, set_selected_author] = useState();
  const [showAuthorInfoModal, setShowAuthorInfoModal] = React.useState(false);
  const handleAuthorInfoOpen = () => setShowAuthorInfoModal(true);
  const handleAuthorInfoClose = () => setShowAuthorInfoModal(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [
    show_should_we_process_queue_modal,
    set_show_should_we_process_queue_modal,
  ] = useState(false);
  const [
    show_should_we_resume_process_queue_modal,
    set_show_should_we_resume_process_queue_modal,
  ] = useState(false);
  const [
    show_should_we_run_search_queries_serper_modal,
    set_show_should_we_run_search_queries_serper_modal,
  ] = useState(false);

  const [
    show_should_we_run_search_queries_apify_modal,
    set_show_should_we_run_search_queries_apify_modal,
  ] = useState(false);

  const [
    show_should_we_classify_urls_modal,
    set_show_should_we_classify_urls_modal,
  ] = useState(false);
  const [
    show_should_we_scrape_urls_modal,
    set_show_should_we_scrape_urls_modal,
  ] = useState(false);
  const [
    show_should_we_scrape_individual_urls_modal,
    set_show_should_we_scrape_individual_urls_modal,
  ] = useState(false);
  const [
    show_should_we_calculate_similarity_score_modal,
    set_show_should_we_calculate_similarity_score_modal,
  ] = useState(false);
  const [show_should_we_get_author_modal, set_show_should_we_get_author_modal] =
    useState(false);
  const [selected_url_for_scrape, set_selected_url_for_scrape] = useState([]);
  const [
    selected_individual_url_for_scrape,
    set_selected_individual_url_for_scrape,
  ] = useState([]);
  const [selected_url_to_classify, set_selected_url_to_classify] = useState([]);
  const [
    selected_urls_to_calculate_similarity_score,
    set_selected_urls_to_calculate_similarity_score,
  ] = useState([]);
  const [selected_url_to_get_author, set_selected_url_to_get_author] = useState(
    []
  );
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");
  const [totalCreditsDeducted, setTotalCreditsDeducted] = useState(1);

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );
      console.log("button");

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );
      fetchpointsDetails(0);

      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
      // setNotificationMessage(`${points} Points deducted successfully`);
      // setOpenSnackbar(true);
      // setDeductionPoints(0);
      // Close the Modal after deduction
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle_should_we_process_queue_close = () => {
    set_show_should_we_process_queue_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };
  const handle_should_we_resume_process_queue_close = () => {
    set_show_should_we_resume_process_queue_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };
  const handle_should_we_run_search_queries_serper_close = () => {
    set_show_should_we_run_search_queries_serper_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };

  const handle_should_we_run_search_queries_apify_close = () => {
    set_show_should_we_run_search_queries_apify_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };

  const handle_should_we_scrape_urls_close = () => {
    set_show_should_we_scrape_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };
  const handle_should_we_scrape_individual_urls_close = () => {
    set_show_should_we_scrape_individual_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
    set_selected_individual_url_for_scrape();
  };
  const handle_should_we_classify_urls_close = () => {
    set_show_should_we_classify_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
    setErrorMessage("");
  };
  const handle_should_we_get_author_close = () => {
    set_show_should_we_get_author_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };

  const handle_should_we_calculate_similarity_score_close = () => {
    set_show_should_we_calculate_similarity_score_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
    setErrorMessage("");
  };
  const [bulkProcessInProgress, setBulkProcessInProgress] = useState(false);

  const validate_run_search_queries_serper = async (button) => {
    // if(!exchanger_website_url){
    //   setNotificationMessage("Add Some urls before preceeding search")
    //   setOpenSnackbar(true)
    //   return
    // }
    var search_query_list = generated_queries.split("\n");
    const totalQueries = search_query_list.length;
    const deduction_point = await getCreditPointToDeductByButton(button);

    const totalRows = no_of_results * totalQueries;

    // // Check if any URLs are selected

    // if (selected_client_urls.length === 0) {
    //   setNotificationMessage("Please select at least one URL to process");
    //   setOpenSnackbar(true);
    //   return;
    // }

    //  const deduction_point = 1; // Assuming a fixed deduction point for now
    // const credits_to_be_deducted = totalRows * parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat((totalRows * parseFloat(deduction_point)).toFixed(3));

    // Set deduction points in the state
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_run_search_queries_serper_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await run_search_queries_serper();
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_run_search_queries_serper_modal(true);
    }

    // If all conditions passed, open the modal
    set_show_should_we_run_search_queries_serper_modal(true);
  };

  const run_search_queries_serper_update = async () => {
    await run_search_queries_serper();
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    //  }
    // setBulkProcessInProgress(true);
  };

  const validate_run_search_queries_apify = async (button) => {
    const deduction_point = await getCreditPointToDeductByButton(button);
    const credits_to_be_deducted = parseFloat((1 * parseFloat(deduction_point)).toFixed(3));

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_run_search_queries_apify_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await run_search_queries_apify();
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_run_search_queries_apify_modal(true);
    }

    // If all conditions passed, open the modal
    set_show_should_we_run_search_queries_apify_modal(true);
  };

  /*
  const { campaignId } = useParams();
  const [website_we_can_offer, set_website_we_can_offer] = useState("");
  const [keyword1, set_keyword1] = useState("");
  const [keyword2, set_keyword2] = useState("");
  */

  const [activeTab, setActiveTab] = useState(1);
  let hoverTimeout;
  const handleTabHover = (tabNumber) => {
    hoverTimeout = setTimeout(() => {
      setActiveTab(tabNumber);
      scrollToTab(tabNumber);
    }, 500); // 2000 milliseconds (2 seconds)
  };
  const handleTabMouseLeave = () => {
    clearTimeout(hoverTimeout);
  };
  const handleTabClick = (tabNumber) => {
    clearTimeout(hoverTimeout); // Clear the hover timeout
    setActiveTab(tabNumber);
    scrollToTab(tabNumber);
  };
  const scrollToTab = (tabNumber) => {
    const tab_content_containers = document.getElementById(
      "tab_content_containers"
    );
    const scroller = document.getElementById("scroller");
    const tabContent = document.getElementById(`tab-content-${tabNumber}`);

    scroller.scroll({
      left:
        tabNumber * (tab_content_containers.scrollWidth / 5) -
        tab_content_containers.scrollWidth / 5,
      behavior: "smooth",
    });
  };

  const [
    find_all_pages_of_this_website_started,
    set_find_all_pages_of_this_website_started,
  ] = useState(false);
  const find_all_pages_of_this_website_started_flag = useRef(false);
  const [exchanger_website_url, set_exchanger_website_url] = useState("");
  const [exchanger_website_pages, set_exchanger_website_pages] = useState([]);
  const [url_queue_progress_count, set_url_queue_progress_count] = useState({
    visited_urls_count: 0,
    found_urls_count: 0,
  });
  const visited_urls = useRef(new Set());
  const found_urls = useRef(new Set());
  const url_queue = useRef([]);
  const validate_process_queue = async (button) => {
    const deduction_point = await getCreditPointToDeductByButton(button);
    const credits_to_be_deducted = parseFloat((1 * parseFloat(deduction_point)).toFixed(3));

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_process_queue_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await find_all_pages_of_this_website("Find all pages of this Website");
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_process_queue_modal(true);
    }

  };

  const process_queue_update = async () => {
    await find_all_pages_of_this_website("Find all pages of this Website");
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    //  }
    // setBulkProcessInProgress(true);
  };
  const stop_find_all_pages_of_this_website_started = () => {
    set_find_all_pages_of_this_website_started(false);
    find_all_pages_of_this_website_started_flag.current = false;
  };

  const validate_resume_process_queue = async () => {
    const deduction_point = await getCreditPointToDeductByButton(
      "Find all pages of this Website"
    );
    const credits_to_be_deducted = parseFloat((1 * parseFloat(deduction_point)).toFixed(3));

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_resume_process_queue_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await resume_process_queue();
    } else {
      set_show_should_we_resume_process_queue_modal(true);
    }

  };
  const resume_process_queue = async () => {
    set_show_should_we_resume_process_queue_modal(false);
    set_find_all_pages_of_this_website_started(true);
    find_all_pages_of_this_website_started_flag.current = true;
    process_queue();

  };
  const find_all_pages_of_this_website = async () => {
    if (!exchanger_website_url) return;
    set_find_all_pages_of_this_website_started(true);
    find_all_pages_of_this_website_started_flag.current = true;
    set_exchanger_website_pages([]);
    visited_urls.current = new Set();
    found_urls.current = new Set();
    url_queue.current = [exchanger_website_url];

    process_queue();
  };

  const calculateAdditionalCreditsToDeduct = (newTotalUrls) => {
    // Calculate the number of additional credits to deduct based on the new total number of URLs
    const additionalCredits =
      Math.floor(newTotalUrls / 100) - totalCreditsDeducted;
    return additionalCredits;
  };

  const process_queue = async () => {
    set_show_should_we_process_queue_modal(false);

    if (!exchanger_website_url) {
      stop_find_all_pages_of_this_website_started();
      setErrorMessage("Give Website URLs Before Proceed.");
      return;
    }

    setDeductionPoints(1);
    await fetchpointsDetails(1);

    if (totalCreditAfterDeduction < 0 || creditWeekLimitAfterDeduction < 0) {
      stop_find_all_pages_of_this_website_started();
      return;
    }

    if (
      url_queue.current.length === 0 ||
      !find_all_pages_of_this_website_started_flag.current
    ) {
      return;
    }

    const url_to_check = url_queue.current.shift();

    if (!visited_urls.current.has(url_to_check)) {
      visited_urls.current.add(url_to_check);
      set_url_queue_progress_count({
        visited_urls_count: visited_urls.current.size,
        found_urls_count: found_urls.current.size,
      });

      const response = await fetch(getFullApiUrl(GET_LINKS_FROM_PAGE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request_data: {
            base_url: exchanger_website_url,
            url_to_check: url_to_check,
            campaign_id: campaignId,
            user_id: localStorage.getItem("user_id"),
            button_name: "Find all pages of this Website",
          },
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const new_links = responseData.data;

        new_links.forEach((newLink) => {
          if (!found_urls.current.has(newLink)) {
            found_urls.current.add(newLink);
            set_exchanger_website_pages((prevPages) => [...prevPages, newLink]);
            url_queue.current.push(newLink);
            set_url_queue_progress_count({
              visited_urls_count: visited_urls.current.size,
              found_urls_count: found_urls.current.size,
            });
          }
        });

        const newTotalUrls = found_urls.current.size;

        // Calculate the number of times updateCreditPoints should be called
        const callsToUpdateCreditPoints =
          Math.floor(newTotalUrls / 100) - totalCreditsDeducted;

        setTotalCreditsDeducted((prevTotalCreditsDeducted) => {
          if (
            prevTotalCreditsDeducted <
            totalCreditsDeducted + callsToUpdateCreditPoints
          ) {
            updateCreditPoints(1); // Assuming updateCreditPoints returns a Promise
          }
          return totalCreditsDeducted + callsToUpdateCreditPoints;
        });

        process_queue();
      } else {
        process_queue();
      }
    } else {
      process_queue();
    }
  };

  // const process_queue = async () => {

  //   if (!exchanger_website_url) {

  //     return;
  //   }

  //   if (
  //     url_queue.current.length === 0 ||
  //     !find_all_pages_of_this_website_started_flag.current
  //   ) {
  //     return;
  //   }

  //   const url_to_check = url_queue.current.shift();

  //   if (!visited_urls.current.has(url_to_check)) {
  //     visited_urls.current.add(url_to_check);
  //     set_url_queue_progress_count({
  //       visited_urls_count: visited_urls.current.size,
  //       found_urls_count: found_urls.current.size,
  //     });

  //     const response = await fetch(getFullApiUrl(GET_LINKS_FROM_PAGE), {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         request_data: {
  //           base_url: exchanger_website_url,
  //           url_to_check: url_to_check,
  //           campaign_id: campaignId,
  //           user_id: localStorage.getItem("user_id"),
  //           button_name: "Find all pages of this Website",
  //         },
  //       }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       const new_links = data.data;

  //       new_links.forEach((newLink) => {
  //         if (!found_urls.current.has(newLink)) {
  //           found_urls.current.add(newLink);
  //           set_exchanger_website_pages((prevPages) => [...prevPages, newLink]);
  //           url_queue.current.push(newLink);
  //           set_url_queue_progress_count({
  //             visited_urls_count: visited_urls.current.size,
  //             found_urls_count: found_urls.current.size,
  //           });
  //         }
  //       });

  //       const currentDeductionPoints = calculateDeductionPoints(
  //         found_urls.current.size
  //       );
  //       if (currentDeductionPoints > deductionPoints) {
  //         const additionalPoints = currentDeductionPoints - deductionPoints;
  //         await updateCreditPoints(totalCreditsDeducted + 1);
  //         setDeductionPoints();

  //         const data = await fetchpointsDetails();
  //         const totalAfterDeduction =
  //           data.total_credit_points - additionalPoints;
  //         const weekLimitAfterDeduction =
  //           data.credit_points_week_limit - additionalPoints;
  //         setTotalCreditAfterDeduction(totalAfterDeduction);
  //         setCreditWeekLimitAfterDeduction(weekLimitAfterDeduction);

  //         if (totalAfterDeduction < 0 || weekLimitAfterDeduction < 0) {
  //           setErrorMessage(
  //             totalAfterDeduction < 0
  //               ? "Insufficient Credit Points."
  //               : "Weekly credit limit exceeded. Ask your Team Owner to refill coins."
  //           );
  //           stop_find_all_pages_of_this_website_started();
  //           return;
  //         }
  //       }

  //       process_queue();
  //     } else {
  //       process_queue();
  //     }
  //   } else {
  //     process_queue();
  //   }
  // };

  const [organic_keywords, set_organic_keywords] = useState("");
  const find_organic_keywords = () => {};

  const [wishlist_website, set_wishlist_website] = useState("");
  const [generated_queries, set_generated_queries] = useState("");

  const create_search_queries = (
    organic_keywords_param,
    wishlist_website_param
  ) => {
    console.log("wishlist_website_param");
    console.log(wishlist_website_param);
    console.log("organic_keywords_param");
    console.log(organic_keywords_param);
    var wishlist_website_array = [];
    if (wishlist_website_param != "") {
      wishlist_website_array = wishlist_website_param
        .split("\n")
        .filter((url) => url.trim() !== "");
    } else {
      wishlist_website_array = wishlist_website
        .split("\n")
        .filter((url) => url.trim() !== "");
    }
    //console.log(wishlist_website_array);

    var organic_keywords_array = [];
    if (organic_keywords_param != "") {
      organic_keywords_array = organic_keywords_param
        .split("\n")
        .filter((keyword) => keyword.trim() !== "");
    } else {
      organic_keywords_array = organic_keywords
        .split("\n")
        .filter((keyword) => keyword.trim() !== "");
    }
    //console.log(organic_keywords_array);

    //const organic_keywords_array = organic_keywords.split('\n').filter(keyword => keyword.trim() !== '');
    if (
      wishlist_website_array.length === 0 ||
      organic_keywords_array.length === 0
    ) {
      set_generated_queries(
        "Please enter active blogger URLs and client keywords."
      );
      return;
    }
    let generatedQueries = "";
    wishlist_website_array.forEach((url) => {
      organic_keywords_array.forEach((keyword) => {
        generatedQueries += `site:${url} ${keyword}\n`;
      });
    });
    if (generatedQueries.length > 0) {
      generatedQueries = generatedQueries.slice(0, -1);
    }
    set_generated_queries(generatedQueries);
  };

  //const running_search = useRef(0);
  const [running_search, set_running_search] = useState(false);
  const [showStillSearching, set_showStillSearching] = useState(false);
  const [search_data, set_search_data] = useState([]);
  const [search_results, set_search_results] = useState([]);
  const [blog_urls_list, set_blog_urls_list] = useState([]);

  const [search_running, set_search_running] = useState(false);
  const [progress, set_Progress] = useState(0);
  const [no_of_pages, set_no_of_pages] = useState(1);
  const [no_of_results, set_no_of_results] = useState(10);
  const [serper_search_domain, set_serper_search_domain] = useState("search");

  const run_search_queries_apify = async () => {
    set_show_should_we_run_search_queries_apify_modal(false);

    //set_running_search(true);
    var request_data = {
      customDataFunction:
        "async ({ input, $, request, response, html }) => {\n  return {\n    pageTitle: $('title').text(),\n  };\n};",
      includeUnfilteredResults: false,
      maxPagesPerQuery: 1,
      mobileResults: false,
      queries: generated_queries,
      resultsPerPage: 5,
      saveHtml: false,
      saveHtmlToKeyValueStore: false,
    };
    //fetch(`http://localhost:8002/run_async_actor_tasks`, {
    fetch(
      "https://api.apify.com/v2/acts/apify~google-search-scraper/runs?token=apify_api_NuJ4OFFaBP1mjEGS2pLIMYxGHsIxk30nDeUT",
      {
        method: "POST",
        body: JSON.stringify(request_data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((taskData) => {
        //set_running_search(1);
        console.log("raw task Data");
        console.log(taskData);
        const checkTaskStatus = () => {
          //fetch(`http://localhost:8002/async_check_status?status=RUNNING`)
          //fetch(`http://localhost:8002/async_check_status?status=SUCCEEDED`)
          fetch(
            `https://api.apify.com/v2/actor-runs/${taskData.data.id}?token=apify_api_NuJ4OFFaBP1mjEGS2pLIMYxGHsIxk30nDeUT`
          )
            .then((response) => response.json())
            .then((taskStatus) => {
              if (taskStatus.data.status === "SUCCEEDED") {
                console.log("Task completed. Fetching results...");
                //fetch(`http://localhost:8002/get_datasets`)
                fetch(
                  `https://api.apify.com/v2/datasets/${taskStatus.data.defaultDatasetId}/items?token=apify_api_NuJ4OFFaBP1mjEGS2pLIMYxGHsIxk30nDeUT`
                )
                  .then((response) => response.json())
                  .then((results) => {
                    console.log("Results:");
                    console.log(results);
                    // Process and use the results as needed
                    const flattenedArray = results.reduce((acc, current) => {
                      return acc.concat(current.organicResults);
                    }, []);
                    var indexedRows = [];
                    for (let i = 0; i < exchanger_website_pages.length; i++) {
                      //console.log("i-"+String(i));
                      const page = exchanger_website_pages[i];
                      for (let j = 0; j < flattenedArray.length; j++) {
                        //console.log("i-"+String(i)+" , j-"+String(j));
                        const row = flattenedArray[j];
                        const combinedRow = {
                          search_result_id: i * flattenedArray.length + j + 1,
                          link_to: page,
                          link_to_data: "Not Analyzed",
                          link_from: row["url"],
                          link_from_data: "Not Analyzed",
                          ...row,
                        };
                        indexedRows.push(combinedRow);
                      }
                    }

                    set_search_data(indexedRows);
                    console.log(
                      " ***************************************************** search_data "
                    );
                    console.log(indexedRows);
                    const urlArray = indexedRows.map((item) => item.url);
                    set_search_results(urlArray.join("\n"));
                    set_blog_urls_list(urlArray);
                    set_running_search(false);
                    set_showStillSearching(false);
                  })

                  // updateCreditPoints("Run Search with Apify")

                  .catch((error) =>
                    console.error("Failed to fetch results:", error)
                  );
              } else if (
                taskStatus.data.status === "RUNNING" ||
                taskStatus.data.status === "RETRYING"
              ) {
                set_running_search(true);
                set_showStillSearching(true);
                setTimeout(() => set_showStillSearching(false), 2000);
                setTimeout(checkTaskStatus, 5000);
              } else {
                set_running_search(false);
                set_showStillSearching(false);
                console.error("Task failed or has an unknown status.");
              }
            })
            .catch((error) =>
              console.error("Failed to check task status:", error)
            );
        };
        checkTaskStatus();
      })
      .catch((error) => {
        console.log("Data not got saved");
        //set_openDialog_MarkComplete(false);
      });
  };

  const run_search_queries_apify_update = async () => {
    await run_search_queries_apify();
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    //  }
    // setBulkProcessInProgress(true);
  };




  const run_search_queries_serper = async () => {
    // Clear previous results and set search state
    set_search_results([]);
    set_search_data([]);

    set_blog_urls_list([]);
    set_search_running(true);
    set_Progress(0);

    // Split search queries into batches
    var search_query_list = generated_queries.split("\n");
    const batchSize = 90; // Adjust batch size as needed
    const totalQueries = search_query_list.length;

    // Iterate over batches of search queries
    for (
      let startIndex = 0;
      startIndex < totalQueries;
      startIndex += batchSize
    ) {
      const endIndex = Math.min(startIndex + batchSize, totalQueries);
      const batchQueries = search_query_list.slice(startIndex, endIndex);

      // Execute search batch
      await searchBatch(batchQueries);

      // Update progress
      set_Progress((100 * endIndex) / totalQueries);
    }

    //////////////////////////////////////////////////////

    // Reset search state
    set_search_running(false);
  };

  const searchBatch = async (batchQueries, maxRetries = 3) => {
    set_show_should_we_run_search_queries_serper_modal(false);
    let retryCount = 0;

    while (retryCount < maxRetries) {
      try {
        // Prepare request body
        const requestBody = batchQueries.map((query) => ({
          q: query,
          autocorrect: false,
          page: no_of_pages,
          num: no_of_results,
        }));
        const requestOptions = {
          method: "POST",
          headers: {
            "X-API-KEY": "31fdf7ff042b58b13eb23a446d3df4be924ce6aa",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
          redirect: "follow",
        };

        // Fetch results from google.serper
        const response = await fetch(
          "https://google.serper.dev/" + serper_search_domain,
          requestOptions
        );
        //const response = await fetch("http://localhost:8002/get_serper_data_"+serper_search_domain, requestOptions);

        const results = await response.json();

        // Process and update search results
        for (const result of results) {
          await set_search_data((prevResults) => {
            console.log(
              " --------------------------------------------------------------- new loop --------------------------------------------------------------- "
            );
            console.log(
              "----------------------------------------------------------------------- prevResults"
            );
            console.log(prevResults);

            var prevLinksAndTitles = [];
            if (prevResults.length > 0) {
              prevLinksAndTitles = prevResults.map(({ link_from, title }) =>
                JSON.stringify({ link: link_from, title })
              );
            }

            //console.log("----------------------------------------------------------------------- 1");
            var resultLinksAndTitles = [];
            if (serper_search_domain == "search") {
              resultLinksAndTitles = Array.from(
                new Set(
                  result.organic.map((item) =>
                    JSON.stringify({ link: item.link, title: item.title })
                  )
                )
              );
            } else if (serper_search_domain == "news") {
              console.log(
                "----------------------------------------------------------------------- news"
              );
              console.log(result);
              resultLinksAndTitles = Array.from(
                new Set(
                  result.news.map((item) =>
                    JSON.stringify({ link: item.link, title: item.title })
                  )
                )
              );
              console.log(
                "----------------------------------------------------------------------- news ends 1"
              );
            }
            console.log(
              "----------------------------------------------------------------------- news ends 2"
            );
            console.log(prevLinksAndTitles);
            console.log(resultLinksAndTitles);

            // Merge unique links and titles from prevResults and result
            var mergedLinksAndTitles = Array.from(
              new Set([...prevLinksAndTitles, ...resultLinksAndTitles])
            ).map(JSON.parse);

            console.log(
              "----------------------------------------------------------------------- mergedLinksAndTitles"
            );
            console.log(mergedLinksAndTitles);
            var indexedRows = [];
            for (let i = 0; i < exchanger_website_pages.length; i++) {
              //console.log("i-"+String(i));
              const page = exchanger_website_pages[i];
              for (let j = 0; j < mergedLinksAndTitles.length; j++) {
                //console.log("i-"+String(i)+" , j-"+String(j));
                const row = mergedLinksAndTitles[j];
                const combinedRow = {
                  search_result_id: i * mergedLinksAndTitles.length + j + 1,
                  link_to: page,
                  link_to_data: "Not Analyzed",
                  link_from: row["link"],
                  title: row["title"],
                  link_from_data: "Not Analyzed",
                };
                indexedRows.push(combinedRow);
              }
            }
            //set_search_data(indexedRows);
            console.log(
              "----------------------------------------------------------------------- final indexedRows "
            );
            console.log(indexedRows);
            /////////////////////////////////////

            return indexedRows;
          });
        }

        // If the request is successful, break out of the retry loop
        break;
      } catch (error) {
        console.log("Error:", error);
        retryCount++;
        console.log(`Retrying... (Attempt ${retryCount}/${maxRetries})`);
      }
    }
  };

  const handleDelete = (record_id) => {
    const updatedData = search_data.filter(
      (item) => item.search_result_id !== record_id
    );
    set_search_data(updatedData);
  };

  const handleDeleteMultiple = (record_ids) => {
    const updatedData = search_data.filter(
      (item) => !record_ids.includes(item.search_result_id)
    );
    set_search_data(updatedData);
  };

  const get_prompt = (blog_url) => {
    //console.log(blog_url);
    var classification_prompt =
      "\nThe URL of the webpage is " +
      blog_url["link_from"] +
      "\n" +
      "The webpage title is " +
      blog_url["title"] +
      "\n\n" +
      "Based on the above webpage URL and webpage title, now classify the webpage in one of the following classes:\n" +
      //"Based on the above webpage URL, now classify the webpage in one of the following classes:\n" +
      "- About Us Page\n" +
      "- Blog Page\n" +
      "- Contact Us Page\n" +
      "- FAQ Page\n" +
      "- Home Page\n" +
      "- Landing Page\n" +
      "- News Page\n" +
      "- Privacy Policy Page\n" +
      "- Service Page\n" +
      "- Terms of Service Page\n" +
      "- Testimonials/Reviews Page\n" +
      "- Product Page\n" +
      "- Ecommerce Product Page\n" +
      "- Sales Page\n" +
      "- Forum Page\n" +
      "- Job Listing Page\n" +
      "- Category Page\n" +
      "- Tag Page\n" +
      "- Ecommerce Category Page\n" +
      "- Blog Page of Product Listicles\n" +
      "- Directory Listing Page\n" +
      "- Pricing Page\n" +
      "- Resource Blog Page\n" +
      "- Profile Page\n" +
      "- Product Listicles Blog Page\n" +
      "- Help Desk Pages\n\n" +
      "Remember, I want only one class without any explanation.";
    //console.log(classification_prompt);
    return classification_prompt;
  };

  const classify_apiEndpoints = [
    "https://m5p5ee4g5l.execute-api.us-east-2.amazonaws.com",
  ];

  const [progress_classify, set_progress_classify] = useState(0);

  const handleClassifyMultiple = (record_ids) => {
    set_show_should_we_classify_urls_modal(false);

    set_progress_classify(0);
    let filteredDataWithRecordIds = search_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );
    //let uniqueBlogURLs = [...new Set(filteredDataWithRecordIds.map(item => item.link_from))];
    let BlogURLs_list = [
      ...new Set(
        filteredDataWithRecordIds.map((item) => ({
          link_from: item.link_from,
          title: item.title,
        }))
      ),
    ];
    let uniqueBlogURLs = BlogURLs_list.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.link_from === value.link_from)
    );

    //console.log(BlogURLs_list);
    //console.log(uniqueBlogURLs);

    const totalRows = uniqueBlogURLs.length;
    const batchSize = 10; // Number of API calls per batch
    const totalBatches = Math.ceil(totalRows / batchSize);
    console.log("-------------------------------------");
    //console.log(filteredDataWithRecordIds);
    //console.log(uniqueBlogURLs);

    let updatedData = [...search_data];

    const classifyBatch = async (batchIndex) => {
      const startIndex = batchIndex * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batchUniqueBlogURLs = uniqueBlogURLs.slice(startIndex, endIndex);

      //const apiIndex = batchIndex % classify_apiEndpoints.length; // Rotating through API endpoints

      const classifyURL = async (blogURL) => {
        const index = uniqueBlogURLs.indexOf(blogURL);
        const record_id = record_ids[index];
        const classification_prompt = get_prompt(blogURL);
        const apiIndex = index % classify_apiEndpoints.length;

        const currentProgress =
          ((startIndex + batchUniqueBlogURLs.indexOf(blogURL) + 1) /
            totalRows) *
          100;

        try {
          console.log(currentProgress);
          const response = await fetch(classify_apiEndpoints[apiIndex], {
            method: "POST",
            body: JSON.stringify({
              request_data: { prompt: classification_prompt },
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.ok) {
            const data = await response.json();
            console.log(
              `Classification result for blog_url ${blogURL["link_from"]}:`,
              data.response
            );

            // Update data based on blog_url
            updatedData = updatedData.map((item) =>
              item.link_from === blogURL["link_from"]
                ? { ...item, classification: data.response }
                : item
            );

            set_search_data(updatedData);
          } else {
            console.error(
              `Error classifying blog_url ${blogURL["link_from"]}:`,
              response.statusText
            );
          }
          set_progress_classify(currentProgress);
        } catch (error) {
          console.error(
            `Error classifying blog_url ${blogURL["link_from"]}:`,
            error
          );
          set_progress_classify(currentProgress);
        }
      };

      const classifyBatchURLs = batchUniqueBlogURLs.map(classifyURL);
      await Promise.all(classifyBatchURLs);

      if (startIndex + batchSize < totalRows) {
        classifyBatch(batchIndex + 1); // Proceed to the next batch
      } else {
        set_progress_classify(100); // Set progress to 100% when processing is complete
      }
    };

    classifyBatch(0);
  };
  const handleCheckSimilarityScore = async (record_ids) => {
    set_show_should_we_calculate_similarity_score_modal(false);

    console.log(search_data);
    console.log(record_ids);

    const apiEndpoints = [
      "https://y1oxjjpfx6.execute-api.us-east-2.amazonaws.com",
      "https://v7glcip0oc.execute-api.us-east-2.amazonaws.com",
      "https://1rywkx193j.execute-api.us-east-2.amazonaws.com",
      "https://xy2h6u8ypb.execute-api.us-east-2.amazonaws.com",
      "https://ywrjzw35i3.execute-api.us-east-2.amazonaws.com",
      "https://81y6yfloue.execute-api.us-east-2.amazonaws.com",
      "https://b17xmalcq9.execute-api.us-east-2.amazonaws.com",
      "https://o55ssq7cif.execute-api.us-east-2.amazonaws.com",
      "https://iarx06w3d9.execute-api.us-east-2.amazonaws.com",
      "https://prg3ei0xoe.execute-api.us-east-2.amazonaws.com",
    ];

    let batchIndex = 0;
    let updatedData = [...search_data];

    const checkSimilarityScoreForBatch = async (batchIndex) => {
      const startIdx = batchIndex * batchSize;
      const endIdx = Math.min(startIdx + batchSize, totalRecords);

      const batchPromises = [];

      for (let index = startIdx; index < endIdx; index++) {
        const record_id = record_ids[index];
        const record_data = search_data.find(
          (item) => item.search_result_id === record_id
        );
        const apiEndpoint = apiEndpoints[index % apiEndpoints.length]; // Cycle through the API endpoints

        const batchPromise = (async () => {
          try {
            const response = await fetch(apiEndpoint, {
              method: "POST",
              body: JSON.stringify({
                request_data: {
                  text1: record_data["link_to_data"],
                  text2: record_data["link_from_data"],
                },
              }),
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (response.ok) {
              const data = await response.json();
              console.log(
                `Classification result for record_id ${record_id}:`,
                data.response
              );
              updatedData = updatedData.map((item) =>
                item.search_result_id === record_id
                  ? {
                      ...item,
                      similarity_score: (data.similarity_score * 100).toFixed(
                        0
                      ),
                    }
                  : item
              );
            } else {
              console.error(
                `Error classifying record_id ${record_id}:`,
                response.statusText
              );
            }
          } catch (error) {
            console.error(`Error classifying record_id ${record_id}:`, error);
          }
        })();

        batchPromises.push(batchPromise);
      }

      await Promise.all(batchPromises);
      set_search_data(updatedData);
    };

    const batchSize = 10;
    const totalRecords = record_ids.length;
    const totalBatches = Math.ceil(totalRecords / batchSize);

    const processingBatches = [];

    for (batchIndex = 0; batchIndex < Math.min(5, totalBatches); batchIndex++) {
      const processingBatch = checkSimilarityScoreForBatch(batchIndex);
      processingBatches.push(processingBatch);
    }

    while (processingBatches.length > 0) {
      const [winnerIndex, winnerPromise] = await Promise.race(
        processingBatches.map((p, index) => [index, p])
      );
      processingBatches.splice(winnerIndex, 1);

      if (batchIndex < totalBatches) {
        const newBatch = checkSimilarityScoreForBatch(batchIndex);
        processingBatches.push(newBatch);
        batchIndex++;
      }
    }
  };
  const handleClassifyMultipleAuthor = async (selectedIds) => {
    set_show_should_we_get_author_modal(false);

    const uniqueLinkFromValues = [
      ...new Set(
        selectedIds.map(
          (id) =>
            search_data.find((item) => item.search_result_id === id)?.link_from
        )
      ),
    ];

    const totalRequests = uniqueLinkFromValues.length;
    const batchSize = 10;
    const totalBatches = Math.ceil(totalRequests / batchSize);

    let updatedData = [...search_data];

    const processBatch = async (batchIndex) => {
      const startIndex = batchIndex * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRequests);
      const batchLinkValues = uniqueLinkFromValues.slice(startIndex, endIndex);

      console.log("Batch Link Values:", batchLinkValues);

      for (const linkValue of batchLinkValues) {
        console.log("Processing linkValue:", linkValue);
        const rowData = search_data.find(
          (item) => item.link_from === linkValue
        );
        console.log("Row Data:", rowData);
        const link_from_data = rowData?.link_from_data;

        try {
          console.log("Fetching author info for URL:", linkValue);
          const response = await fetch(
            getFullApiUrl(SCRAPE_WEBSITE_TO_FIND_AUTHOR),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                link_from_data: link_from_data,
                link_from: linkValue,
              }),
            }
          );

          if (!response.ok) {
            console.error("Failed to fetch author info for URL:", linkValue);
            continue;
          }

          const data = await response.json();
          console.log("Fetched data:", data);

          updatedData = updatedData.map((item) => {
            if (item.link_from === linkValue) {
              return {
                ...item,
                author_name: data.author_name || "Unknown Author",
                contact_number: data.contact_number || "N/A",
                email_id: data.email_id || "N/A",
                linkedin_link: data.linkedin_link || "N/A",
                twitter_link: data.twitter_link || "N/A",
                contact_link: data.contact_link || "N/A",
              };
            }
            return item;
          });
        } catch (error) {
          console.error("Error fetching author info:", error);
        }
      }

      set_search_data(updatedData);

      if (startIndex + batchSize < totalRequests) {
        processBatch(batchIndex + 1);
      } else {
        // Handle completion, if needed
      }
    };

    processBatch(0);
  };

  const [author_info, set_author_info] = useState();
  const [selected_ids_to_scrape, set_selected_ids_to_scrape] = useState();

  const validate_get_author = async (params, button) => {
    if (!scrapingPerformed) {
      setNotificationMessage("Implement Scrap before implementing this");
      setOpenSnackbar(true);
      return;
    }
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      return; // Exit the function early
    }

    // Extracting search_result_id list from params
    const selectedIds = params;
    const totalRows = selectedIds.length;
    set_selected_url_to_get_author(params);

    // set_selected_url_for_scrape(params);

    //console.log(uniqueLinkWithFields);
    // const totalRows = uniqueLinkWithFields.length;
    const deduction_point = await getCreditPointToDeductByButton(button);

    // const totalRows = rows.length;
    // var scrapped_count = rows.reduce(
    //   (count, row) => count + (row["scrapedData"] !== "" ? 1 : 0),
    //   0
    // );

    // const deduction_point = 1; // Assuming a fixed deduction point for now
    // const credits_to_be_deducted = totalRows * parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat((totalRows * parseFloat(deduction_point)).toFixed(3));

    // Set deduction points in the state
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_get_author_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await handleClassifyMultipleAuthor(selected_url_to_get_author);
    await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_get_author_modal(true);
    }
  };
  const processGetAuthorAndUpdate = async () => {
    
  };
  const validate_classify_urls = async (params, button) => {
    if (!scrapingPerformed) {
      setNotificationMessage("Implement Scrap before implementing this");
      setOpenSnackbar(true);
      return;
    }
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      return; // Exit the function early
    }

    const selectedIds = params;
    const totalRows = selectedIds.length;
    set_selected_url_to_classify(params);


    const deduction_point = await getCreditPointToDeductByButton(button);


    var not_scrapped_count = totalRows;


    const credits_to_be_deducted = parseFloat((not_scrapped_count * parseFloat(deduction_point)).toFixed(3));

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_classify_urls_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await handleClassifyMultiple(selected_url_to_classify);
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_classify_urls_modal(true);
    }

    // If all conditions passed, open the modal
  };
  const processClassificatonAndUpdate = async () => {
    await handleClassifyMultiple(selected_url_to_classify);
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    //  }
    // setBulkProcessInProgress(true);
  };
  const validate_similarity_of_urls = async (params, button) => {
    if (!scrapingPerformed) {
      setNotificationMessage("Implement Scrap before implementing this");
      setOpenSnackbar(true);
      return;
    }
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      return; // Exit the function early
    }

    // Extracting search_result_id list from params
    const selectedIds = params;
    const totalRows = selectedIds.length;
    set_selected_urls_to_calculate_similarity_score(params);

    // set_selected_url_for_scrape(params);

    //console.log(uniqueLinkWithFields);
    // const totalRows = uniqueLinkWithFields.length;
    const deduction_point = await getCreditPointToDeductByButton(button);

    // const totalRows = rows.length;
    // var scrapped_count = rows.reduce(
    //   (count, row) => count + (row["scrapedData"] !== "" ? 1 : 0),
    //   0
    // );
    var not_scrapped_count = totalRows;

    // const deduction_point = 1; // Assuming a fixed deduction point for now
    // const credits_to_be_deducted = not_scrapped_count * parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat((not_scrapped_count * parseFloat(deduction_point)).toFixed(3));

    // Set deduction points in the state
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_calculate_similarity_score_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await handleCheckSimilarityScore(
        selected_urls_to_calculate_similarity_score
      );
      //  if (!bulkProcessInProgress) {
      await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_calculate_similarity_score_modal(true);
    }

    // If all conditions passed, open the modal
  };
  const processSimilarityScoreAndUpdate = async () => {
    await handleCheckSimilarityScore(
      selected_urls_to_calculate_similarity_score
    );
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    //  }
    // setBulkProcessInProgress(true);
  };
  const validate_scrape_urls = async (params, button) => {
    // if (!params || !Array.isArray(params) || params.length === 0) {
    //   console.error("Invalid or empty params:", params);
    //   return; // Exit the function early
    // }

    // Extracting search_result_id list from params
    // const selectedIds = params;
    const totalRows = search_data.length;
    set_selected_url_for_scrape(totalRows);

    // set_selected_url_for_scrape(params);
    const updatedData = [...search_data];
    const uniqueLinkToValues = [
      ...new Set(updatedData.map((item) => item.link_to)),
    ];
    const uniqueLinkFromValues = [
      ...new Set(updatedData.map((item) => item.link_from)),
    ];
    const uniqueLinkWithFields = [
      ...uniqueLinkToValues.map((linkValue) => ({
        linkValue,
        key: "link_to",
        dataField: "link_to_data",
        scrapedData: "",
      })),
      ...uniqueLinkFromValues.map((linkValue) => ({
        linkValue,
        key: "link_from",
        dataField: "link_from_data",
        scrapedData: "",
      })),
    ];
    //console.log(uniqueLinkWithFields);
    // const totalRows = uniqueLinkWithFields.length;
    const deduction_point = await getCreditPointToDeductByButton(button);

    // const totalRows = rows.length;
    // var scrapped_count = rows.reduce(
    //   (count, row) => count + (row["scrapedData"] !== "" ? 1 : 0),
    //   0
    // );
    var not_scrapped_count = totalRows;

    // const deduction_point = 1; // Assuming a fixed deduction point for now
    // const credits_to_be_deducted = not_scrapped_count * parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat((not_scrapped_count * parseFloat(deduction_point)).toFixed(3));

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_scrape_urls_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await handleScrapMultiple(selected_url_for_scrape);
    await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_scrape_urls_modal(true);
    }
  };
  const processScrapeAndUpdate = async () => {
    await handleScrapMultiple(selected_url_for_scrape);
    await updateCreditPoints(deductionPoints);
  };
  const validate_scrape_individual_urls = async (params, button) => {
    set_selected_individual_url_for_scrape(params);
    const deduction_point = await getCreditPointToDeductByButton(button);

    // const credits_to_be_deducted = parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat((1 * parseFloat(deduction_point)).toFixed(3));

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_scrape_individual_urls_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await api_call_scrape_urls(selected_individual_url_for_scrape);
    await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_scrape_individual_urls_modal(true);
    }

  };
  
  const processScrapeIndividualUrlAndUpdate = async () => {
    await api_call_scrape_urls(selected_individual_url_for_scrape);
    await updateCreditPoints(deductionPoints);
  };
  
 
  const [showEditScrapSection, set_showEditScrapSection] = useState(false);
  const [editableScrappedData, set_editableScrappedData] = useState("");
  const [selected_scrapper_option, set_selected_scrapper_option] = useState("");
  const [scrapping_field, set_scrapping_field] = useState({});

  const editScrapedData = (record_id, field, data_field) => {
    console.log(
      "-------------------------------------------------------------------------------"
    );
    console.log("record_id");
    console.log(record_id);
    const editableScrappedData_temp = search_data.find(
      (item) => item.search_result_id === record_id
    );
    console.log(
      "-------------------------------------------------------------------------------"
    );
    console.log("editableScrappedData_temp");
    console.log(editableScrappedData_temp);
    set_editableScrappedData(editableScrappedData_temp);
    console.log({ field: field, data_field: data_field });
    console.log(editableScrappedData[scrapping_field["data_field"]]);

    set_scrapping_field({ field: field, data_field: data_field });
    set_showEditScrapSection(true);
  };

  const handleClose = () => {
    set_showEditScrapSection(false);
    //set_show_selectScrapOption_Section(false);

    //setOpen(false);
  };

  const api_call_scrape_urls = (url) => {
    set_show_should_we_scrape_individual_urls_modal(false);
    console.log(selected_scrapper_option);
    fetch(getFullApiUrl(SCRAPE_URLS), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          urls: [url],
          scrapper: selected_scrapper_option,
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          button: "Run Scrapper",
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data["response"]);

        var temp_editableScrappedData = { ...editableScrappedData };
        temp_editableScrappedData[scrapping_field["data_field"]] =
          data["response"];
        set_editableScrappedData(temp_editableScrappedData);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Didn't got the data");
      });
    //set_show_selectScrapOption_Section(false);
  };

  const changeEditScrappedData = (event) => {
    var temp_editableScrappedData = { ...editableScrappedData };
    console.log(
      "-------------------------------------------------------------------------------"
    );
    console.log("temp_editableScrappedData");
    console.log(temp_editableScrappedData);
    //temp_editableScrappedData["link_from_data"] = event.target.value;
    temp_editableScrappedData[scrapping_field["data_field"]] =
      event.target.value;
    set_editableScrappedData(temp_editableScrappedData);
  };
  /*
  const saveEditableScrappedData = () =>
  {
    const updatedSearchData = search_data.map(item => {
      if (item[scrapping_field["field"]] === editableScrappedData[scrapping_field["field"]]) {
        console.log(item.search_result_id);
        return { ...item, scrapping_field["data_field"]: editableScrappedData[scrapping_field["data_field"]] };
      }
      return item;
    });
    set_search_data(updatedSearchData);
    set_showEditScrapSection(false);
  }
  */
  const saveEditableScrappedData = () => {
    console.log(scrapping_field["field"]);
    console.log(scrapping_field["data_field"]);

    const updatedSearchData = search_data.map((item) => {
      if (
        item[scrapping_field["field"]] ===
        editableScrappedData[scrapping_field["field"]]
      ) {
        console.log(item.search_result_id);
        return {
          ...item,
          [scrapping_field["data_field"]]:
            editableScrappedData[scrapping_field["data_field"]],
        };
      }
      return item;
    });
    console.log(updatedSearchData);
    set_search_data(updatedSearchData);
    set_showEditScrapSection(false);
  };

  const open_in_new_tab = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    // Check if all link_from_data and link_to_data are not 'Not Analyzed'
    const allScraped = search_data.every(
      (item) =>
        item.link_from_data !== "Not Analyzed" &&
        item.link_to_data !== "Not Analyzed"
    );

    // Update scrapingPerformed state based on the condition
    setScrapingPerformed(allScraped);
  }, [search_data]);


  const handleScrapMultiple = async (record_ids) => {
    set_show_should_we_scrape_urls_modal(false);

    const apiEndpoints = [
      "https://5po5s0eo70.execute-api.us-east-2.amazonaws.com",
      "https://l4bzdp8da9.execute-api.us-east-2.amazonaws.com",
      "https://h0fary5mbh.execute-api.us-east-2.amazonaws.com",
      "https://o0od5remnh.execute-api.us-east-2.amazonaws.com",
      "https://g1gpp5coqe.execute-api.us-east-2.amazonaws.com",
      "https://xmmi48n6i6.execute-api.us-east-2.amazonaws.com",
      "https://7wgl5kfmh3.execute-api.us-east-2.amazonaws.com",
      "https://3urq85a0hg.execute-api.us-east-2.amazonaws.com",
      "https://5slhaq9nx5.execute-api.us-east-2.amazonaws.com",
      "https://u9c84acq7l.execute-api.us-east-2.amazonaws.com",
    ];

    const updatedData = [...search_data];
    const uniqueLinkToValues = [
      ...new Set(updatedData.map((item) => item.link_to)),
    ];
    const uniqueLinkFromValues = [
      ...new Set(updatedData.map((item) => item.link_from)),
    ];

    const uniqueLinkWithFields = [
      ...uniqueLinkToValues.map((linkValue) => ({
        linkValue,
        key: "link_to",
        dataField: "link_to_data",
        scrapedData: "",
      })),
      ...uniqueLinkFromValues.map((linkValue) => ({
        linkValue,
        key: "link_from",
        dataField: "link_from_data",
        scrapedData: "",
      })),
    ];

    setShowScrapProgressSection(true);

    const totalRows = uniqueLinkWithFields.length;
    const error_codes = ["Error", "404"];
    const scrapperValues = ["BeautifulSoup", "Webcache"];
    let scrap_processed = 0;
    let scrapped_count = 0;
    let not_scrapped_count = 0;
    const batchSize = 10; // Number of API calls per batch
    const totalBatches = Math.ceil(totalRows / batchSize);
    const concurrentBatchLimit = 1; // Number of concurrent batches

    const process_single_scrapped_data = async (responseObj) => {
      scrap_processed++;
      let got_scrapped = 0;
      let got_response = 0;
      let response_text = "";

      if (responseObj.response !== null) {
        const { dataField, response } = responseObj;
        if (response !== "" && response !== undefined) {
          got_response = 1;
          if (
            !error_codes.some((error_code) => response.includes(error_code))
          ) {
            got_scrapped = 1;
            response_text = response;
          }
        }
      }

      if (got_scrapped == 1) {
        scrapped_count++;
      } else {
        not_scrapped_count++;
      }

      const updatedProgress = {
        scrap_processed,
        scrapped_count,
        not_scrapped_count,
        total_count: totalRows,
      };
      setScrapingProgress(updatedProgress);

      if (got_response == 1) {
        for (let index = 0; index < updatedData.length; index++) {
          const item = updatedData[index];
          const { key, linkValue, dataField, response } = responseObj;
          if (item[key] === linkValue) {
            updatedData[index][dataField] = response_text;
          }
        }

        set_search_data([...updatedData]);
      }
    };

    const MAX_RETRIES = 2;

    const retryFetchWithLoadBalancing = async (
      apiIndex,
      requestData,
      retries = 0
    ) => {
      try {
        const response = await fetch(apiEndpoints[apiIndex], {
          method: "POST",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (
            data.hasOwnProperty("response") &&
            Array.isArray(data["response"]) &&
            data["response"].length > 0
          ) {
            const firstResponse = data["response"][0];
            if (
              firstResponse.hasOwnProperty("text") &&
              firstResponse["text"] !== "" &&
              firstResponse["text"] !== null
            ) {
              return data;
            }
          }
        }

        if (retries < MAX_RETRIES) {
          const scrapper = scrapperValues[retries % 2];
          requestData.request_data.scrapper = scrapper;
          const delay = 1000 * Math.pow(2, retries);
          await new Promise((resolve) => setTimeout(resolve, delay));
          return retryFetchWithLoadBalancing(
            apiIndex,
            requestData,
            retries + 1
          );
        }

        return { response: "" };
      } catch (error) {
        return { response: "" };
      }
    };

    const runBatch = async (currentBatch) => {
      const startIndex = currentBatch * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);

      for (let i = startIndex; i < endIndex; i++) {
        const row =
          i < uniqueLinkToValues.length
            ? {
                linkValue: uniqueLinkToValues[i],
                key: "link_to",
                dataField: "link_to_data",
                scrapedData: "",
              }
            : {
                linkValue: uniqueLinkFromValues[i - uniqueLinkToValues.length],
                key: "link_from",
                dataField: "link_from_data",
                scrapedData: "",
              };

        if (row["scrapedData"] === "") {
          const apiIndex = i % apiEndpoints.length;
          const requestData = {
            request_data: {
              urls: [row["linkValue"]],
              scrapper: scrapperValues[0],
            },
          };

          retryFetchWithLoadBalancing(apiIndex, requestData)
            .then((data) => {
              process_single_scrapped_data({
                ...row,
                index: i,
                response: data["response"][0]["text"],
              });
            })
            .catch(() => {
              process_single_scrapped_data({
                ...row,
                index: i,
                response: null,
              });
            });
        }
      }
    };

    const currentBatchPromises = [];
    for (let batch = 0; batch < totalBatches; batch += concurrentBatchLimit) {
      for (
        let concurrentBatchIndex = 0;
        concurrentBatchIndex < concurrentBatchLimit;
        concurrentBatchIndex++
      ) {
        const currentBatch = batch + concurrentBatchIndex;
        if (currentBatch < totalBatches) {
          currentBatchPromises.push(runBatch(currentBatch));
        }
      }
      if (currentBatchPromises.length > 100) {
        const [winnerIndex] = await Promise.race(
          currentBatchPromises.map((promise, index) =>
            promise.then(() => index)
          )
        );
        currentBatchPromises.splice(winnerIndex, 1);
      }
    }
  };

  
  useEffect(() => {
    handle_load_campaign_data();
    // get_campaign_list();
  }, []);

  function padNumber(number) {
    return number < 10 ? `0${number}` : number;
  }

  function createProjectName() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    const campaignName = `Project_${year}_${padNumber(month)}_${padNumber(
      day
    )}_${padNumber(hours)}_${padNumber(minutes)}_${padNumber(seconds)}`;
    return campaignName;
  }

  const [is_old_campaign, set_is_old_campaign] = useState(-1);
  const [campaign_id, set_campaign_id] = useState("");

  const go_to_campaigns_list = async () => {
    set_is_old_campaign(-1);
  };
  const handle_new_campaign = async () => {
    var campaign_id = createProjectName();
    set_campaign_id(campaign_id);

    set_exchanger_website_url("");
    set_exchanger_website_pages([]);
    set_organic_keywords("");
    set_wishlist_website("");
    set_generated_queries("");
    set_search_data([]);

    set_is_old_campaign(0);
  };
  const [saving, set_saving] = useState(false);

  const handle_load_campaign_data = async () => {
    console.log("....................");
    // console.log(id);
    var request_data = {
      campaign_id: campaignId,
    };
    fetch(getFullApiUrl(LOAD_CAMPAIGN_FROM_S3), {
      //fetch('https://api.apify.com/v2/acts/apify~google-search-scraper/runs?token=apify_api_NuJ4OFFaBP1mjEGS2pLIMYxGHsIxk30nDeUT', {
      method: "POST",
      body: JSON.stringify(request_data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((taskData) => {
        console.log("....................");
        console.log(taskData);
        //console.log(taskData["data"]["exchanger_website_url"]);
        set_campaign_id(campaignId);
        set_exchanger_website_url(taskData["data"]["exchanger_website_url"]);
        set_exchanger_website_pages(
          taskData["data"]["exchanger_website_pages"]
        );
        set_organic_keywords(taskData["data"]["organic_keywords"]);
        set_wishlist_website(taskData["data"]["wishlist_website"]);
        set_generated_queries(taskData["data"]["generated_queries"]);
        set_search_data(taskData["data"]["search_data"]);

        set_is_old_campaign(1);
        console.log("....................");
      })
      .catch((error) => {
        console.log("............");
      });
  };
  const handle_save_campaign_data = async () => {
    set_saving(true);
    //set_is_old_campaign(1);
    console.log(
      "---------------------------------------------------------------------------------"
    );
    console.log(exchanger_website_url);
    console.log(exchanger_website_pages);
    console.log(organic_keywords);
    console.log(wishlist_website);
    console.log(generated_queries);
    console.log(search_data);
    console.log(
      "---------------------------------------------------------------------------------"
    );

    /*
    var request_data = {
      "campaign_id":"1",
      "json_data":{
        "exchanger_website_url": exchanger_website_url,
        "exchanger_website_pages": exchanger_website_pages,
        "organic_keywords": organic_keywords,
        "wishlist_website": wishlist_website,
        "generated_queries": generated_queries,
        "search_data": search_data
      }
    }
    */
    var request_data = {
      campaign_id: campaignId,
      json_data: {
        exchanger_website_url: exchanger_website_url,
        exchanger_website_pages: exchanger_website_pages,
        organic_keywords: organic_keywords,
        wishlist_website: wishlist_website,
        generated_queries: generated_queries,
        search_data: search_data,
      },
    };
    fetch(getFullApiUrl(SAVE_CAMPAIGN_FROM_S3), {
      //fetch('https://api.apify.com/v2/acts/apify~google-search-scraper/runs?token=apify_api_NuJ4OFFaBP1mjEGS2pLIMYxGHsIxk30nDeUT', {
      method: "POST",
      body: JSON.stringify(request_data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((taskData) => {
        console.log("....................");
        // get_campaign_list();
        set_saving(false);
      })
      .catch((error) => {
        console.log("............");
      });
  };

  const [editingProjectId, setEditingProjectId] = useState(null);
  const [editedProjectName, setEditedProjectName] = useState("");

  const open_campaign_name_edit = (campaign_id) => {
    setEditingProjectId(campaign_id);
    setEditedProjectName(campaign_id);
  };
  const handle_change_campaign_name = async (campaign_id, newProjectName) => {
    setEditedProjectName(newProjectName);
  };
  
  //  {/* ******************************************************************************************************************************************************************************************************************************************************************************** */}

  const [hasAccess, setHasAccess] = useState(false);

  const fetchData = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
        }),
      };

      const response = await fetch(
        getFullApiUrl(CHECK_USER_CAMPAIGN_ACCESS),
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const checkAccessAndNavigate = (data) => {
    if (data && data === true) {
      // If access is granted
      setHasAccess(true); // Set hasAccess state to true
    } else {
      navigate("/"); // Navigate to home page if access is not granted
      alert("You don't have access to this page."); // Customize the message as needed
    }
  };

  useEffect(() => {
    const fetchDataAndCheckAccess = async () => {
      const data = await fetchData();
      checkAccessAndNavigate(data);
    };

    if (campaignId) {
      fetchDataAndCheckAccess();
    } else {
      navigate("/"); // Redirect to home page if campaignId is not provided
    }
  }, [campaignId]);

  // if (!campaignId) {
  //   navigate('/'); // Redirect to home page if campaignId is not provided
  //   return null; // Return null to avoid rendering anything
  // }

  useEffect(() => {
    getCampaignDetails();
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <div className="page_Section">
        <div className="leftSection_withMenu">
          <LeftSectionWithMenu />
        </div>

        <div className="rightSection_withMenu">
          <div className="headerSection_withMenu">
            <Header onToggleTheme={toggleTheme} />
          </div>

          <div className="bodySection">
            <Grid container spacing={0} sx={{}}>
              <Breadcrumbs
                id="Breadcrumbs"
                separator={<NavigateNext />}
                aria-label="breadcrumb"
              >
                <div className="breadcrumb_text">
                  <Link
                    component={RouterLink}
                    to="/"
                    color="inherit"
                    underline="hover"
                    className="breadcrumbs-link"
                  >
                    Home
                  </Link>
                </div>
                <div className="breadcrumb_text">
                  <Link
                    component={RouterLink}
                    to={`/my_teams`}
                    color="inherit"
                    underline="hover"
                    className="breadcrumbs-link"
                  >
                    MyTeams
                  </Link>
                </div>
                <div className="breadcrumb_text">
                  <Link
                    component={RouterLink}
                    to={`/my_team/${campaignData.team_id}`}
                    color="inherit"
                    underline="hover"
                    className="breadcrumbs-link"
                  >
                    {campaignData.team_name}
                  </Link>
                </div>
                <div className="breadcrumb_text">
                  <Link
                    component={RouterLink}
                    to={`/my_team_workspaces/${campaignData.team_id}`}
                    color="inherit"
                    underline="hover"
                    className="breadcrumbs-link"
                  >
                    {campaignData.team_name} Workspaces
                  </Link>
                </div>
                <div className="breadcrumb_text">
                  <Link
                    component={RouterLink}
                    to={`/my_workspace/${campaignData.workspace_id}`}
                    color="inherit"
                    underline="hover"
                    className="breadcrumbs-link"
                  >
                    {campaignData.workspace_name}
                  </Link>
                </div>
                <div className="breadcrumb_text">
                  <Link
                    component={RouterLink}
                    to={`/my_campaign/${campaignId}`}
                    color="inherit"
                    underline="hover"
                    className="breadcrumbs-link"
                  >
                    {campaignData.workspace_name} Campaigns
                  </Link>
                </div>
                <div className="breadcrumb_text">
                  <Link
                    component={RouterLink}
                    to={`/solutions/${campaignId}`}
                    color="inherit"
                    underline="hover"
                    className="breadcrumbs-link"
                  >
                    {campaignData.campaign_name}
                  </Link>
                </div>
                <div className="breadcrumb_text">
                    Quick link insert finder
                </div>
              </Breadcrumbs>

              <Grid className="main_content_container_without_leftbar">
                <div className="campaign_detail_section">
                  {saving == true ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handle_save_campaign_data()}
                      disabled
                      endIcon={<CircularProgress size="20" />}
                    >
                      Saving . . . . . . . . . .
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      endIcon={<SaveIcon />}
                      onClick={() => handle_save_campaign_data()}
                    >
                      Save Project Data
                    </Button>
                  )}
                </div>
                <div>
                  <div className="tab_containers">
                    <div className="tab_header_containers">
                      <div
                        className={`tab_header ${
                          activeTab === 1 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(1)}
                        onMouseEnter={() => handleTabHover(1)}
                        onMouseLeave={handleTabMouseLeave}
                      >
                        Link To
                      </div>
                      {/* Set Target Pages */}
                      <div
                        className={`tab_header ${
                          activeTab === 2 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(2)}
                        onMouseEnter={() => handleTabHover(2)}
                        onMouseLeave={handleTabMouseLeave}
                      >
                        Keywords
                      </div>
                      {/* Set Organic Keywords */}
                      <div
                        className={`tab_header ${
                          activeTab === 3 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(3)}
                        onMouseEnter={() => handleTabHover(3)}
                        onMouseLeave={handleTabMouseLeave}
                      >
                        Link From
                      </div>
                      {/* Set your wishlist */}
                      <div
                        className={`tab_header ${
                          activeTab === 4 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(4)}
                        onMouseEnter={() => handleTabHover(4)}
                        onMouseLeave={handleTabMouseLeave}
                      >
                        Google Search & Analyze
                      </div>
                      {/* aaa */}
                      <div
                        className={`tab_header ${
                          activeTab === 5 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(5)}
                        onMouseEnter={() => handleTabHover(5)}
                        onMouseLeave={handleTabMouseLeave}
                      >
                        Generate Suggestions
                      </div>
                      {/* aaa */}
                    </div>

                    <div id="scroller" className="scroller">
                      <div
                        id="tab_content_containers"
                        className="tab_content_containers"
                      >
                        <div
                          id="tab-content-1"
                          className={`tab_content ${
                            activeTab === 1 ? "active" : ""
                          }`}
                        >
                          <Accordion className="accordion">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Need our crawler to find pages to link to? - click
                              here
                            </AccordionSummary>
                            <AccordionDetails>
                              <TextField
                                value={exchanger_website_url}
                                onChange={(event) =>
                                  set_exchanger_website_url(event.target.value)
                                }
                                placeholder="Website you want to link to"
                                minRows={5}
                                style={{ width: "100%" }}
                              />

                              {find_all_pages_of_this_website_started ==
                              false ? (
                                <>
                                  <div className="button_container" style={{}}>
                                    <Button
                                      sx={{
                                        marginRight: "25px",
                                      }}
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      onClick={() =>
                                        validate_process_queue(
                                          "Find all pages of this Website"
                                        )
                                      }
                                    >
                                      Find all pages of this Website
                                    </Button>
                                    <Button
                                      sx={{}}
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        validate_resume_process_queue()
                                      }
                                    >
                                      Resume Finding
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="credits_info">
                                    <h3>Credits Information</h3>
                                    <p>
                                      Total Credit Points: {totalCreditPoints}
                                    </p>
                                    <p>
                                      Weekly Credit Points Limit:{" "}
                                      {creditPointsLimit}
                                    </p>
                                    <p>
                                      Total Credits Deducted:{" "}
                                      {totalCreditsDeducted}
                                    </p>
                                  </div>
                                  <div className="button_container">
                                    <Button
                                      sx={{}}
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      onClick={() =>
                                        stop_find_all_pages_of_this_website_started()
                                      }
                                    >
                                      Stop Finding
                                    </Button>
                                  </div>
                                  <Box
                                    sx={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <CircularProgress
                                      variant="determinate"
                                      value={
                                        (url_queue_progress_count.visited_urls_count /
                                          url_queue_progress_count.found_urls_count) *
                                        100
                                      }
                                      style={{ width: "70px", height: "70px" }}
                                    />
                                    <Box
                                      sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        component="div"
                                        color="text.secondary"
                                      >
                                        {
                                          url_queue_progress_count.visited_urls_count
                                        }{" "}
                                        /{" "}
                                        {
                                          url_queue_progress_count.found_urls_count
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </AccordionDetails>
                          </Accordion>
                          <TextareaAutosize
                            className="text_area_of_link_exchange"
                            value={exchanger_website_pages.join("\n")} // Convert the array to a comma-separated string
                            onChange={(event) =>
                              set_exchanger_website_pages(
                                event.target.value.split("\n")
                              )
                            } // Split the string into an array
                            placeholder="URLs you want to link to"
                            minRows={5}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div
                          id="tab-content-2"
                          className={`tab_content ${
                            activeTab === 2 ? "active" : ""
                          }`}
                        >
                          <Accordion className="accordion">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Do you want to find Organic Keywords Automatically
                              ? - click here
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="button_container">
                                <Button
                                  variant="contained"
                                  className="ButtonWithCustomFontSize"
                                  color="secondary"
                                  size="small"
                                  onClick={() => find_organic_keywords()}
                                >
                                  I want to find Organic Keywords Automatically
                                </Button>
                              </div>
                            </AccordionDetails>
                          </Accordion>

                          <TextareaAutosize
                            className="text_area_of_link_exchange"
                            value={organic_keywords}
                            onChange={(event) => {
                              set_organic_keywords(event.target.value);
                              create_search_queries(event.target.value, "");
                            }}
                            placeholder="Keywords to use when searching for opportunity"
                            minRows={5}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div
                          id="tab-content-3"
                          className={`tab_content ${
                            activeTab === 3 ? "active" : ""
                          }`}
                        >
                          <TextareaAutosize
                            className="text_area_of_link_exchange"
                            value={wishlist_website}
                            onChange={(event) => {
                              set_wishlist_website(event.target.value);
                              create_search_queries("", event.target.value);
                            }}
                            placeholder="Sites you want a link from"
                            minRows={5}
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div
                          id="tab-content-4"
                          className={`tab_content ${
                            activeTab === 4 ? "active" : ""
                          }`}
                        >
                          <Accordion className="accordion">
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Do you want to check Automatically generated
                              search queries ? - click here
                            </AccordionSummary>
                            <AccordionDetails>
                              <TextareaAutosize
                                value={generated_queries}
                                onChange={(event) =>
                                  set_generated_queries(event.target.value)
                                }
                                placeholder="Here you would get generated search queries"
                                minRows={5}
                                style={{ width: "100%" }}
                                className="text_area_of_link_exchange"
                              />
                            </AccordionDetails>
                          </Accordion>

                          {running_search === false ? (
                            <div className="button_containers">
                              <div className="button_container_apify apify_link_exchange">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                   className="ButtonWithCustomFontSize"
                                  onClick={() =>
                                    validate_run_search_queries_apify(
                                      "Run Search with Apify"
                                    )
                                  }
                                >
                                  Run Search with Apify
                                </Button>
                              </div>

                              {search_running ? (
                                <div
                                  style={{
                                    width: "10%",
                                    margin: "0px 2%",
                                    position: "relative",
                                  }}
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                  />
                                  <Typography
                                    style={{
                                      position: "absolute",
                                      color: "#00f",
                                      top: -22,
                                      left:
                                        Math.floor(progress).toFixed(0) + "%",
                                      transform: "translateX(-25%)",
                                    }}
                                  >
                                    {`${Math.floor(progress).toFixed(0)}%`}
                                  </Typography>
                                </div>
                              ) : (
                                <div class="search_option_section">
                                  No of pages :{" "}
                                  <TextField
                                    id="outlined-number"
                                    type="number"
                                    variant="standard"
                                    value={no_of_pages}
                                    onChange={(event) => {
                                      var inputValue = event.target.value;
                                      console.log(inputValue);
                                      if (inputValue >= 1 && inputValue <= 10) {
                                        console.log("condition pass");
                                        set_no_of_pages(parseInt(inputValue));
                                      }
                                    }}
                                    sx={{
                                      width: "35px",
                                    }}
                                  />
                                  &nbsp; &nbsp; &nbsp; &nbsp; No of results :{" "}
                                  <TextField
                                    id="outlined-number"
                                    type="number"
                                    variant="standard"
                                    value={no_of_results}
                                    onChange={(event) => {
                                      var inputValue = event.target.value;
                                      console.log(inputValue);
                                      if (
                                        inputValue >= 1 &&
                                        inputValue <= 100
                                      ) {
                                        console.log("condition pass");
                                        set_no_of_results(parseInt(inputValue));
                                      }
                                    }}
                                    sx={{
                                      width: "45px",
                                    }}
                                  />
                                  <div className="button_container_serper">
                                    <FormControl
                                      className="serper_search_domain"
                                      variant="standard"
                                      sx={{ m: 1, minWidth: 120 }}
                                    >
                                      <InputLabel id="demo-simple-select-standard-label">
                                        Domain
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={serper_search_domain}
                                        onChange={(event) =>
                                          set_serper_search_domain(
                                            event.target.value
                                          )
                                        }
                                        label="Domain"
                                      >
                                        <MenuItem value="search">web</MenuItem>
                                        <MenuItem value="news">news</MenuItem>
                                      </Select>
                                    </FormControl>

                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      onClick={() =>
                                        validate_run_search_queries_serper(
                                          "Run Search with Serper"
                                        )
                                      }
                                    >
                                      Run Search with Serper
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              <div
                                className={
                                  showStillSearching
                                    ? "show_still_searching"
                                    : "show_still_searching_fade_out"
                                }
                              >
                                We are still
                              </div>
                              &nbsp;Searching.........
                              <LinearProgress />
                            </>
                          )}

                          <br />

                          {show_scrap_progress_section && (
                            <div className="scraping_progress_bar_container">
                              <div className="scraping_progress_bar">
                                <LinearProgress
                                  sx={{
                                    height: "5px",
                                    "& .MuiLinearProgress-barColorPrimary": {
                                      backgroundColor: "#26d426",
                                    },
                                    "& .MuiLinearProgress-bar2Buffer": {
                                      backgroundColor: "#f15757",
                                    },
                                    "& .css-8ub8io-MuiLinearProgress-dashed": {
                                      backgroundImage:
                                        "radial-gradient(rgb(255 255 255) 0%, rgb(0 127 255) 16%, #ffffff 42%);",
                                    },
                                  }}
                                  variant="buffer"
                                  value={
                                    (100 *
                                      scraping_progress["scrapped_count"]) /
                                    scraping_progress["total_count"]
                                  }
                                  valueBuffer={
                                    (100 *
                                      scraping_progress["scrap_processed"]) /
                                    scraping_progress["total_count"]
                                  }
                                />

                                <Typography
                                  style={{
                                    position: "absolute",
                                    color: "#00f",
                                    top: -22,
                                    left: `${
                                      (100 *
                                        scraping_progress["scrap_processed"]) /
                                      scraping_progress["total_count"] /
                                      2
                                    }%`,
                                    transform: "translateX(-25%)",
                                  }}
                                >
                                  {scraping_progress["total_count"] == 0
                                    ? `0%`
                                    : `${Math.floor(
                                        (100 *
                                          scraping_progress[
                                            "scrap_processed"
                                          ]) /
                                          scraping_progress["total_count"]
                                      )}%`}
                                </Typography>

                                <Typography
                                  style={{
                                    position: "absolute",
                                    color: "#a27608",
                                    top: -22,
                                    right: `${
                                      scraping_progress["total_count"] == 0
                                        ? "0%"
                                        : (
                                            100 -
                                            Math.floor(
                                              (100 *
                                                scraping_progress[
                                                  "scrap_processed"
                                                ]) /
                                                scraping_progress["total_count"]
                                            )
                                          ).toFixed(0) / 2
                                    }%`,
                                    transform: "translateX(+25%)",
                                  }}
                                >
                                  {scraping_progress["total_count"] == 0
                                    ? `0%`
                                    : `${
                                        100 -
                                        Math.floor(
                                          (100 *
                                            scraping_progress[
                                              "scrap_processed"
                                            ]) /
                                            scraping_progress["total_count"]
                                        )
                                      }%`}
                                </Typography>

                                <Typography
                                  style={{
                                    position: "absolute",
                                    color: "#039f03",
                                    top: 0,
                                    left: `${
                                      (100 *
                                        scraping_progress["scrapped_count"]) /
                                        scraping_progress["total_count"] /
                                        2 <
                                      5
                                        ? (100 *
                                            scraping_progress[
                                              "scrapped_count"
                                            ]) /
                                            scraping_progress["total_count"] /
                                            2 -
                                          1.5
                                        : (100 *
                                            scraping_progress[
                                              "scrapped_count"
                                            ]) /
                                          scraping_progress["total_count"] /
                                          2
                                    }%`,
                                    transform: "translateX(-50%)",
                                  }}
                                >
                                  {`${Math.floor(
                                    (100 *
                                      scraping_progress["scrapped_count"]) /
                                      scraping_progress["total_count"]
                                  ).toFixed(0)}%`}
                                </Typography>

                                <Typography
                                  style={{
                                    position: "absolute",
                                    color: "#f00",
                                    top: 0,
                                    left: `${
                                      ((100 *
                                        scraping_progress["scrapped_count"]) /
                                        scraping_progress["total_count"] +
                                        (100 *
                                          scraping_progress[
                                            "not_scrapped_count"
                                          ]) /
                                          scraping_progress["total_count"]) /
                                        2 <
                                      5
                                        ? (100 *
                                            scraping_progress[
                                              "scrapped_count"
                                            ]) /
                                            scraping_progress["total_count"] +
                                          (100 *
                                            scraping_progress[
                                              "not_scrapped_count"
                                            ]) /
                                            scraping_progress["total_count"] /
                                            2 +
                                          2.5
                                        : (100 *
                                            scraping_progress[
                                              "scrapped_count"
                                            ]) /
                                            scraping_progress["total_count"] +
                                          (100 *
                                            scraping_progress[
                                              "not_scrapped_count"
                                            ]) /
                                            scraping_progress["total_count"] /
                                            2
                                    }%`,
                                    transform: "translateX(-50%)",
                                  }}
                                >
                                  {scraping_progress["total_count"] == 0
                                    ? `0%`
                                    : `${Math.floor(
                                        (100 *
                                          scraping_progress[
                                            "not_scrapped_count"
                                          ]) /
                                          scraping_progress["total_count"]
                                      ).toFixed(0)}%`}
                                </Typography>
                              </div>
                              <div className="scraping_progress_bar_footer">
                                {scraping_progress["total_count"] === 0
                                  ? "0%"
                                  : (
                                      100 -
                                      Math.floor(
                                        (100 *
                                          scraping_progress[
                                            "scrap_processed"
                                          ]) /
                                          scraping_progress["total_count"]
                                      )
                                    ).toFixed(0) <= 0
                                  ? "Scraped"
                                  : "Scraping ..........."}
                              </div>
                            </div>
                          )}

                          <TableWithPagination
                            data={search_data}
                            file_name={"quick_link_insert_finder_data"}
                            fields={[
                              {
                                type: "action",
                                field: "delete",
                                headerName: "Delete",
                                width: 65,
                                renderCell: (params) => {
                                  return (
                                    <>
                                      {/* <div>&nbsp;</div> */}
                                      <Tooltip title="Delete">
                                        <IconButton
                                          onClick={() => {
                                            handleDelete(
                                              params.search_result_id
                                            );
                                          }}
                                        >
                                          <MdOutlineDeleteOutline className="table_action_icon_link_exchange_row" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  );
                                },
                              },
                             
                             
                              {
                                type: "field",
                                field: "link_to",
                                search_type: "text_search",
                                headerName: "Link To",
                                minWidth: 150,
                                width: 200,
                                maxWidth: 1000,
                              },
                              {
                                type: "action",
                                field: "link_to_data",
                                headerName: "Link To Scraped ?",
                                renderCell: (params) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        width: "100%",
                                        gap: "30px",
                                       alignItems: "center"
                                      }}
                                    >
                                      <div
                                        onClick={() => {
                                          editScrapedData(
                                            params.search_result_id,
                                            "link_to",
                                            "link_to_data"
                                          );
                                        }}
                                      >
                                        <BsHammer />
                                      </div>
                                      {params.link_to_data == "Not Analyzed" ? (
                                        <div style={{ float: "left" }}>
                                          Not Analyzed
                                        </div>
                                      ) : params.link_to_data ? (
                                        <div style={{ margin: "auto" }}>
                                          Scraped
                                        </div>
                                      ) : (
                                        <div style={{ margin: "auto" }}>
                                          Not Scraped
                                        </div>
                                      )}
                                    </div>
                                  );
                                },
                                width: 185,
                              },
                              //    { field: 'scraped', headerName: 'Is Scraped ?', width: 100, valueGetter: (params) => params.value ? 'Scraped' : 'Not Scraped'  },
                              {
                                type: "field",
                                field: "emphasizedKeywords",
                                headerName: "Keywords",
                                search_type: "text_search",
                                minWidth: 50,
                                width: 150,
                                maxWidth: 1000,
                              },
                              {
                                type: "field",
                                field: "link_from",
                                headerName: "Link From",
                                search_type: "text_search",
                                minWidth: 150,
                                width: 220,
                                maxWidth: 1000,
                              },
                              {
                                type: "action",
                                field: "link_from_data",
                                headerName: "Link From Scraped ?",
                                renderCell: (params) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        width: "100%",
                                        gap: "30px",
                                       alignItems: "center"
                                      }}
                                    >
                                      <div
                                        onClick={() => {
                                          editScrapedData(
                                            params.search_result_id,
                                            "link_from",
                                            "link_from_data"
                                          );
                                        }}
                                      >
                                        <BsHammer />
                                      </div>
                                      {params.link_from_data ==
                                      "Not Analyzed" ? (
                                        <div style={{ margin: "auto" }}>
                                          Not Analyzed
                                        </div>
                                      ) : params.link_from_data ? (
                                        <div style={{ margin: "auto" }}>
                                          Scraped
                                        </div>
                                      ) : (
                                        <div style={{ margin: "auto" }}>
                                          Not Scraped
                                        </div>
                                      )}
                                    </div>
                                  );
                                },
                                width: 165,
                              },
                              {
                                type: "field",
                                field: "title",
                                headerName: "Title",
                                search_type: "text_search",
                                minWidth: 100,
                                width: 200,
                                maxWidth: 500,
                              },
                              {
                                type: "field",
                                field: "classification",
                                search_type: "text_search",
                                headerName: "Page Type",
                                minWidth: 100,
                                width: 200,
                                maxWidth: 500,
                              },
                              {
                                type: "field",
                                field: "similarity_score",
                                headerName: "Similarity Score",
                                search_type: "number_search",
                                minWidth: 100,
                                width: 200,
                                maxWidth: 500,
                              },
                              {
                                type: "field",
                                field: "author_name",
                                headerName: "author_name",
                                search_type: "text_search",
                                minWidth: 100,
                                width: 200,
                                maxWidth: 500,
                              },
                              {
                                type: "field",
                                field: "contact_number",
                                headerName: "contact_number",
                                search_type: "text_search",
                                minWidth: 100,
                                width: 200,
                                maxWidth: 500,
                              },
                              {
                                type: "field",
                                field: "email_id",
                                headerName: "email_id",
                                search_type: "text_search",
                                minWidth: 100,
                                width: 200,
                                maxWidth: 500,
                              },
                              {
                                type: "field",
                                field: "linkedin_link",
                                search_type: "text_search",
                                headerName: "Linkedin",
                                minWidth: 100,
                                width: 200,
                                maxWidth: 500,
                              },
                              {
                                type: "field",
                                field: "twitter_link",
                                search_type: "text_search",
                                headerName: "Twitter",
                                minWidth: 100,
                                width: 200,
                                maxWidth: 500,
                              },
                              {
                                type: "id",
                                field: "search_result_id",
                                headerName: "ID",
                                minWidth: 1,
                                width: 1,
                                maxWidth: 90,
                              },

                              /*
                              {
                                type:"action",
                                field: 'similarity_score',
                                headerName: 'Similarity Score',
                                minWidth: 100,
                                width:200,
                                maxWidth:500,
                                renderCell: (params) => {
                                  return (
                                    params.similarity_score ? <div style={{margin: "auto"}}>{(params.similarity_score * 100).toFixed(2)}%</div>:<div style={{margin: "auto"}}>Not Analyzed</div>
                                  )
                                },
                              },
                              */
                            ]}
                            bulk_actions={
                              progress_classify == 0 || progress_classify == 100
                                ? [
                                    {
                                      action: "delete_multiple",
                                      renderCell: (params) => {
                                        return (
                                          <Tooltip title="Bulk Delete">
                                            <IconButton
                                              onClick={() => {
                                                handleDeleteMultiple(params);
                                              }}
                                            >
                                              <DeleteSweepIcon className="table_action_icon_link_exchange" />
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      },
                                    },
                                    {
                                      action: "scrape",
                                      renderCell: (params) => {
                                        return (
                                          <Tooltip title="scrape">
                                            <IconButton
                                              onClick={() => {
                                                validate_scrape_urls(
                                                  params,
                                                  "Run Scrapper"
                                                );
                                              }}
                                            >
                                              <GiFlatHammer className="table_action_icon_link_exchange" />
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      },
                                    },
                                    {
                                      action: "score",
                                      renderCell: (params) => {
                                        return (
                                          <Tooltip title="Calculate Similarity Score">
                                            <IconButton
                                              // onClick={() => { handleCheckSimilarityScore(params) }}
                                              onClick={() => {
                                                validate_similarity_of_urls(
                                                  params,
                                                  "Calculate Similarity Score"
                                                );
                                              }}
                                            >
                                              <BsSpeedometer className="table_action_icon_link_exchange" />
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      },
                                    },
                                    {
                                      action: "classify",
                                      renderCell: (params) => {
                                        return (
                                          <Tooltip title="Classify">
                                            <IconButton
                                              onClick={() => {
                                                validate_classify_urls(
                                                  params,
                                                  "Classify URL"
                                                );
                                              }}
                                              // onClick={() => {
                                              //   handleClassifyMultiple(params);
                                              // }}
                                            >
                                              <LiaProjectDiagramSolid className="table_action_icon_link_exchange" />
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      },
                                    },
                                    {
                                      action: "Get Author",
                                      renderCell: (params) => {
                                        return (
                                          <Tooltip title="Get Author">
                                            <IconButton
                                              // onClick={() => {
                                              //   handleClassifyMultipleAuthor(
                                              //     params
                                              //   );
                                              // }}
                                              onClick={() => {
                                                validate_get_author(
                                                  params,
                                                  "Get Author"
                                                );
                                              }}
                                            >
                                              <MdMan2 className="table_action_icon_link_exchange" />
                                            </IconButton>
                                          </Tooltip>
                                        );
                                      },
                                    },
                                  ]
                                : [
                                    progress_classify > 0 &&
                                    progress_classify < 100
                                      ? {
                                          action: "progress_bar",
                                          renderCell: (params) => {
                                            return (
                                              <div
                                                style={{
                                                  width: "500px",
                                                  margin: "12px 0px 0px 0px",
                                                }}
                                              >
                                                <LinearProgress
                                                  variant="determinate"
                                                  value={progress_classify}
                                                />
                                              </div>
                                            );
                                          },
                                        }
                                      : {},
                                  ]
                            }
                          />
                          <br />
                          {/*
                  <TextareaAutosize
                    value={search_results}
                    onChange={(event) => set_search_results(event.target.value)}
                    placeholder="Here you would get search results"
                    minRows={2}
                    style={{ width: "100%", overflow: "auto" }}
                  />
                  */}
                        </div>
                        <div
                          id="tab-content-5"
                          className={`tab_content ${
                            activeTab === 5 ? "active" : ""
                          }`}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <Modal
        open={showEditScrapSection}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            padding: "5px 32px 32px 32px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "0px -28px -15px 0px",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          {showEditScrapSection ? (
            <Box className="modalContentCantainer">
              <div className="editable_scrapped_data_url">
                {editableScrappedData[scrapping_field["field"]]}
                <Tooltip title="Open in New Tab">
                  <OpenInNewIcon
                    fontSize="small"
                    className="OpenInNewIcon"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      open_in_new_tab(
                        editableScrappedData[scrapping_field["field"]]
                      );
                    }}
                  />
                </Tooltip>
              </div>
              <FormControl sx={{ width: "150px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Scraper
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  onChange={(event) => {
                    //console.log(event.target.value);
                    set_selected_scrapper_option(event.target.value);
                  }}
                >
                  <MenuItem value="BeautifulSoup">BeautifulSoup</MenuItem>
                  <MenuItem value="Webcache">Webcache</MenuItem>
                  <MenuItem value="Selenium">Selenium</MenuItem>
                </Select>
              </FormControl>
              {selected_scrapper_option == "Selenium"
                ? "Note : using Selenium scrapper takes much time"
                : ""}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "20px 0px 8px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    validate_scrape_individual_urls(
                      editableScrappedData[scrapping_field["field"]],
                      "Run Scrapper"
                    )
                  }
                  style={{ marginRight: "8px" }}
                >
                  Run Scrapper
                </Button>
              </Box>
              <TextareaAutosize
                className="text_area_of_link_exchange"
                value={editableScrappedData[scrapping_field["data_field"]]}
                onChange={changeEditScrappedData}
                placeholder="Edit your scrapped text"
                minRows={5}
                style={{ width: "100%", borderRadius: "5px" }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "20px 0px 8px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => saveEditableScrappedData()}
                  style={{ marginRight: "8px" }}
                >
                  Save Scrap Text
                </Button>
              </Box>
            </Box>
          ) : (
            <div></div>
          )}
        </Box>
      </Modal>

      <Modal
        open={showAuthorInfoModal}
        onClose={handleAuthorInfoClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box id="model_box">
          <div className="form-group">
            <h2 id="modal-title">Author Information</h2>
            <p id="modal-description">{author_info}</p>
          </div>

          <div className="form-group">
            <Button onClick={handleAuthorInfoClose}>Close</Button>
          </div>
        </Box>
      </Modal>

      {/* Process Queue Search Modal */}

      <Modal
        open={show_should_we_process_queue_modal}
        onClose={handle_should_we_process_queue_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_process_queue_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={process_queue_update}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/* resume Process Queue Modal */}
      <Modal
        open={show_should_we_resume_process_queue_modal}
        onClose={handle_should_we_resume_process_queue_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_resume_process_queue_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={resume_process_queue}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
      {/* Sherper Search Modal */}

      <Modal
        open={show_should_we_run_search_queries_serper_modal}
        onClose={handle_should_we_run_search_queries_serper_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handle_should_we_run_search_queries_serper_close}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={run_search_queries_serper_update}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/* Apify Search Modal */}
      <Modal
        open={show_should_we_run_search_queries_apify_modal}
        onClose={handle_should_we_run_search_queries_apify_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handle_should_we_run_search_queries_apify_close}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={run_search_queries_apify_update}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/* Scrape URLs Modal */}
      <Modal
        open={show_should_we_scrape_urls_modal}
        onClose={handle_should_we_scrape_urls_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_scrape_urls_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processScrapeAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/* Scrape individual URL */}
      <Modal
        open={show_should_we_scrape_individual_urls_modal}
        onClose={handle_should_we_scrape_individual_urls_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_scrape_individual_urls_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processScrapeIndividualUrlAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
      {/* Classify Modal */}

      <Modal
        open={show_should_we_classify_urls_modal}
        onClose={handle_should_we_classify_urls_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_classify_urls_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processClassificatonAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/* similiarity Score Check Modal */}
      <Modal
        open={show_should_we_calculate_similarity_score_modal}
        onClose={handle_should_we_calculate_similarity_score_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handle_should_we_calculate_similarity_score_close}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processSimilarityScoreAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/*  Author Modal  */}

      <Modal
        open={show_should_we_get_author_modal}
        onClose={handle_should_we_get_author_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_get_author_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processGetAuthorAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={notificationMessage}
      />
    </>
  );
};

export default LinkExchangeCampaignPage;
