// CampaignCard.js
import React from "react";
import "./CampaignCard.css";

const CampaignCard = ({ title, description, buttonTitle, onClick }) => {
  return (
    <div className="campaign_card">
      <div className="campaign_card_title">{title}</div>
      <div className="campaign_card_description">
        {description.map((item, index) => (
          <div key={index} className="campaign_card_item">
            <div className="check_mark">✔️</div>
            <div className="campaign_card_item_text">{item}</div>
            
          </div>
        ))}
      </div>
      <div>
        
        <button className="campaign_card_button" onClick={onClick}>
          {buttonTitle}
        </button>
      </div>
    </div>
  );
};

export default CampaignCard;
