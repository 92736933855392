import React, { useState, useEffect, useRef } from "react";
import "./ExploreBlogWebsitePage.css";
import { useParams, Link as RouterLink } from "react-router-dom";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";
import {
  Breadcrumbs,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  TextareaAutosize,
  Button,
  IconButton,
  Box,
  Modal,
  Typography,
  CircularProgress,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import {
  CHECK_CREDIT_CONDITION,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_LINKS_FROM_PAGE,
  SAVE_BLOG_URLS_DATA,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  getFullApiUrl,
} from "../../utils/apiPath";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExploreBlogWebsitePage = ({ toggleTheme }) => {
  const { campaignId } = useParams();
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getCampaignDetails();
  }, []);
  const [
    find_all_pages_of_this_website_started,
    set_find_all_pages_of_this_website_started,
  ] = useState(false);
  const [
    show_should_we_process_queue_modal,
    set_show_should_we_process_queue_modal,
  ] = useState(false);
  const [
    show_should_we_resume_process_queue_modal,
    set_show_should_we_resume_process_queue_modal,
  ] = useState(false);
  const find_all_pages_of_this_website_started_flag = useRef(false);
  const [exchanger_website_url, set_exchanger_website_url] = useState("");
  const [exchanger_website_pages, set_exchanger_website_pages] = useState([]);
  const [url_queue_progress_count, set_url_queue_progress_count] = useState({
    visited_urls_count: 0,
    found_urls_count: 0,
  });
  const visited_urls = useRef(new Set());
  const found_urls = useRef(new Set());
  const url_queue = useRef([]);
  const validate_process_queue = async (button) => {

    const deduction_point = await getCreditPointToDeductByButton(button);
    const credits_to_be_deducted = parseFloat((1 * parseFloat(deduction_point)).toFixed(3));

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
    }

    // If all conditions passed, open the modal
    set_show_should_we_process_queue_modal(true);
  };

  const process_queue_update = async () => {
    await find_all_pages_of_this_website("Find all pages of this Website");
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    //  }
    // setBulkProcessInProgress(true);
  };
  const stop_find_all_pages_of_this_website_started = () => {
    set_find_all_pages_of_this_website_started(false);
    find_all_pages_of_this_website_started_flag.current = false;
  };

  const validate_resume_process_queue = async () => {
    const deduction_point = await getCreditPointToDeductByButton(
      "Find all pages of this Website"
    );
    const credits_to_be_deducted = parseFloat((1 * parseFloat(deduction_point)).toFixed(3));

    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
    }

    set_show_should_we_resume_process_queue_modal(true);
  };
  const resume_process_queue = async () => {
    set_show_should_we_resume_process_queue_modal(false);
    set_find_all_pages_of_this_website_started(true);
    find_all_pages_of_this_website_started_flag.current = true;
    process_queue();
  };
  const find_all_pages_of_this_website = async () => {
    if (!exchanger_website_url) return;
    set_find_all_pages_of_this_website_started(true);
    find_all_pages_of_this_website_started_flag.current = true;
    set_exchanger_website_pages([]);
    visited_urls.current = new Set();
    found_urls.current = new Set();
    url_queue.current = [exchanger_website_url];

    process_queue();
  };

  const process_queue = async () => {
    set_show_should_we_process_queue_modal(false);

    console.log("found_urls", found_urls);
    console.log("url_queue", url_queue);
    if (!exchanger_website_url) {
      stop_find_all_pages_of_this_website_started();
      setErrorMessage("Give Website URLs Before Proceed.");
      return;
    }

    setDeductionPoints(1);
    await fetchpointsDetails(1);

    if (totalCreditAfterDeduction < 0 || creditWeekLimitAfterDeduction < 0) {
      stop_find_all_pages_of_this_website_started();
      return;
    }

    if (
      url_queue.current.length === 0 ||
      !find_all_pages_of_this_website_started_flag.current
    ) {
      return;
    }

    const url_to_check = url_queue.current.shift();

    if (!visited_urls.current.has(url_to_check)) {
      visited_urls.current.add(url_to_check);
      set_url_queue_progress_count({
        visited_urls_count: visited_urls.current.size,
        found_urls_count: found_urls.current.size,
      });

      const response = await fetch(getFullApiUrl(GET_LINKS_FROM_PAGE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request_data: {
            base_url: exchanger_website_url,
            url_to_check: url_to_check,
            campaign_id: campaignId,
            user_id: localStorage.getItem("user_id"),
            button_name: "Find all pages of this Website",
          },
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const new_links = responseData.data;

        new_links.forEach((newLink) => {
          if (!found_urls.current.has(newLink)) {
            found_urls.current.add(newLink);
            set_exchanger_website_pages((prevPages) => [...prevPages, newLink]);
            url_queue.current.push(newLink);
            set_url_queue_progress_count({
              visited_urls_count: visited_urls.current.size,
              found_urls_count: found_urls.current.size,
            });
          }
        });

        const newTotalUrls = found_urls.current.size;

        // Calculate the number of times updateCreditPoints should be called
        const callsToUpdateCreditPoints =
          Math.floor(newTotalUrls / 100) - totalCreditsDeducted;

        setTotalCreditsDeducted((prevTotalCreditsDeducted) => {
          if (
            prevTotalCreditsDeducted <
            totalCreditsDeducted + callsToUpdateCreditPoints
          ) {
            updateCreditPoints(1); // Assuming updateCreditPoints returns a Promise
          }
          return totalCreditsDeducted + callsToUpdateCreditPoints;
        });

        process_queue();
      } else {
        process_queue();
      }
    } else {
      process_queue();
    }
  };
  const handle_should_we_process_queue_close = () => {
    set_show_should_we_process_queue_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };
  const handle_should_we_resume_process_queue_close = () => {
    set_show_should_we_resume_process_queue_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");
  const [totalCreditsDeducted, setTotalCreditsDeducted] = useState(1);

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );
      console.log("button");

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );
      fetchpointsDetails(0);

      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const convertUrlsToObjects = (urls) => {
    console.log("urls", urls);
    return urls.map((url, index) => ({
      id: index + 1,
      url_id: index + 1,
      url: url,
      scraped: 0,
      scrapedData: "",
    }));
  };

  const saveRows = (data) => {
    fetch(getFullApiUrl(SAVE_BLOG_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, blog_urls_data: data },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.log("Data not saved");
      });
  };
  const saveBlogData = () => {
    const blogData = convertUrlsToObjects(exchanger_website_pages);
    saveRows(blogData);
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection campaign_bodysection">
          <FloatingMenuBar campaignId={campaignId} />
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/solutions/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.campaign_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                All-In-One
              </Link>
            </div>
            <div className="breadcrumb_text">
              Explore a Blogger Website List
            </div>
          </Breadcrumbs>
          <div className="explore_blog_page_container">
            <div className="explore_blog_page_heading">
              <div className="explore_blog_page_heading_text">
              Explore all blog links of a blogger
              </div>
            </div>
            <div className="explore_blog_page_details">
              <TextField
                value={exchanger_website_url}
                onChange={(event) =>
                  set_exchanger_website_url(event.target.value)
                }
                placeholder="Enter Website you want to Explore"
                minRows={5}
                style={{ width: "100%" }}
              />

              {find_all_pages_of_this_website_started == false ? (
                <>
                  <div className="button_container">
                    <Button
                      sx={{
                        marginRight: "25px",
                      }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() =>
                        validate_process_queue("Find all pages of this Website")
                      }
                    >
                      Find all pages of this Website
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => validate_resume_process_queue()}
                    >
                      Resume Finding
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="credits_info">
                    <h3>Credits Information</h3>
                    <p>Total Credit Points: {totalCreditPoints}</p>
                    <p>Weekly Credit Points Limit: {creditPointsLimit}</p>
                    <p>Total Credits Deducted: {totalCreditsDeducted}</p>
                  </div>
                  <div className="button_container">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() =>
                        stop_find_all_pages_of_this_website_started()
                      }
                    >
                      Stop Finding
                    </Button>
                  </div>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={
                        (url_queue_progress_count.visited_urls_count /
                          url_queue_progress_count.found_urls_count) *
                        100
                      }
                      style={{
                        width: "70px",
                        height: "70px",
                        marginBottom: "1%",
                      }}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                      >
                        {url_queue_progress_count.visited_urls_count} /{" "}
                        {url_queue_progress_count.found_urls_count}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </div>

            <TextareaAutosize
              className="text_area_of_link_exchange"
              value={exchanger_website_pages.join("\n")} // Convert the array to a comma-separated string
              onChange={(event) =>
                set_exchanger_website_pages(event.target.value.split("\n"))
              } // Split the string into an array
              placeholder="URLs you want to Explore"
              minRows={5}
              style={{ width: "100%", overflow: "auto" }}
              maxRows={15}
            />

            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={saveBlogData}
              style={{
                margin:"1% 0"
              }}
            >
              Save Blog Data
            </Button>
          </div>

          <Modal
            open={show_should_we_process_queue_modal}
            onClose={handle_should_we_process_queue_close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="LinkExchange_model_box">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton onClick={handle_should_we_process_queue_close}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="LinkExchange_modal_header">Current Credits </div>
              <div className="LinkExchange_modal_text_heading">
                Before proceeding with this action, please review the following:
              </div>
              <div className="LinkExchange_modal_text">
                Available Credits :{" "}
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForTotalCredits ? "red" : "inherit",
                    }}
                  >
                    {totalCreditPoints}
                  </div>
                  {/* {totalCreditPoints} */}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForCreditWeekLimits
                        ? "red"
                        : "inherit",
                    }}
                  >
                    {creditPointsLimit}
                  </div>
                  {/* {creditPointsLimit} */}
                </div>
              </div>
              <div className="LinkExchange_modal_text_deduct">
                Performing this action will deduct{" "}
                <div className="LinkExchange_modal_deduct_number">
                  {" "}
                  {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
                </div>{" "}
                from your balance.
              </div>
              {(errorMessage ||
                errorMassageForTotalCredits ||
                errorMassageForCreditWeekLimits) && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",

                    fontWeight: "bolder",
                    fontFamily: "monospace",
                    fontSize: "larger",
                  }}
                >
                  <div>{errorMassageForTotalCredits}</div>
                  <div>{errorMassageForCreditWeekLimits}</div>
                  <div>{errorMessage}</div>
                </div>
              )}

              {!(
                errorMessage ||
                errorMassageForTotalCredits ||
                errorMassageForCreditWeekLimits
              ) && (
                <>
                  <div className="LinkExchange_form_group">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={process_queue_update}
                      color="primary"
                    >
                      Confirm Action
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </Modal>
          <Modal
            open={show_should_we_resume_process_queue_modal}
            onClose={handle_should_we_resume_process_queue_close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="LinkExchange_model_box">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  onClick={handle_should_we_resume_process_queue_close}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="LinkExchange_modal_header">Current Credits </div>
              <div className="LinkExchange_modal_text_heading">
                Before proceeding with this action, please review the following:
              </div>
              <div className="LinkExchange_modal_text">
                Available Credits :{" "}
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForTotalCredits ? "red" : "inherit",
                    }}
                  >
                    {totalCreditPoints}
                  </div>
                  {/* {totalCreditPoints} */}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForCreditWeekLimits
                        ? "red"
                        : "inherit",
                    }}
                  >
                    {creditPointsLimit}
                  </div>
                  {/* {creditPointsLimit} */}
                </div>
              </div>
              <div className="LinkExchange_modal_text_deduct">
                Performing this action will deduct{" "}
                <div className="LinkExchange_modal_deduct_number">
                  {" "}
                  {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
                </div>{" "}
                from your balance.
              </div>
              {(errorMessage ||
                errorMassageForTotalCredits ||
                errorMassageForCreditWeekLimits) && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",

                    fontWeight: "bolder",
                    fontFamily: "monospace",
                    fontSize: "larger",
                  }}
                >
                  <div>{errorMassageForTotalCredits}</div>
                  <div>{errorMassageForCreditWeekLimits}</div>
                  <div>{errorMessage}</div>
                </div>
              )}

              {!(
                errorMessage ||
                errorMassageForTotalCredits ||
                errorMassageForCreditWeekLimits
              ) && (
                <>
                  <div className="LinkExchange_form_group">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={resume_process_queue}
                      color="primary"
                    >
                      Confirm Action
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ExploreBlogWebsitePage;
