import React, { useState, useEffect } from "react";
import {
  Typography,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
import {
  CHECK_USER_CAMPAIGN_ACCESS,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_RECENT_IN_CHARGE_OF_CAMPAIGN,
  getFullApiUrl,
} from "../../utils/apiPath";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import Header from "../common/Header/Header";
import { FaRegCircleQuestion } from "react-icons/fa6";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";
import CustomButton from "../components/CustomButton/CustomButton";
import ChooseOppurtunityPage from "./ChooseOppurtunityPage/ChooseOppurtunityPage";

const ScreenshotToolPage = ({ toggleTheme }) => {
  const { campaignId } = useParams();

  const navigate = useNavigate();
  const goto = (page) => {
    navigate(page); // Navigate to the specified page
  };
  useEffect(() => {
    getCampaignDetails();
    getRectInChangeOfCampaignList();
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const [get_recent_in_charge_data, set_get_recent_in_charge_data] = useState(
    []
  );

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getRectInChangeOfCampaignList = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_RECENT_IN_CHARGE_OF_CAMPAIGN)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      set_get_recent_in_charge_data(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const [domainOpportunities, setDomainOpportunities] = React.useState("all");
  const [alternativeLinks, setAlternativeLinks] = React.useState("unavailable");
  const [targetKeyword, setTargetKeyword] = React.useState("yes");
  const [pageTypes, setPageTypes] = React.useState({});

  const [activeTab, setActiveTab] = useState(0);


  const handlePageTypeChange = (event) => {
    setPageTypes({ ...pageTypes, [event.target.name]: event.target.checked });
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [similarityScore, setSimilarityScore] = React.useState([50, 90]);
  const [activeIndex, setActiveIndex] = React.useState(1); // Set default active button

  const buttons = [
    { range: [33, 70], label: 'Relevant' },
    { range: [50, 90], label: 'Similar' },
    { range: [70, 100], label: 'Same' },
    { range: [0, 100], label: 'All' }
  ];

  // Handle slider change and update activeIndex based on the slider value range
  const handleSliderChange = (event, newValue) => {
    setSimilarityScore(newValue);
    const newActiveIndex = buttons.findIndex(
      (button) => newValue[0] >= button.range[0] && newValue[1] <= button.range[1]
    );
    if (newActiveIndex !== -1) {
      setActiveIndex(newActiveIndex);
    }
  };

  // Handle button click to update slider value and set the activeIndex
  const handleButtonClick = (index) => {
    setActiveIndex(index);
    setSimilarityScore(buttons[index].range);
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                Details of Team  ( {campaignData.team_name} )
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                Workspaces of Team ( {campaignData.team_name} )
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                Details of Workspace ({campaignData.workspace_name})
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                Campaigns of Workspace  ({campaignData.workspace_name} )
              </Link>
            </div>
            <div className="breadcrumb_text"> Details of Campaign ({campaignData.campaign_name})</div>
            <div className="breadcrumb_text">Choose Opportunity</div>

          </Breadcrumbs>
          <FloatingMenuBar />


          <div className="find_link_page_contaainer">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="tabs"
            >
              <Tab label="Finalize on Existing Data" />
              <Tab label="Finalize Uploaded Data" />
            </Tabs>

            {activeTab === 0 && (
              <div className="tab_content_container">
                <ChooseOppurtunityPage />
              </div>

            )}

            {activeTab === 1 && (
              <div className="tab_content_container">

                <Typography variant="h5" mb={2}>
                  Finalize Uploaded Data
                </Typography>
                {/* Content for the second tab will be added here later */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenshotToolPage;
