import React, { useState, useEffect } from "react";
import "./BlackListPage.css";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";
import {
  Breadcrumbs,
  Link,
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
  Snackbar,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import {
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_POINTS_DETAILS,
  CHECK_CREDIT_CONDITION,
  SAVE_BLOG_URLS_DATA,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_INDIVIDUAL_CAMPAIGN_DATA,
  getFullApiUrl,
  SAVE_BLACK_LIST_DATA_LIST_OF_WORKSPACE,
  SAVE_BLACK_LIST_DATA_LIST_OF_CAMPAIGN,
} from "../../utils/apiPath";
import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import BlackListComponent from "./BlackListComponent";

const BlackListPage = ({ toggleTheme }) => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    getCampaignDetails();
  }, [campaignId]);
  const [serper_search_domain, set_serper_search_domain] = useState("search");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [keywords, set_keywords] = useState("");
  const [active_bloggers_urls, set_active_bloggers_urls] = useState("");

  const [search_queries, set_search_queries] = useState("");
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [search_results, set_search_results] = useState([]);
  const [blog_urls_list, set_blog_urls_list] = useState([]);

  const [black_list_data, set_black_list_data] = useState([]);

  const [saving, set_saving] = useState(false);

  const [
    show_should_we_run_search_queries_serper_modal,
    set_show_should_we_run_search_queries_serper_modal,
  ] = useState(false);

  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );
      console.log("button");

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );
      fetchpointsDetails();
      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle_should_we_run_search_queries_serper_close = () => {
    set_show_should_we_run_search_queries_serper_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };
  const goto = (page) => {
    if (page == "check_active_bloggers") {
      //window.location.href = '/campaigns';
      //navigate(`/check_active_bloggers`);
      navigate(`/campaign/${campaignId}/check_active_bloggers`);
    }
  };

  const create_search_queries = () => {
    const activeBloggerUrls = active_bloggers_urls
      .split("\n")
      .filter((url) => url.trim() !== "");
    const clientKeywords = keywords
      .split("\n")
      .filter((keyword) => keyword.trim() !== "");
    if (activeBloggerUrls.length === 0 || clientKeywords.length === 0) {
      set_search_queries(
        "Please enter active blogger URLs and client keywords."
      );
      return;
    }
    let generatedQueries = "";
    activeBloggerUrls.forEach((url) => {
      clientKeywords.forEach((keyword) => {
        generatedQueries += `site:${url} ${keyword}\n`;
      });
    });
    if (generatedQueries.length > 0) {
      generatedQueries = generatedQueries.slice(0, -1);
    }
    set_search_queries(generatedQueries);
  };

  const [search_running, set_search_running] = useState(false);
  const [progress, set_Progress] = useState(0);
  const [no_of_pages, set_no_of_pages] = useState(1);
  const [no_of_results, set_no_of_results] = useState(10);
  const [no_of_queries_to_search, set_no_of_queries_to_search] = useState();
  const run_search_queries_serper = async () => {
    set_show_should_we_run_search_queries_serper_modal(false);
    set_search_results("");
    set_blog_urls_list([]);
    set_search_running(true);
    set_Progress(0);
    let search_query_list = search_queries.split("\n");
    const batchSize = 90; // Adjust batch size as needed
    // Filter out completely empty lines
    let filtered_search_query_list = search_query_list.filter(
      (query) => query.trim() !== ""
    );

    const totalQueries = filtered_search_query_list.length;

    for (
      let startIndex = 0;
      startIndex < totalQueries;
      startIndex += batchSize
    ) {
      const endIndex = Math.min(startIndex + batchSize, totalQueries);
      const batchQueries = search_query_list.slice(startIndex, endIndex);

      await searchBatch(batchQueries);
      //console.log("endIndex - "+endIndex);
      //console.log("totalQueries - "+totalQueries);
      //console.log("percentage - "+(100*endIndex/totalQueries));
      set_Progress((100 * endIndex) / totalQueries);
    }

    set_search_running(false);
  };

  const searchBatch = async (batchQueries, maxRetries = 3) => {
    let retryCount = 0;

    while (retryCount < maxRetries) {
      try {
        const requestBody = batchQueries.map((query) => ({
          q: query,
          autocorrect: false,
          page: no_of_pages,
          num: no_of_results,
        }));

        var myHeaders = new Headers();
        myHeaders.append(
          "X-API-KEY",
          "31fdf7ff042b58b13eb23a446d3df4be924ce6aa"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(requestBody);
        //,  "autocorrect": false
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          "https://google.serper.dev/" + serper_search_domain,
          requestOptions
        );
        //const response = await fetch("http://localhost:8002/get_serper_data", requestOptions);

        const results = await response.json();

        for (const result of results) {
          //var urlArray = []
          if (serper_search_domain == "news") {
            //urlArray = result.news.map(item => item.link);
            var result_key = "news";
          } else {
            //urlArray = result.organic.map(item => item.link);
            var result_key = "organic";
          }
          const urlArray = result[result_key].map((item) => item.link);
          //console.log(urlArray);

          //set_search_results(prevResults => prevResults.concat(urlArray.join('\n') + '\n'));
          //set_search_results(prevResults => [...new Set([...prevResults.split('\n'), ...urlArray])].join('\n'));
          await set_search_results((prevResults) =>
            [...new Set([...prevResults.split("\n"), ...urlArray])]
              .join("\n")
              .replace(/^\n+|\n+$/g, "")
          );

          set_blog_urls_list((prevUrls) => [
            ...new Set(prevUrls.concat(urlArray)),
          ]);
          //set_blog_urls_list(prevUrls => prevUrls.concat(urlArray));
        }

        // If the request is successful, break out of the retry loop
        break;
      } catch (error) {
        console.log("Error:", error);
        retryCount++;
        console.log(`Retrying... (Attempt ${retryCount}/${maxRetries})`);
      }
    }
  };

  const save_black_list_urls_in_campaign = () => {
    //const existingUrlsData = rows.map((row) => row);
    const updatedUrls = search_results
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url !== "");
    //updatedUrls = [...new Set(updatedUrls)]
    var row_objects_array = [];
    var row_object = {};
    for (let i = 0; i < updatedUrls.length; i++) {
      row_object = {
        id: i + 1,
        url_id: i + 1,
        url: updatedUrls[i],
        scraped: 0,
        scrapedData: "",
      };
      row_objects_array.push(row_object);
    }
    console.log(row_objects_array);
    set_black_list_data(row_objects_array);
    saveRowsForcampaign(row_objects_array);
    // saveProjectData(row_objects_array);
  };
  const save_black_list_urls_in_workspace = () => {
    //const existingUrlsData = rows.map((row) => row);
    const updatedUrls = search_results
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url !== "");
    //updatedUrls = [...new Set(updatedUrls)]
    var row_objects_array = [];
    var row_object = {};
    for (let i = 0; i < updatedUrls.length; i++) {
      row_object = {
        id: i + 1,
        url_id: i + 1,
        url: updatedUrls[i],
        scraped: 0,
        scrapedData: "",
      };
      row_objects_array.push(row_object);
    }
    console.log(row_objects_array);
    set_black_list_data(row_objects_array);
    saveRowsForWorkspace(row_objects_array);
    // saveProjectData(row_objects_array);
  };

  const saveRowsForcampaign = (data) => {
    set_saving(true);
    fetch(getFullApiUrl(SAVE_BLACK_LIST_DATA_LIST_OF_CAMPAIGN), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, black_list_data: data },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        set_saving(false);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };
  const saveRowsForWorkspace = (data) => {
    set_saving(true);
    fetch(getFullApiUrl(SAVE_BLACK_LIST_DATA_LIST_OF_WORKSPACE), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          workspace_id: campaignData.workspace_id,
          black_list_data: data,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        set_saving(false);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  const validate_run_search_queries_serper = async (button) => {
    const deduction_point = await getCreditPointToDeductByButton(button);

    let search_query_list = search_queries.split("\n");

    // Filter out completely empty lines
    let filtered_search_query_list = search_query_list.filter(
      (query) => query.trim() !== ""
    );

    const totalQueries = filtered_search_query_list.length;

    const totalRows = totalQueries;

    const credits_to_be_deducted = totalRows * parseInt(deduction_point);

    // If all conditions passed, open the modal

    // Set deduction points in the state
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no blog URLs available for scraping at the moment."
      );
    }
    set_show_should_we_run_search_queries_serper_modal(true);
  };

  const run_search_queries_serper_update = async () => {
    await run_search_queries_serper();
    //  if (!bulkProcessInProgress) {
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    //  }
    // setBulkProcessInProgress(true);
  };

  const [openSaveForBlackListModal, setOpenSaveForBlackListModal] =
    useState(false);

  const handleOpenSaveForBlackListModal = () =>
    setOpenSaveForBlackListModal(true);
  const handleCloseSaveForBlackListModal = () =>
    setOpenSaveForBlackListModal(false);

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection campaign_bodysection">
          <FloatingMenuBar campaignId={campaignId} />
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/solutions/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.campaign_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                All-In-One
              </Link>
            </div>
            <div className="breadcrumb_text">Black List</div>
          </Breadcrumbs>
          {/* <div className="active_bloggers_Title">Black List 
          </div> */}


          <BlackListComponent
            workspace_id={campaignData.workspace_id}
            campaign_id={campaignId}
          />
          




          <Modal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={openSaveForBlackListModal}
            onClose={handleCloseSaveForBlackListModal}
          >
            <Box id="LinkExchange_model_box">
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  right: "15px",
                  top: "13px",
                }}
              >
                <IconButton onClick={handleCloseSaveForBlackListModal}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="LinkExchange_modal_text_heading">
                Save Blacklist URLs
              </div>
              <div className="black_list_save_modal_button_container">
                <Button
                  className="black_list_save_modal_button"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    save_black_list_urls_in_workspace();
                    handleCloseSaveForBlackListModal();
                  }}
                >
                  Save in Workspace Level
                </Button>
                <Button
                  className="black_list_save_modal_button"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    save_black_list_urls_in_campaign();
                    handleCloseSaveForBlackListModal();
                  }}
                >
                  Save in Campaign Level
                </Button>
                <Button
                  className="black_list_save_modal_button"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    save_black_list_urls_in_campaign();
                    save_black_list_urls_in_workspace();
                    handleCloseSaveForBlackListModal();
                  }}
                >
                  Save in Both
                </Button>
              </div>
            </Box>
          </Modal>
          <Modal
            open={show_should_we_run_search_queries_serper_modal}
            onClose={handle_should_we_run_search_queries_serper_close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="LinkExchange_model_box">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  onClick={handle_should_we_run_search_queries_serper_close}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="LinkExchange_modal_header">Current Credits</div>
              <div className="LinkExchange_modal_text_heading">
                Before proceeding with this action, please review the following:
              </div>
              <div className="LinkExchange_modal_text">
                Available Credits:{" "}
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForTotalCredits ? "red" : "inherit",
                    }}
                  >
                    {totalCreditPoints}
                  </div>
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left:{" "}
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForCreditWeekLimits
                        ? "red"
                        : "inherit",
                    }}
                  >
                    {creditPointsLimit}
                  </div>
                </div>
              </div>
              {errorMessage !==
                "There are no blog URLs available for scraping at the moment." && (
                  <div className="LinkExchange_modal_text_deduct">
                    Performing this action will deduct{" "}
                    <div className="LinkExchange_modal_deduct_number">
                      {" "}
                      {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
                    </div>{" "}
                    from your balance.
                  </div>
                )}
              {(errorMessage ||
                errorMassageForTotalCredits ||
                errorMassageForCreditWeekLimits) && (
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",

                      fontWeight: "bolder",
                      fontFamily: "monospace",
                      fontSize: "larger",
                    }}
                  >
                    <div>{errorMassageForTotalCredits}</div>
                    <div>{errorMassageForCreditWeekLimits}</div>
                    <div>{errorMessage}</div>
                  </div>
                )}

              {!(
                errorMessage ||
                errorMassageForTotalCredits ||
                errorMassageForCreditWeekLimits
              ) && (
                  <>
                    <div className="LinkExchange_modal_header">
                      After Deduction
                    </div>
                    <div className="LinkExchange_modal_text">
                      Balance Credits :
                      <div className="LinkExchange_modal_number">
                        {totalCreditAfterDeduction}
                      </div>
                    </div>
                    <div className="LinkExchange_modal_text">
                      Weekly Credits Limit Left :{" "}
                      <div className="LinkExchange_modal_number">
                        {creditWeekLimitAfterDeduction}
                      </div>
                    </div>

                    <div className="LinkExchange_form_group">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={run_search_queries_serper_update}
                        color="primary"
                      >
                        Confirm Action
                      </Button>
                    </div>
                  </>
                )}
            </Box>
          </Modal>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={notificationMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default BlackListPage;
