import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ChooseOppurtunityPage.css";
import CampaignCard from "../../components/CampaignCard/CampaignCard";

const ChooseOppurtunityPage = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const default_form_data = {
    name: "Start Advanced Campaign",
    formData: {
      ignorePageType: "no",
      ignoreSimilarityScore: "no",
      similarityScore: [30, 75],
      domainOpportunities: "1",
      alternativeLinks: "4",
      target_keyword_matching_logic: "preferable",
      pageTypeHandling: "include",
      pageTypes: {
        "Blog Post": true,
        "Advice Listicle Blog Page": true,
        "Recommendation Listicle Blog Page": true,
        "Blog Roll Page": false,
        "Blog Category Page": false,
        "Blog Tag Page": false,
        "Blog Author Page": false,
        "Resource Page": true,
        "Comparison Blog Page": true,
        "Glossary Blog Page": true,
        "Wiki Page": false,
        "Homepage": false,
        "About Us Page": false,
        "Contact Page": false,
        "Privacy Policy Page": false,
        "Terms of Service Page": false,
        "Ecommerce Product Page": false,
        "Ecommerce Category Page": false,
        "Landing Page": false,
        "Service Page": false,
        "Pricing Page": false,
        "Case Study Page": false,
        "Testimonials/Reviews Page": false,
        "Portfolio Page": false,
        "News Page": false,
        "Press Release Page": false,
        "Events Page": false,
        "Forum Page": false,
        "Job Listing Page": false,
        "Directory Listing Page": false,
        "Social Media Post": false,
        "Profile Page": false,
        "Video Page": false,
        "PDF Page": false
      }
    }
  };
  
  
  
  const opportunities = [
    {
      name: "Link Insertions",
      formData: {
        ignorePageType: "no",
        ignoreSimilarityScore: "no",
        alternativeLinks: "4",
        domainOpportunities: "1",
        pageTypeHandling: "include",
        pageTypes: {
          "Blog Post": true,
          "Advice Listicle Blog Page": true,
          "Recommendation Listicle Blog Page": true,
          "Blog Roll Page": false,
          "Blog Category Page": false,
          "Blog Tag Page": false,
          "Blog Author Page": false,
          "Resource Page": true,
          "Comparison Blog Page": true,
          "Glossary Blog Page": true,
          "Wiki Page": false,
          "Homepage": false,
          "About Us Page": false,
          "Contact Page": false,
          "Privacy Policy Page": false,
          "Terms of Service Page": false,
          "Ecommerce Product Page": false,
          "Ecommerce Category Page": false,
          "Landing Page": false,
          "Service Page": false,
          "Pricing Page": false,
          "Case Study Page": false,
          "Testimonials/Reviews Page": false,
          "Portfolio Page": false,
          "News Page": false,
          "Press Release Page": false,
          "Events Page": false,
          "Forum Page": false,
          "Job Listing Page": false,
          "Directory Listing Page": false,
          "Social Media Post": false,
          "Profile Page": false,
          "Video Page": false,
          "PDF Page": false
        },
        similarityScore: [30, 75],
        target_keyword_matching_logic: "preferable"
      }
    },
    {
      name: "Exact Keyword Link Insertions",
      formData: {
        ignorePageType: "no",
        ignoreSimilarityScore: "no",
        similarityScore: [30, 75],
        domainOpportunities: "1",
        alternativeLinks: "4",
        target_keyword_matching_logic: "exact_match",
        pageTypeHandling: "include",
        pageTypes: {
          "Blog Post": true,
          "Advice Listicle Blog Page": true,
          "Recommendation Listicle Blog Page": true,
          "Blog Roll Page": false,
          "Blog Category Page": false,
          "Blog Tag Page": false,
          "Blog Author Page": false,
          "Resource Page": true,
          "Comparison Blog Page": true,
          "Glossary Blog Page": true,
          "Wiki Page": false,
          "Homepage": false,
          "About Us Page": false,
          "Contact Page": false,
          "Privacy Policy Page": false,
          "Terms of Service Page": false,
          "Ecommerce Product Page": false,
          "Ecommerce Category Page": false,
          "Landing Page": false,
          "Service Page": false,
          "Pricing Page": false,
          "Case Study Page": false,
          "Testimonials/Reviews Page": false,
          "Portfolio Page": false,
          "News Page": false,
          "Press Release Page": false,
          "Events Page": false,
          "Forum Page": false,
          "Job Listing Page": false,
          "Directory Listing Page": false,
          "Social Media Post": false,
          "Profile Page": false,
          "Video Page": false,
          "PDF Page": false
        }
      }
    },
    {
      name: "Guest Posts",
      formData: {
        ignorePageType: "no",
        ignoreSimilarityScore: "no",
        similarityScore: [30, 100],
        domainOpportunities: "1",
        alternativeLinks: "4",
        target_keyword_matching_logic: "preferable",
        pageTypeHandling: "include",
        pageTypes: {
          "Blog Post": true,
          "Advice Listicle Blog Page": true,
          "Recommendation Listicle Blog Page": true,
          "Blog Roll Page": true,
          "Blog Category Page": true,
          "Blog Tag Page": true,
          "Blog Author Page": true,
          "Resource Page": true,
          "Comparison Blog Page": true,
          "Glossary Blog Page": true,
          "Wiki Page": false,
          "Homepage": false,
          "About Us Page": false,
          "Contact Page": false,
          "Privacy Policy Page": false,
          "Terms of Service Page": false,
          "Ecommerce Product Page": false,
          "Ecommerce Category Page": false,
          "Landing Page": false,
          "Service Page": false,
          "Pricing Page": false,
          "Case Study Page": false,
          "Testimonials/Reviews Page": false,
          "Portfolio Page": false,
          "News Page": false,
          "Press Release Page": false,
          "Events Page": false,
          "Forum Page": false,
          "Job Listing Page": false,
          "Directory Listing Page": false,
          "Social Media Post": false,
          "Profile Page": false,
          "Video Page": false,
          "PDF Page": false
        }
      }
    },
    {
      name: "Listicles",
      formData: {
        ignorePageType: "no",
        ignoreSimilarityScore: "no",
        similarityScore: [0, 100],
        domainOpportunities: "1",
        alternativeLinks: "4",
        target_keyword_matching_logic: "preferable",
        pageTypeHandling: "include",
        pageTypes: {
          "Blog Post": false,
          "Advice Listicle Blog Page": true,
          "Recommendation Listicle Blog Page": true,
          "Blog Roll Page": false,
          "Blog Category Page": false,
          "Blog Tag Page": false,
          "Blog Author Page": false,
          "Resource Page": true,
          "Comparison Blog Page": false,
          "Glossary Blog Page": false,
          "Wiki Page": false,
          "Homepage": false,
          "About Us Page": false,
          "Contact Page": false,
          "Privacy Policy Page": false,
          "Terms of Service Page": false,
          "Ecommerce Product Page": false,
          "Ecommerce Category Page": false,
          "Landing Page": false,
          "Service Page": false,
          "Pricing Page": false,
          "Case Study Page": false,
          "Testimonials/Reviews Page": false,
          "Portfolio Page": false,
          "News Page": false,
          "Press Release Page": false,
          "Events Page": false,
          "Forum Page": false,
          "Job Listing Page": false,
          "Directory Listing Page": false,
          "Social Media Post": false,
          "Profile Page": false,
          "Video Page": false,
          "PDF Page": false
        }
      }
    },
    {
      name: "Find Similar Content",
      formData: {
        ignorePageType: "no",
        ignoreSimilarityScore: "no",
        similarityScore: [50, 100],
        domainOpportunities: "1",
        alternativeLinks: "4",
        target_keyword_matching_logic: "preferable",
        pageTypeHandling: "include",
        pageTypes: {
          "Blog Post": true,
          "Advice Listicle Blog Page": true,
          "Recommendation Listicle Blog Page": true,
          "Blog Roll Page": true,
          "Blog Category Page": true,
          "Blog Tag Page": true,
          "Blog Author Page": true,
          "Resource Page": true,
          "Comparison Blog Page": true,
          "Glossary Blog Page": true,
          "Wiki Page": true,
          "Homepage": true,
          "About Us Page": true,
          "Contact Page": true,
          "Privacy Policy Page": true,
          "Terms of Service Page": true,
          "Ecommerce Product Page": true,
          "Ecommerce Category Page": true,
          "Landing Page": true,
          "Service Page": true,
          "Pricing Page": true,
          "Case Study Page": true,
          "Testimonials/Reviews Page": true,
          "Portfolio Page": true,
          "News Page": true,
          "Press Release Page": true,
          "Events Page": true,
          "Forum Page": true,
          "Job Listing Page": true,
          "Directory Listing Page": true,
          "Social Media Post": true,
          "Profile Page": true,
          "Video Page": true,
          "PDF Page": true
        }
      }
    }
  ];


  

  const handleProceedClick = (formData) => {
    // Store the form data in localStorage before navigating
    localStorage.setItem("selectedOpportunityData", JSON.stringify(formData));
    navigate(`/my_campaign/${campaignId}/find_link_insert`);
  };

  const handlePlusClick = () => {
    
    localStorage.setItem("selectedOpportunityData", JSON.stringify(default_form_data));

    // Navigate to the next page without storing data
    navigate(`/my_campaign/${campaignId}/find_link_insert`);
  };

  return (
    <div className="choose_opurtuinity_container">
      <div className="header_container">
        <div className="header">Choose Opportunity Template</div>
        <div className="sub_header">
          Select to automatically find top link building opportunities for your campaign
        </div>
      </div>
      <div className="campaign-list">
        <div className="start-advanced-campaign" onClick={handlePlusClick}>
          <div className="start-icon">+</div>
          <p>Start advanced campaign</p>
        </div>
        {opportunities.map((opportunity, index) => (
          <CampaignCard
            key={index}
            title={opportunity.name}
            description={[
              "Matches your target URL with relevant link inserts.",
              "Filters out non-blog posts for better relevance.",
              "Shows only the best opportunity per domain.",
            ]}
            buttonTitle="PROCEED"
            onClick={() => handleProceedClick(opportunity.formData)}
          />
        ))}
      </div>
    </div>
  );
};

export default ChooseOppurtunityPage;
