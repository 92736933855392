import React, { useState, useEffect, useRef } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  TextField,
  TextareaAutosize,
  Box,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Skeleton,
} from "@mui/material";
import {} from "@mui/x-data-grid";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import ReorderIcon from "@mui/icons-material/Reorder";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DownloadIcon from "@mui/icons-material/Download";

import { saveAs } from "file-saver";
import "./IdentifyParagraphsPage.css";
import LeftBar from "./../common/LeftBar";
import Header from "../common/Header/Header";
import {
  BASE_API_URL_MAIN,
  CALCULATE_SIMILARITY_SCORE_FOR_ALL,
  DOWNLOAD_ANALYZED_BLOG_DATA,
  GET_BLOG_URLS_DATA,
  GET_CLIENT_URLS_DATA,
  GET_IDENTIFIED_PARAGRAPHS_DATA,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_PREVIOUSLY_IDENTIFIED_PARAGRAPH,
  IDENTIFY_PARAGRAPH,
  SAVE_IDENTIFIED_PARAGRAPHS_DATA,
  getFullApiUrl,
} from "../../utils/apiPath";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";

const IdentifyParagraphsPage = ({ toggleTheme }) => {
  //const apipath = "http://localhost:8001"
  //const apipath = ""
  //const textRef = useRef(null);
  const { campaignId } = useParams();

  const [client_urls_data, set_client_urls_data] = useState([]);
  const [selected_client_url, set_selected_client_url] = useState({});

  const [blog_urls_data, set_blog_urls_data] = useState([]);
  const [analyzed_blog_urls_data, set_analyzed_blog_urls_data] = useState([]);

  const [identified_paragraphs_data, set_identified_paragraphs_data] = useState(
    []
  );
  //const [client_urls, set_client_urls] = useState([]);
  const [initial_data_loaded_client, set_initial_data_loaded_client] =
    useState(0);
  const [initial_data_loaded_blog, set_initial_data_loaded_blog] = useState(0);
  const [
    initial_data_loaded_identified_paragraphs,
    set_initial_data_loaded_identified_paragraphs,
  ] = useState(0);

  const [filter_count_of_keyword_match, set_filter_count_of_keyword_match] =
    useState(0);

  const [open, setOpen] = React.useState(false);
  /*const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };*/
  //********************************************************** State variable - End

  //********************************************************** On page load - Start
  useEffect(() => {
    set_initial_data_loaded_client(0);
    fetch(getFullApiUrl(GET_CLIENT_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const updatedRows = data["data"].map((row, index) => ({
          ...row,
          id: index + 1,
          url_id: index + 1,
        }));
        set_client_urls_data(updatedRows);
        set_initial_data_loaded_client(1);
      })
      .catch((error) => {
        console.error("Error:", error);
        set_initial_data_loaded_client(-1);
      });

    set_initial_data_loaded_blog(0);
    fetch(getFullApiUrl(GET_BLOG_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const updatedRows = data["data"].map((row, index) => ({
          ...row,
          id: index + 1,
          url_id: index + 1,
        }));
        set_blog_urls_data(updatedRows);
        set_initial_data_loaded_blog(1);
      })
      .catch((error) => {
        console.error("Error:", error);
        set_initial_data_loaded_blog(-1);
      });

    set_initial_data_loaded_identified_paragraphs(0);
    fetch(getFullApiUrl(GET_IDENTIFIED_PARAGRAPHS_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //const updatedRows = data["data"].map((row, index) => ({ ...row, id: index + 1, url_id: index + 1 }));
        set_identified_paragraphs_data(data);
        set_initial_data_loaded_identified_paragraphs(1);
      })
      .catch((error) => {
        console.error("Error:", error);
        set_initial_data_loaded_identified_paragraphs(-1);
      });
  }, []);
  //********************************************************** On page load - End

  //********************************************************** Identify_Paragraphs - Start

  useEffect(() => {
    getCampaignDetails();
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const get_GPT_prompt_to_identify_paragraphs = (
    keywords,
    scraped_blog_data
  ) => {
    var prompts = "\n";
    prompts =
      prompts +
      "Identify paragraphs from given article that are closely related to one of the given keywords";
    prompts = prompts + "\n\n";
    prompts = prompts + "Below are the keywords";
    prompts = prompts + "\n\n";
    prompts = prompts + keywords;
    prompts = prompts + "\n\n";
    prompts = prompts + "Below is the article";
    prompts = prompts + "\n\n";
    prompts = prompts + scraped_blog_data;
    prompts = prompts + "\n\n";
    prompts =
      prompts + "Output the exact paragraph along with it's matching keywords";
    prompts = prompts + "\n";
    prompts = prompts + "";

    console.log(prompts);
    return prompts;
  };

  const handle_client_url_Option_Select = (selected_client_url_data) => {
    if (
      selected_client_url_data.hasOwnProperty("min_count_expected_for_keywords")
    ) {
    } else {
      selected_client_url_data["min_count_expected_for_keywords"] =
        selected_client_url_data["keywords"]
          .split("\n")
          .map((keyword) => ({ [keyword]: 0 }));
    }
    console.log(
      "*************************************************************************"
    );
    console.log(selected_client_url_data);
    set_selected_client_url(selected_client_url_data);

    // call api - get_previously_identified_Paragraph(client_url, blog_url)
    // populate the ui list
  };

  const generate_Identify_Paragraphs_Section = () => {
    set_filter_count_of_keyword_match(0);
    setOpen(true);
    set_analyzed_blog_urls_data([]);
    fetch(getFullApiUrl(GET_PREVIOUSLY_IDENTIFIED_PARAGRAPH), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
          client_url: selected_client_url.url,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("got data");
        let analyzed_blog_urls_data_temp = [];
        for (let i = 0; i < data["data"].length; i++) {
          let row_temp = { ...data["data"][i] };
          row_temp["url_id"] = i + 1;

          //row_temp["scraped_client_data"] = ""
          //row_temp["keywords"] = row_temp.hasOwnProperty("matched_paragraph")? row_temp["matched_paragraph"] : "";

          //console.log(" *********************** row_temp")
          //console.log(row_temp)
          //console.log(blog_urls_data)
          var index = blog_urls_data.findIndex(
            (obj) => obj.url === row_temp["blog_url"]
          );
          //console.log(blog_urls_data[index])
          var scrapedData = blog_urls_data[index]["scrapedData"];
          
          console.log(selected_client_url["keywords"]);
          var keywordList = selected_client_url["keywords"].split("\n");
          console.log(keywordList);
          
          var keywords_count = {};
          for (let i = 0; i < keywordList.length; i++) {
            var keyword = keywordList[i];
            var count =
              scrapedData.toLowerCase().split(keyword.toLowerCase()).length - 1;
            keywords_count[keyword] = count;
          }
          var total_keywords_count = 0;
          Object.values(keywords_count).forEach((count) => {
            total_keywords_count += count;
          });
          row_temp["total_keywords_count"] = total_keywords_count;
          row_temp["keywords_count"] = keywords_count;

          row_temp["matched_paragraph"] = row_temp.hasOwnProperty(
            "matched_paragraph"
          )
            ? row_temp["matched_paragraph"]
            : "";
          row_temp["prompt_to_identify_paragraph"] = "";

          console.log("data processed");
          analyzed_blog_urls_data_temp.push(row_temp);
        }
        set_analyzed_blog_urls_data(analyzed_blog_urls_data_temp);
        setOpen(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        //set_initial_data_loaded(0);
        setOpen(false);
      });
  };

  const save_the_work_done_at_Identify_Paragraphs_Section = () => {
    console.log(
      "********************************************************************"
    );
    console.log(
      "********************************************************************"
    );
    console.log(
      "********************************************************************"
    );
    console.log(
      "********************************************************************"
    );

    const selectedKeys = [
      "blog_url",
      "similarity_score",
      "keywords",
      "matched_paragraph",
      "selected",
    ];
    const analyzed_blog_urls_data_to_save = analyzed_blog_urls_data.map((obj) =>
      Object.fromEntries(selectedKeys.map((key) => [key, obj[key]]))
    );
    console.log("analyzed_blog_urls_data_to_save");
    console.log(analyzed_blog_urls_data_to_save);
    var request_data = {
      campaign_id: campaignId,
      client_url: selected_client_url.url,
      analyzed_blog_urls_data: analyzed_blog_urls_data_to_save,
    };

    fetch(getFullApiUrl(SAVE_IDENTIFIED_PARAGRAPHS_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: request_data }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sort_the_Identify_Paragraphs_Section = (type) => {
    console.log(
      "********************************************************************"
    );
    console.log(
      "********************************************************************"
    );
    console.log(analyzed_blog_urls_data);
    const sorted_objects = analyzed_blog_urls_data.map((row, index) => ({
      ...row,
    }));
    sorted_objects.sort((a, b) => {
      if (type == "descending") {
        return b.similarity_score - a.similarity_score;
      } else if (type == "ascending") {
        return a.similarity_score - b.similarity_score;
      } else {
        return a.url_id - b.url_id;
      }
    });
    set_analyzed_blog_urls_data(sorted_objects);
  };

  const process_single_blog = (url_id) => {
    console.log(selected_client_url["url"]);
    //console.log(analyzed_blog_urls_data);
    const index = analyzed_blog_urls_data.findIndex(
      (obj) => obj.url_id === url_id
    );
    console.log(analyzed_blog_urls_data[index]["blog_url"]);

    fetch(getFullApiUrl(IDENTIFY_PARAGRAPH), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
          client_url: selected_client_url["url"],
          blog_url: analyzed_blog_urls_data[index]["blog_url"],
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data["response"]);

        let analyzed_blog_urls_data_temp = [];
        for (let i = 0; i < analyzed_blog_urls_data.length; i++) {
          let row_temp = { ...analyzed_blog_urls_data[i] };
          if (
            row_temp["blog_url"] == analyzed_blog_urls_data[index]["blog_url"]
          ) {
            row_temp["scraped_client_data"] =
              data["response"]["scraped_client_data"];
            row_temp["scraped_blog_data"] =
              data["response"]["scraped_blog_data"];
            row_temp["keywords"] = data["response"]["keywords"];
            row_temp["matched_paragraph"] =
              data["response"]["matched_paragraph"];
            row_temp["prompt_to_identify_paragraph"] =
              get_GPT_prompt_to_identify_paragraphs(
                data["response"]["keywords"],
                data["response"]["scraped_blog_data"]
              );
            row_temp["similarity_score"] = data["response"]["similarity_score"];
          }
          analyzed_blog_urls_data_temp.push(row_temp);
        }
        set_analyzed_blog_urls_data(analyzed_blog_urls_data_temp);
      })
      .catch((error) => {
        console.error("Error:", error);
        //set_initial_data_loaded(0);
      });
  };

  //********************************************************** Identify_Paragraphs - End

  const select_this_blog = (url_id) => {
    const index = analyzed_blog_urls_data.findIndex(
      (obj) => obj.url_id === url_id
    );
    var analyzed_blog_urls_data_temp = analyzed_blog_urls_data.map(
      (row) => row
    );
    analyzed_blog_urls_data_temp[index]["selected"] = 1;
    set_analyzed_blog_urls_data(analyzed_blog_urls_data_temp);
  };
  const deselect_this_blog = (url_id) => {
    const index = analyzed_blog_urls_data.findIndex(
      (obj) => obj.url_id === url_id
    );
    var analyzed_blog_urls_data_temp = analyzed_blog_urls_data.map(
      (row) => row
    );
    analyzed_blog_urls_data_temp[index]["selected"] = 0;
    set_analyzed_blog_urls_data(analyzed_blog_urls_data_temp);
  };
  const deselect_this_blog_not_working = (url_id) => {
    let analyzed_blog_urls_data_temp = [];
    for (let i = 0; i < analyzed_blog_urls_data.length; i++) {
      let row_temp = { ...analyzed_blog_urls_data[i] };
      row_temp["selected"] = 0;
      analyzed_blog_urls_data_temp.push(row_temp);
    }
    set_analyzed_blog_urls_data(analyzed_blog_urls_data_temp);
  };

  const calculate_similarity_score_for_all = () => {
    fetch(getFullApiUrl(CALCULATE_SIMILARITY_SCORE_FOR_ALL), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
          client_url: selected_client_url.url,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log();
        //const updatedRows = data["data"].map((row, index) => ({ ...row, id: index + 1, url_id: index + 1 }));
        //set_identified_paragraphs_data(data);
        generate_Identify_Paragraphs_Section();
      })
      .catch((error) => {
        console.error("Error:", error);
        //set_initial_data_loaded(0);
      });
  };

  const [keywordFilters, setKeywordFilters] = useState({});

  const filter_by_individual_keyword_count = (keyword, value) => {
    console.log("keyword - " + keyword + " & value - " + value);
    console.log(
      "**************************************************************************************************************************"
    );
    console.log(
      "**************************************************************************************************************************"
    );
    console.log(
      "**************************************************************************************************************************"
    );
    console.log(
      "**************************************************************************************************************************"
    );
    console.log(
      "**************************************************************************************************************************"
    );
    const tempSelectedClientUrlData = { ...selected_client_url };
    const minCountExpectedForKeywords =
      tempSelectedClientUrlData["min_count_expected_for_keywords"];

    for (let i = 0; i < minCountExpectedForKeywords.length; i++) {
      const keywordObj = minCountExpectedForKeywords[i];
      const keywordName = Object.keys(keywordObj)[0];
      console.log("keywordName - " + keywordName + " & keyword - " + keyword);
      if (keywordName === keyword) {
        console.log(
          "**************************************************************************************************************************"
        );
        console.log(
          "**************************************************************************************************************************"
        );
        console.log(
          "**************************************************************************************************************************"
        );
        console.log(
          "**************************************************************************************************************************"
        );
        console.log(
          "**************************************************************************************************************************"
        );
        keywordObj[keywordName] = value;
        break;
      }
    }
    console.log(tempSelectedClientUrlData);
    set_selected_client_url(tempSelectedClientUrlData);
  };

  const copy_text = (text) => {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };
  const open_in_new_tab = (url) => {
    window.open(url, "_blank");
  };

  const download_analyzed_blog_data = () => {
    console.log(analyzed_blog_urls_data);
    fetch(getFullApiUrl(DOWNLOAD_ANALYZED_BLOG_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: analyzed_blog_urls_data }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, "inspect_matches_individually_data.csv");
        //set_initial_loader_open(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection campaign_bodysection">
          <FloatingMenuBar campaignId={campaignId} />
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/solutions/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.campaign_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                All-In-One
              </Link>
            </div>
            <div className="breadcrumb_text">
            Inspect Matches Individually
            </div>
          </Breadcrumbs>

          <Box sx={{ padding: 5 }}>
            {initial_data_loaded_client === 1 &&
            initial_data_loaded_blog === 1 &&
            initial_data_loaded_identified_paragraphs === 1 ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={
                      analyzed_blog_urls_data.length > 0
                        ? { width: "85%" }
                        : { width: "100%" }
                    }
                  >
                    <Box sx={{ width: "100%" }}>
                      <Autocomplete
                        options={client_urls_data}
                        getOptionLabel={(option) => option.url}
                        onChange={(event, value) =>
                          handle_client_url_Option_Select(value)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Select Client URL" />
                        )}
                      />
                    </Box>

                    {Object.keys(selected_client_url).length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "20px",
                        }}
                      >
                        <Typography variant="h5" fontWeight="bold">
                          Please choose a Client URL to proceed
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                          padding: "30px 0px 0px 0px",
                          marginBottom: "20px",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => generate_Identify_Paragraphs_Section()}
                          style={{ marginRight: "8px", textTransform: "none" }}
                        >
                          Generate Identify Paragraphs Section
                        </Button>
                      </Box>
                    )}
                  </Box>
                  {analyzed_blog_urls_data.length > 0 ? (
                    <Box className="color-sample-container">
                      <div className="color-sample match_very_good">
                        100% - 31%{" "}
                      </div>
                      <div className="color-sample match_good">30% - 21%</div>
                      <div className="color-sample match_somewhat">
                        20% - 16%
                      </div>
                      <div className="color-sample match_bad">15% - 11%</div>
                      <div className="color-sample match_very_bad">
                        10% - 0%
                      </div>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>

                {open == true ? (
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "70%",
                    }}
                  >
                    <CircularProgress />
                    {/* <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="50vh"
                    /> */}
                  </div>
                ) : (
                  <>
                    {analyzed_blog_urls_data.length > 0 ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-between",
                            marginTop: "20px",
                          }}
                        >
                          <div
                            style={{ marginTop: "10px", marginLeft: "10px" }}
                          >
                            <Tooltip title="Download in CSV format">
                              <DownloadIcon
                                className="OpenInNewIcon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  download_analyzed_blog_data();
                                }}
                              />
                            </Tooltip>
                          </div>

                          <div style={{ marginLeft: "auto" }}>
                            <TextField
                              id="standard-number"
                              label="Filter : - No of keyword match"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="standard"
                              inputProps={{
                                min: 0,
                              }}
                              onBlur={(event) =>
                                set_filter_count_of_keyword_match(
                                  event.target.value
                                )
                              }
                              style={{
                                marginRight: "8px",
                                textTransform: "none",
                              }}
                            />
                          </div>

                          <div style={{ marginTop: "5px" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() =>
                                calculate_similarity_score_for_all()
                              }
                              style={{
                                marginRight: "8px",
                                textTransform: "none",
                              }}
                            >
                              Calculate Similarity Score
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() =>
                                save_the_work_done_at_Identify_Paragraphs_Section()
                              }
                              style={{
                                marginRight: "8px",
                                textTransform: "none",
                              }}
                            >
                              Save The Work
                            </Button>
                          </div>

                          <div
                            className="sorting_buttons"
                            style={{ marginTop: "10px", marginRight: "15px" }}
                          >
                            <KeyboardDoubleArrowDownIcon
                              onClick={() =>
                                sort_the_Identify_Paragraphs_Section(
                                  "descending"
                                )
                              }
                            />
                            <ReorderIcon
                              onClick={() =>
                                sort_the_Identify_Paragraphs_Section("")
                              }
                            />
                            <KeyboardDoubleArrowUpIcon
                              onClick={() =>
                                sort_the_Identify_Paragraphs_Section(
                                  "ascending"
                                )
                              }
                            />
                          </div>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          {
                            //JSON.stringify(selected_client_url["min_count_expected_for_keywords"])
                          }

                          <div className="keywordFiltersContainer">
                            {selected_client_url[
                              "min_count_expected_for_keywords"
                            ].map((keywordObj, index) => (
                              <div className="keywordFilter">
                                <div className="keywordFilterName">
                                  {Object.keys(keywordObj)[0]}
                                </div>
                                :
                                <TextField
                                  id="standard-number"
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="standard"
                                  inputProps={{
                                    min: 0,
                                  }}
                                  onBlur={(event) =>
                                    filter_by_individual_keyword_count(
                                      Object.keys(keywordObj)[0],
                                      parseInt(event.target.value)
                                    )
                                  }
                                  className="keywordFilterTextField"
                                />
                              </div>
                            ))}
                          </div>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <Box className="blog-urls-data-container">
                  {analyzed_blog_urls_data.map((row, index) => {
                    //(row.selected === 0 && row.total_keywords_count>=filter_count_of_keyword_match) ?

                    const keywordCountsMatch = selected_client_url[
                      "min_count_expected_for_keywords"
                    ].every((keywordObj) => {
                      const keywordName = Object.keys(keywordObj)[0];
                      const keywordCount = keywordObj[keywordName];
                      return row.keywords_count[keywordName] >= keywordCount;
                    });

                    if (
                      row.selected === 0 &&
                      row.total_keywords_count >=
                        filter_count_of_keyword_match &&
                      keywordCountsMatch
                    ) {
                      return (
                        <Accordion key={row.url_id}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="summaryContainer">
                              <div className="urlId">
                                Blog URL No: {row.url_id}
                              </div>
                              <div className="url">
                                &nbsp;
                                <Tooltip title="Copy the url">
                                  <ContentCopyIcon
                                    fontSize="small"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      copy_text(row.blog_url);
                                    }}
                                  />
                                </Tooltip>
                                &nbsp;
                                {row.blog_url}
                                &nbsp;
                                <Tooltip title="Open in New Tab">
                                  <OpenInNewIcon
                                    fontSize="small"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      open_in_new_tab(row.blog_url);
                                    }}
                                  />
                                </Tooltip>
                                &nbsp;
                              </div>
                              <div className="total_keywords_count">
                                {row.total_keywords_count}
                              </div>
                              {row.similarity_score === -1 ? (
                                <div className="match_not_analyzed">
                                  Not Analyzed
                                </div>
                              ) : row.similarity_score > 30 ? (
                                <div className="match_very_good">
                                  {row.similarity_score}% match
                                </div>
                              ) : row.similarity_score > 20 ? (
                                <div className="match_good">
                                  {row.similarity_score}% match
                                </div>
                              ) : row.similarity_score > 15 ? (
                                <div className="match_somewhat">
                                  {row.similarity_score}% match
                                </div>
                              ) : row.similarity_score > 10 ? (
                                <div className="match_bad">
                                  {row.similarity_score}% match
                                </div>
                              ) : (
                                <div className="match_very_bad">
                                  {row.similarity_score}% match
                                </div>
                              )}
                            </div>
                          </AccordionSummary>

                          <AccordionDetails>
                            <div className="keywordsContainer">
                              {Object.keys(row.keywords_count).map(
                                (keyword, index) => (
                                  <div key={index} className="keywordContainer">
                                    <Typography
                                      variant="body1"
                                      component="span"
                                      className="keyword"
                                    >
                                      {keyword}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      component="span"
                                      className="count"
                                    >
                                      ({row.keywords_count[keyword]})
                                    </Typography>
                                  </div>
                                )
                              )}
                            </div>

                            {/*
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: "35px"
          }}>
            <Button variant="contained" color="success" style={{ textTransform: 'none' }}
              onClick={() => process_single_blog(row.url_id)}>
              Process The Blog
            </Button>
          </div>
            <div className="sectionContainer">
              <div className="leftSection">
                <Typography variant="subtitle1" className="sectionLabel">
                  GPT4 Prompt To Identify Paragraphs
                </Typography>
                <TextField
                  label="Prompt"
                  InputLabelProps={{
                      shrink: row.prompt_to_identify_paragraph !== ""
                    }}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={row.prompt_to_identify_paragraph}
                  onChange={(event) => {
                        const updatedData = [...analyzed_blog_urls_data];
                        updatedData[index].prompt_to_identify_paragraph = event.target.value;
                        set_analyzed_blog_urls_data(updatedData);
                  }}
                />
              </div>
              <div className="rightSection">
                <Typography variant="subtitle1" className="sectionLabel">
                  Identified paragraphs using GPT3
                </Typography>
                <TextField
                  label="Identified Paragraphs"
                  InputLabelProps={{
                      shrink: row.matched_paragraph !== ""
                    }}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={row.matched_paragraph}
                  onChange={(event) => {
                        const updatedData = [...analyzed_blog_urls_data];
                        updatedData[index].matched_paragraph = event.target.value;
                        set_analyzed_blog_urls_data(updatedData);
                  }}
                />

              </div>
            </div>
            <div className="buttonContainer">

            <Button variant="disabled" style={{ textTransform: 'none', color:"#000" }}>
                Identified Paragraph Looks Good -
            </Button>
        </div>
*/}

                            {row.selected == 0 ? (
                              <Button
                                variant="contained"
                                color="success"
                                style={{ textTransform: "none" }}
                                onClick={() => select_this_blog(row.url_id)}
                              >
                                Select This Blog
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="success"
                                style={{ textTransform: "none" }}
                                onClick={() => deselect_this_blog(row.url_id)}
                              >
                                Deselect This Blog
                              </Button>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>

                {analyzed_blog_urls_data.some(
                  (row) =>
                    row.selected === 1 &&
                    row.total_keywords_count >= filter_count_of_keyword_match
                ) && (
                  <Box
                    className="blog-urls-selected"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    <Typography variant="h5" fontWeight="bold">
                      You Selected Below Blog Pages
                    </Typography>
                  </Box>
                )}
                <Box className="blog-urls-data-container">
                  {analyzed_blog_urls_data.map((row, index) =>
                    row.selected === 1 &&
                    row.total_keywords_count >=
                      filter_count_of_keyword_match ? (
                      <Accordion key={row.url_id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <div className="summaryContainer">
                            <div className="urlId">
                              Blog URL No: {row.url_id}
                            </div>
                            <div className="url">
                              &nbsp;
                              <Tooltip title="Copy the url">
                                <ContentCopyIcon
                                  fontSize="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    copy_text(row.blog_url);
                                  }}
                                />
                              </Tooltip>
                              &nbsp;
                              {row.blog_url}
                              &nbsp;
                              <Tooltip title="Open in New Tab">
                                <OpenInNewIcon
                                  fontSize="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    open_in_new_tab(row.blog_url);
                                  }}
                                />
                              </Tooltip>
                              &nbsp;
                            </div>
                            <div className="total_keywords_count">
                              {row.total_keywords_count}
                            </div>
                            {row.similarity_score === -1 ? (
                              <div className="match_not_analyzed">
                                Not Analyzed
                              </div>
                            ) : row.similarity_score > 30 ? (
                              <div className="match_very_good">
                                {row.similarity_score}% match
                              </div>
                            ) : row.similarity_score > 20 ? (
                              <div className="match_good">
                                {row.similarity_score}% match
                              </div>
                            ) : row.similarity_score > 15 ? (
                              <div className="match_somewhat">
                                {row.similarity_score}% match
                              </div>
                            ) : row.similarity_score > 10 ? (
                              <div className="match_bad">
                                {row.similarity_score}% match
                              </div>
                            ) : (
                              <div className="match_very_bad">
                                {row.similarity_score}% match
                              </div>
                            )}
                          </div>
                        </AccordionSummary>

                        <AccordionDetails>
                          <div className="keywordsContainer">
                            {Object.keys(row.keywords_count).map(
                              (keyword, index) => (
                                <div key={index} className="keywordContainer">
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="keyword"
                                  >
                                    {keyword}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    className="count"
                                  >
                                    ({row.keywords_count[keyword]})
                                  </Typography>
                                </div>
                              )
                            )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "35px",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="success"
                              style={{ textTransform: "none" }}
                              onClick={() => process_single_blog(row.url_id)}
                            >
                              Process The Blog
                            </Button>
                          </div>

                          <div className="sectionContainer">
                            <div className="leftSection">
                              <Typography
                                variant="subtitle1"
                                className="sectionLabel"
                              >
                                GPT4 Prompt To Identify Paragraphs
                              </Typography>
                              <TextField
                                label="Prompt"
                                InputLabelProps={{
                                  shrink:
                                    row.prompt_to_identify_paragraph !== "",
                                }}
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                value={row.prompt_to_identify_paragraph}
                                onChange={(event) => {
                                  const updatedData = [
                                    ...analyzed_blog_urls_data,
                                  ];
                                  updatedData[
                                    index
                                  ].prompt_to_identify_paragraph =
                                    event.target.value;
                                  set_analyzed_blog_urls_data(updatedData);
                                }}
                              />
                            </div>
                            <div className="rightSection">
                              <Typography
                                variant="subtitle1"
                                className="sectionLabel"
                              >
                                Identified paragraphs using GPT3
                              </Typography>
                              <TextField
                                label="Identified Paragraphs"
                                InputLabelProps={{
                                  shrink: row.matched_paragraph !== "",
                                }}
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                value={row.matched_paragraph}
                                onChange={(event) => {
                                  const updatedData = [
                                    ...analyzed_blog_urls_data,
                                  ];
                                  updatedData[index].matched_paragraph =
                                    event.target.value;
                                  set_analyzed_blog_urls_data(updatedData);
                                }}
                              />
                            </div>
                          </div>
                          <div className="buttonContainer">
                            <Button
                              variant="disabled"
                              style={{ textTransform: "none", color: "#000" }}
                            >
                              Identified Paragraph Looks Good -
                            </Button>
                            {row.selected == 0 ? (
                              <Button
                                variant="contained"
                                color="success"
                                style={{ textTransform: "none" }}
                                onClick={() => select_this_blog(row.url_id)}
                              >
                                Select This Blog
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="success"
                                style={{ textTransform: "none" }}
                                onClick={() => deselect_this_blog(row.url_id)}
                              >
                                Deselect This Blog
                              </Button>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      ""
                    )
                  )}
                </Box>
              </>
            ) : initial_data_loaded_client === -1 ||
              initial_data_loaded_blog === -1 ||
              initial_data_loaded_identified_paragraphs === -1 ? (
              <>Issue on Initial Data Loading</>
            ) : (
              <>Loading Initial Data........</>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

/*

<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
  <Typography>
    URL ID : {row.url_id}
  </Typography>
  <Typography>
    URL : {row.url}
  </Typography>
  <Typography>
    90%
  </Typography>
</AccordionSummary>

<div className="scraped-data">{row.scrapedData}</div>


What is the idea ?
here we are finding match between keywords and pharagraphs
this matching pharagraph finding is just to check which blog page is relevant
and storing the matching paragraph for manual checking by relationship manager ( our team members )

Additional New IDEA :
lets do a similarity check between the client and blog page and provide the matching score in percentage

<div>Selected Option: {selected_client_url && selected_client_url.scrapedData}</div>

*/
export default IdentifyParagraphsPage;
