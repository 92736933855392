import React, { useState, useEffect } from "react";
import { Button, Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import Header from "../common/Header/Header";
import Robo from "./../common/Robo";
import "./SolutionsPage.css";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import { NavigateNext } from "@mui/icons-material";
import {
  CHECK_USER_CAMPAIGN_ACCESS,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  getFullApiUrl,
} from "../../utils/apiPath";

const SolutionsPage = ({ toggleTheme }) => {
  const { campaignId } = useParams();

  const navigate = useNavigate();
  const goto = (page) => {
    navigate(page); // Navigate to the specified page
  };
  useEffect(() => {
    getCampaignDetails();
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_campaign/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">{campaignData.campaign_name}</div>
          </Breadcrumbs>

          <div className="SolutionsSection">
            <div className="Solution">
              <div className="SolutionHeader">All-In-One</div>
              <div className="SolutionDescription">
                Place your hyperlinks to other websites to boost your online
                visibility. Check your known bloggers' pages. Explore new
                blogger websites. AI-based fitness check of blog page contents.
                AI-based automated Email to pitch to bloggers.
              </div>
              <div className="SolutionButton">
                <Button onClick={() => goto(`/my_campaign/${campaignId}`)}>
                  Continue
                </Button>
              </div>
            </div>
            <div className="Solution">
              <div className="SolutionHeader">Quick link insert finder</div>
              <div className="SolutionDescription">
                Websites swap links for SEO benefits. A link exchange campaign
                involves two websites mutually agreeing to link to each other's
                content, often for the purpose of improving SEO, but it has
                become less popular due to search engine guidelines.
              </div>
              <div className="SolutionButton">
                <Button
                  onClick={() => goto(`/link_exchange_campaign/${campaignId}`)}
                >
                  Continue
                </Button>
              </div>
            </div>

            <div className="Solution">
              <div className="SolutionHeader">Find Contacts</div>
              <div className="SolutionDescription">
                Enter the author's name, and you can find links to their
                biography, personal website, or profiles on other websites.
              </div>
              <div className="SolutionButton">
                <Button onClick={() => goto(`/find_Contacts/${campaignId}`)}>
                  Continue
                </Button>
              </div>
            </div>
          </div>
          <Robo />
        </div>
      </div>
    </div>
  );
};

export default SolutionsPage;
