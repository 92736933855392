import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes, 
  Route,
  Navigate
} from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import LandingPage from "./pages/LandingPage/LandingPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import "./App.css";
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import CantactUsPage from "./pages/CantactUsPage/CantactUsPage";
import useAuth from "./hooks/authentication/useAuth";
import My_Workspace from "./pages/AdminPage/My_Workspace/My_Workspace";
import Workspaces from "./pages/AdminPage/Workspaces/Workspaces";
import Roles from "./pages/AdminPage/Roles/Roles";
import AdminPage from "./pages/AdminPage/AdminPage";
import Users from "./pages/AdminPage/Users/Users";
import All_Delete_data from "./pages/AdminPage/AllDeleteData/All_Delete_data";
import Subscriptions from "./pages/AdminPage/Subscriptions/Subscriptions";
import RegistrationSuccessPage from "./pages/RegisterPage/RegistrationSuccessPage/RegistrationSuccessPage";
import Campaigns from "./pages/AdminPage/Campaigns";
import Credits_Points_Manage from "./pages/AdminPage/Credits_Points_Manage";
import Team_Members from "./pages/AdminPage/Team_Members/Team_Members";
import Teams from "./pages/AdminPage/Teams";
import Team_Workspace from "./pages/AdminPage/Team_Workspace/Team_Workspace";
import MyWorkspaces from "./pages/MyWorkspaces/MyWorkspaces";
import MyWorkspaceCampaigns from "./pages/MyWorkspaceCampaigns/MyWorkspaceCampaigns";
import MyTeams from "./pages/MyTeams/MyTeams";
import MyWorkspaceCampaign from "./pages/MyWorkspaceCampaign/MyWorkspaceCampaign";
import MyTeamWorkspace from "./pages/MyTeamWorkspace/MyTeamWorkspace";
import MyTeamMembers from "./pages/MyTeamMembers/MyTeamMembers";
import My_Account from "./pages/My_Account/My_Account";
import MyTeamWorkspaceCampaign from "./pages/MyTeamWorkspaceCampaign/MyTeamWorkspaceCampaign";
import CampaignListOfUser from "./pages/CampaignListOfUser/CampaignListOfUser";
import MyWorkspaceMembers from "./pages/MyWorkspaceMembers/MyWorkspaceMembers";
import Login_passcode from "./pages/LoginPasscode/Login_passcode";
import MyTeamWorkspaceMember from "./pages/MyTeamWorkspaceMember/MyTeamWorkspaceMember";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import BillingPage from "./pages/BillingPage/BillingPage";
import TicketPage from "./pages/TicketPage/TicketPage";
import ClientURLsPage from "./pages/ClientURLsPage";
import CheckActiveBloggersPage from "./pages/CheckActiveBloggersPage";
import IdentifyParagraphsPage from "./pages/IdentifyParagraphsPage";
import LinkInsertionSuggestionPage from "./pages/LinkInsertionSuggestionPage";
import MyWorkspaceCampaignTesting from "./pages/MyWorkspaceCampaignTesting/MyWorkspaceCampaignTesting";
import CampaignRoutes from "./CampaignRoutes";
import GoogleSignUpPage from "./pages/RegisterPage/GoogleSignUpPage/GoogleSignUpPage";
import SolutionsPage from "./pages/SolutionsPage/SolutionsPage";
import Point_deduction from "./pages/AdminPage/Point_Deduction/Point_deduction";
import LinkExchangeCampaignPage from "./pages/LinkExchangeCampaignPage/LinkExchangeCampaignPage";
import flow_icon from './assets/icons/flow_icon.png'
import flow_icon_dark from './assets/icons/flow_icon_dark.png'
import Find_Contacts from "./pages/Find_Contacts/Find_Contacts";
import TeamDetails from "./pages/TeamDetails/TeamDetails";
import IndividualWorkspaceDetails from "./pages/IndividualWorkspaceDetails/IndividualWorkspaceDetails";
import { NODE_OPTIONS } from "./utils/apiPath";
import BuyCreditsPage from "./pages/BuyCreditsPage/BuyCreditsPage";
import HomePage from "./pages/HomePage/HomePage"; // Make sure to create this component

const App = () => {
  const { loggedIn, login, logout } = useAuth(); // Fetch loggedIn status from useAuth hook
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setIsDarkMode(storedTheme === "dark");
    }
  }, []);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      // Update theme preference in local storage
      localStorage.setItem("theme", newMode ? "dark" : "light");
      return newMode;
    });
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
    },
    palette: {
      mode: isDarkMode ? "dark" : "light",
      text: {
        primary: isDarkMode ? "#d6d6d6" : "#000000",
        table: isDarkMode ? "#c2c2c2" : "#202020",
        secondary : isDarkMode ? '#b0b0b0' : "#202020",
        tertiary :  isDarkMode ? '#212121' : "#4a4a4a",
        second : isDarkMode ? '#a4a4a4' : "#5f5f5f"
      },
      background: {
        default: isDarkMode ? "#24272e" : "#f5f5f5",
        secondary: isDarkMode ? "#1C1E24" : "#FFF",
        temp: isDarkMode ? "#1C1E24" : "#ffffff",
        primary: isDarkMode ? "#1C1E24" : "#f8f8f8",
        body: isDarkMode ? "#010310" : "#f2f2f2",
        prev : isDarkMode ? "#7a7a7a" : "#d1d1d1",
        tirnary : isDarkMode ? "#2a2d35" : "f2f2f2",
        hover : isDarkMode ? "#00000091" :"#00000091" ,
      },
      header: {
        secondary: isDarkMode ? "#000000" : "#ffffffe3",
      },
      search: {
        default: isDarkMode ? "#232323" : "#eaeaea",
        label: isDarkMode ? "#d6d6d6" : "#c9c9c9",
      },
      tableHeader: {
        head: isDarkMode ? "#24272e" : "#f5f5f5",
        temp: isDarkMode ? "#1E1E1E" : "#f5f5f5",
      },
      tableBody: {
        temp: isDarkMode ? "#1C1E24" : "#ffffffe3",
        hover: isDarkMode ? "#313131" : "#f1f1f1",
      },
      tableBorder: {
        border: isDarkMode ? "#373737" : "#b4b4b4",
        border2: isDarkMode ? "#3b3b3b" : "#d8d8d8"
      },
      container: {
        body: isDarkMode ? "#30302F" : "#ffffff",
        admin: isDarkMode ? "#242424" : "#ffffff",
      },
      logo: {
        text: isDarkMode ? "#FFF" : "#414141",
        color: isDarkMode ? "#FFF" : "#000000",
      },
      search_box:{
        first:isDarkMode ? "#000000" : "#ffffff", 
        second: isDarkMode ? "#24272e" : "#f5f5f5",
      },
      react_flow:{
        first:isDarkMode ? "#121417" : "#e3e3e3", 
        second: isDarkMode ? "#24272e" : "#f5f5f5",
        text: isDarkMode ? "#dadada" : "#414141",
        border: isDarkMode ? "#373737" : "#b4b4b4",
      },
      flow_chart:{
        background:isDarkMode ? "#353535" : "#c4c4c4", 
      },
      button:{
        primary_bg:isDarkMode ? "#4b91ca" : "#4b91ca", 
        secondary_bg:isDarkMode ? "#d9534f" : "#cf5f60", 
        select_text : isDarkMode ? "#FFF" :  "#FFF",
      }, 
      hover:{
        primary : isDarkMode ? "#00000091" : "#EAF1FF"
      },
      border:{
        primary : isDarkMode ? "#FFF" : "#4b91ca",
        

      }
    },
  });

  const setCSSVariables = (theme) => {
    const { palette } = theme;

    Object.keys(palette).forEach((key) => {
      const colors = palette[key];
      if (typeof colors === "object") {
        Object.keys(colors).forEach((colorKey) => {
          const variableName = `--${key}-${colorKey}`;
          document.documentElement.style.setProperty(
            variableName,
            colors[colorKey]
          );
        });
      }
    });
  };

  useEffect(() => {
    setCSSVariables(theme);
  }, [theme]);

  // Check if the user has admin privileges based on role name
  const role_name = localStorage.getItem("role_name");
  const isAdmin = role_name === "admin" || role_name === "super_admin";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Public routes */}
          <Route
            path="/"
            element={
              loggedIn ? (
                <HomePage toggleTheme={toggleTheme} />
              ) : (
                <LandingPage toggleTheme={toggleTheme} />
              )
            }
          />
          <Route
            path="/registration-success"
            element={<RegistrationSuccessPage toggleTheme={toggleTheme} />}
          />
          <Route
            path="/contact"
            element={<CantactUsPage toggleTheme={toggleTheme} />}
          />

          {/* if user not logged In */}
          {loggedIn ? (
            <>
              <Route path="/login" element={<Navigate to="/" />} />
              <Route path="/register" element={<Navigate to="/" />} />
              <Route path="/login_passcode" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              {/* Render login and register routes for non-logged-in users */}
              <Route
                path="/login"
                element={<LoginPage toggleTheme={toggleTheme} login={login} />}
              />
              <Route
                path="/register"
                element={<RegisterPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/register_with_google"
                element={<GoogleSignUpPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/login_passcode"
                element={<Login_passcode toggleTheme={toggleTheme} />}
              />
            </>
          )}

          {/* Protected routes */}
          {loggedIn || isAdmin ? (
            <>
              <Route
                path="/my_account"
                element={<My_Account toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_workspaces"
                element={<MyWorkspaces toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_workspace_campaign/:workspaceId"
                element={<MyWorkspaceCampaigns toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_workspace/:workspaceId"
                element={<IndividualWorkspaceDetails toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_workspace_members/:workspace_id"
                element={<MyWorkspaceMembers toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_team_workspace_campaign/:workspaceId"
                element={<MyTeamWorkspaceCampaign toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_team_workspace_member/:workspace_id"
                element={<MyTeamWorkspaceMember toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_teams"
                element={<MyTeams toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_team_workspaces/:teamId"
                element={<MyTeamWorkspace toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_team/:teamId"
                element={<TeamDetails toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_team_members/:teamId"
                element={<MyTeamMembers toggleTheme={toggleTheme} />}
              />
              <Route
                path="/subscription"
                element={<SubscriptionPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/payment"
                element={<PaymentPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/user_campaign"
                element={<CampaignListOfUser toggleTheme={toggleTheme} />}
              />
              <Route
                path="/settings"
                element={<SettingsPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/billing"
                element={<BillingPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/ticket"
                element={<TicketPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_campaign_testing/:campaignId"
                element={
                  <MyWorkspaceCampaignTesting toggleTheme={toggleTheme} />
                }
              />
              <Route
                path="/buy_credits"
                element={<BuyCreditsPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/link_exchange_campaign/:campaignId"
                element={<LinkExchangeCampaignPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/solutions/:campaignId"
                element={<SolutionsPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/my_campaign/:campaignId/*"
                element={<CampaignRoutes toggleTheme={toggleTheme} />}
              />
              <Route
                path="/find_Contacts/:campaignId/*"
                element={<Find_Contacts toggleTheme={toggleTheme} />}
              />
            </>
          ) : null}

          {/* Admin routes */}
          {isAdmin ? (
            <>
              <Route
                path="/my_account"
                element={<My_Account toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/all_delete_data"
                element={<All_Delete_data toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/team_members/:teamId"
                element={<Team_Members toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin"
                element={<AdminPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/workspaces"
                element={<Workspaces toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/users"
                element={<Users toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/my_workspace/:workspaceId"
                element={<My_Workspace toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/team_workspace/:teamId"
                element={<Team_Workspace toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/teams"
                element={<Teams toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/team_members/:teamId"
                element={<Team_Members toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/campaigns"
                element={<Campaigns toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/roles"
                element={<Roles toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/subscriptions"
                element={<Subscriptions toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/teams"
                element={<Teams toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/team_members"
                element={<Team_Members toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/campaigns"
                element={<Campaigns toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/points_deduction"
                element={<Point_deduction toggleTheme={toggleTheme} />}
              />
              <Route
                path="/admin/credit_point_manage"
                element={<Credits_Points_Manage toggleTheme={toggleTheme} />}
              />
            </>
          ) : null}

          {/* Redirect to home if trying to access admin routes without admin privileges */}
          {loggedIn && !isAdmin && (
            <Route path="/admin/*" element={<Navigate to="/" />} />
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
