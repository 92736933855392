import React from "react";
import PropTypes from "prop-types";
import "./CustomButtonForStatus.css";

const CustomButtonForStatus = ({ title, status, onClick }) => {
  // Determine button class based on status
  let buttonClass = "button"; // Default base class

  if (title === "Yes" && status === "Yes(Confirmed)") {
    buttonClass += " yes_confirmed"; // Yes button with Yes(Confirmed) status
  } else if (title === "No" && status === "No(Confirmed)") {
    buttonClass += " no_confirmed"; // No button with No(Confirmed) status
  } else if (title === "Yes" && status === "No(Confirmed)") {
    buttonClass += " proceed_button"; // Yes button when No(Confirmed) status
  } else if (title === "No" && status === "Yes(Confirmed)") {
    buttonClass += " reset_button"; // No button when Yes(Confirmed) status
  }else if (title === "reset_button" && status === "Yes(Auto)") {
    buttonClass += " proceed_button"; // No button when Yes(Confirmed) status
  }  else if (title === "Yes" && status === "Yes(Auto)") {
    buttonClass += " proceed_button"; // No button when Yes(Confirmed) status
  }  
  else {
    buttonClass += title === "Yes" ? " proceed_button" : " reset_button"; // Default fallback
  }
  return (
    <div className="custom_button">
      <button
        className={buttonClass}
        onClick={onClick}
        // disabled={status === "Yes(Confirmed)" || status === "No(Confirmed)"} // Disable button if already confirmed
      >
        {title}
      </button>
    </div>
  );
};

CustomButtonForStatus.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string, // Can be "Yes(Confirmed)", "No(Confirmed)", or undefined
  onClick: PropTypes.func,
};

export default CustomButtonForStatus;
