import React, { useEffect, useRef, useState } from "react";
import "./QuickVarifyScreenshotPage.css";
import {
    CircularProgress,
    Breadcrumbs,
    Link,
    Typography,
    Tooltip,
    Select,
    MenuItem,
    Divider,
    Snackbar,
    FormControl
} from "@mui/material";
import Header from "../../common/Header/Header";
import LeftSectionWithMenu from "../../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import FloatingMenuBar from "../../components/FloatingMenuBar/FloatingMenuBar";
import { NavigateNext } from "@mui/icons-material";
import { useParams, Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import {
    FILTER_USER_UPLOAD_DATA_FOR_SCREENSHOT,
    GET_INDIVIDUAL_CAMPAIGN_DETAIL,
    GET_SCREENSHOT,
    GET_TARGET_AND_PROSPECT_SCREENSHOT_DATA,
    GET_PROXY,
    TAKE_SCREENSHOT_AND_UPDATE_SCREENSHOT_DATA,
    getFullApiUrl,
    GET_FIND_LINK_INSERT_FORM_DATA,
    SAVE_TARGET_AND_PROSPECT_SCREENSHOT_DATA
} from "../../../utils/apiPath";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import CustomButton from "../../components/CustomButton/CustomButton";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import img from '../../../assets/images/AITool&SoftwareDevelopment.jpg'
import img1 from '../../../assets/website_demo/img_1.png'
import img2 from '../../../assets/website_demo/img_2.png'
import img3 from '../../../assets/website_demo/img_3.png'
import KeywordsSearch from "./KeywordsSearch/KeywordsSearch";
import { MdDoNotDisturbAlt } from "react-icons/md";
const QuickVarifyScreenshotPage = ({ toggleTheme  }) => {

    const location = useLocation();
    const { finalizeOppurtunityPageData } = location.state || {};



    const [campaignData, setCampaignData] = useState({
        team_id: "",
        workspace_id: "",
        team_name: "",
        workspace_name: "",
        campaign_name: "",
    });
    const navigate = useNavigate();
    const [isScreenshotGet, setIsScreenshotGet] = useState(false)
    const [screenshotData, setScreenshotData] = useState([]);

    const [selectedItem, setSelectedItem] = useState(
        {
            blog_url: "",
            client_url: "",
            status: ""
        }
    );

    const [iframeSrc, setIframeSrc] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingScreenshot, setLoadingScreenshot] = useState(true);

    const itemsPerPage = 10;
    const { campaignId } = useParams();
    const [selectedProspect, setSelectedProspect] = useState('');
    const [selectedTarget, setSelectedTarget] = useState('');

    const [showKeywordsSearch, setShowKeywordsSearch] = useState(false);
    const [fetched_screenshot, set_fetched_screenshot] = useState()
    const [is_anchor_tag_visible, set_is_anchor_tag_visible] = useState(false)
    const [anchor_tag_url, set_anchor_tag_url] = useState("")

    const [currentPage, setCurrentPage] = useState(1);
    const [groupedData, setGroupedData] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [topMatches, setTopMatches] = useState(null);
    const [currentPageTopMatch, setCurrentPageTopMatch] = useState(null);
    const [uniqueClientUrls, setUniqueClientUrls] = useState([]);
    const [selectedClientUrl, setSelectedClientUrl] = useState("");
    const currentKey = Object.keys(groupedData)[currentPage - 1];
    const [currentItems, setCurrentItems] = useState(groupedData[currentKey] || []);
    const [isYesClicked, setIsYesClicked] = useState(false);
    const [isNoClicked, setIsNoClicked] = useState(false);
    const [form_data, set_form_data] = useState()
    const [number_of_opportunity, set_number_of_opportunity] = useState(1)
    const [number_of_alternative_links, set_number_of_alternative_links] = useState(1)
    const [yesConfirmedData, setYesConfirmedData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [status_update_check, set_status_update_check] = useState(false)
    const [otherItems, setOtherItems] = useState([]);

    const getFormDataByCampaignId = async () => {
        try {
            const response = await fetch(`${getFullApiUrl(GET_FIND_LINK_INSERT_FORM_DATA)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ campaign_id: campaignId }),
            });

            if (!response.ok) throw new Error("Failed to fetch form data");

            const data = await response.json();
            const opportunities = parseInt(data.domainOpportunities)
            const alternativeLinks = parseInt(data.alternativeLinks)
            set_number_of_opportunity(opportunities);
            set_number_of_alternative_links(alternativeLinks)
            return { domainOpportunities: data.domainOpportunities, alternativeLinks: data.alternativeLinks };
        } catch (error) {
            console.error(error.message);
            set_number_of_opportunity(1)
        }
    };
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleYesClick = async () => {
        if (!selectedItem) return;

        set_status_update_check(true);
        const current_domain = selectedItem.blog_domain;
        const currentPageData = groupedData[current_domain];
        console.log("currentPageData ", currentPageData);
        console.log("currentItems ", currentItems);

        // Count the number of "Yes(Confirmed)" items in the current page's data
        const yesConfirmedCount = currentPageData.filter(item => item.status === "Yes(Confirmed)").length;
        console.log("yesConfirmedCount ", yesConfirmedCount);

        // if (yesConfirmedCount >= number_of_opportunity) {
        //     setSnackbarMessage("Yes(Confirmed) limit exceeded");
        //     setSnackbarOpen(true);
        //     return;
        // }

        // Update selectedItem's status to "Yes(Confirmed)"
        const updatedItem = { ...selectedItem, status: "Yes(Confirmed)" };
        console.log("updatedItem ", updatedItem);

        // Update topMatches to reflect "Yes(Confirmed)" for selectedItem
        let updatedTopMatches = topMatches.map(item =>
            item.blog_domain === selectedItem.blog_domain
                ? item.id === selectedItem.id
                    ? updatedItem // Set selected item to "Yes(Confirmed)"
                    : item // Keep other items as they are (do not set them to "No(Confirmed)")
                : item
        );
        console.log("updatedTopMatches ", updatedTopMatches);

        // Ensure currentPageTopMatch is updated correctly
        if (selectedItem !== currentPageTopMatch) {
            if (currentPageTopMatch) {
                // Update currentPageTopMatch's status to "No(Confirmed)" if it exists
                const updatedCurrentPageTopMatch = { ...currentPageTopMatch, status: "No(Confirmed)" };
                updatedTopMatches = updatedTopMatches.map(item =>
                    item.id === currentPageTopMatch.id ? updatedCurrentPageTopMatch : item
                );
            }
            // Make selectedItem the new currentPageTopMatch
            setCurrentPageTopMatch(updatedItem);
        } else {
            // If selectedItem is the currentPageTopMatch, update its status in screenshotData
            updatedTopMatches = updatedTopMatches.map(item =>
                item.id === selectedItem.id ? updatedItem : item
            );
        }

        // Update screenshotData to reflect changes in topMatches
        let updatedScreenshotData = screenshotData.map(item => {
            const matchingTopMatch = updatedTopMatches.find(match => match.id === item.id);
            return matchingTopMatch ? matchingTopMatch : item;
        });

        // Handle alternative links logic
        if (number_of_alternative_links > 0) {
            const remainingItems = updatedScreenshotData.filter(
                item => item.blog_domain === updatedItem.blog_domain && item.id !== updatedItem.id
            );

            const domainOtherItems = remainingItems
                .sort((a, b) => {
                    const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                    const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);
                    const totalOccurrenceA = a.keywords.reduce((total, keyword) => total + keyword.occurrence, 0);
                    const totalOccurrenceB = b.keywords.reduce((total, keyword) => total + keyword.occurrence, 0);
                    const hasKeywordMatchA = a.keywords.some(keyword => Object.values(keyword)[1] > 0);
                    const hasKeywordMatchB = b.keywords.some(keyword => Object.values(keyword)[1] > 0);

                    if (scoreA !== scoreB) {
                        return scoreB - scoreA;
                    } else if (totalOccurrenceA !== totalOccurrenceB) {
                        return totalOccurrenceB - totalOccurrenceA;
                    } else {
                        return hasKeywordMatchB ? 1 : -1;
                    }
                })
                .slice(0, number_of_alternative_links);

            // Update otherItems for the domain
            setOtherItems(prev => ({
                ...prev,
                [updatedItem.blog_domain]: domainOtherItems.map(item => ({
                    ...item,
                    status: "No(Confirmed)" // Set all alternative items to "No(Confirmed)"
                }))
            }));

            // Update screenshotData to reflect "No(Confirmed)" status for otherItems, but keep selectedItem as "Yes(Confirmed)"
            updatedScreenshotData = updatedScreenshotData.map(item => {
                if (item.id === updatedItem.id) {
                    // If the item is the selectedItem, ensure it stays "Yes(Confirmed)"
                    return { ...item, status: "Yes(Confirmed)" };
                }

                const matchingOtherItem = domainOtherItems.find(other => other.id === item.id);
                return matchingOtherItem ? { ...item, status: "No(Confirmed)" } : item;
            });
        }

        // Update groupedData to reflect changes in updatedScreenshotData
        const updatedGroupedData = await groupDataByDomainCombination(updatedScreenshotData);

        // Update states
        setScreenshotData(updatedScreenshotData);
        setGroupedData(updatedGroupedData);
        await save_screenshot_data_function(updatedScreenshotData)
        // Ensure selectedItem is included in YesConfirmedData
        setYesConfirmedData(prev => {
            const exists = prev.find(item => item.id === updatedItem.id);
            return exists ? prev : [...prev, updatedItem];
        });

        const currentDomain = selectedItem.blog_domain;
        const currPageData = updatedGroupedData[currentDomain];
        console.log("currentDomain ", currentDomain);
        console.log("currPageData ", currPageData);

        // Calculate top matches and distribute across pages
        await determineTopMatches(currPageData, number_of_opportunity, number_of_alternative_links, currentPage);

        console.log("Updated ScreenshotData: ", updatedScreenshotData);
        console.log("Updated GroupedData: ", updatedGroupedData);

        setIsYesClicked(true);
        setIsNoClicked(false);
        // setTimeout(() => {
        //     goToNextPage();
        // }, 2000);
        await goToNextPage();

    };




    const handleNoClick = async () => {
        if (!selectedItem) return;

        set_status_update_check(true);
        const current_domain = selectedItem.blog_domain;
        const currentPageData = groupedData[current_domain];
        console.log("currentPageData ", currentPageData);
        console.log("currentItems ", currentItems);

        // Update selectedItem's status to "No(Confirmed)"
        const updatedItem = { ...selectedItem, status: "No(Confirmed)" };
        console.log("updatedItem ", updatedItem);

        // Update screenshotData to reflect changes related to selectedItem
        let updatedScreenshotData = screenshotData.map(item => {
            // Only update the selectedItem in screenshotData
            if (item.id === selectedItem.id) {
                return updatedItem;
            }
            return item;
        });

        // Handle alternative links logic if applicable
        if (number_of_alternative_links > 0) {
            const remainingItems = updatedScreenshotData.filter(
                item => item.blog_domain === updatedItem.blog_domain && item.id !== updatedItem.id
            );

            const domainOtherItems = remainingItems
                .sort((a, b) => {
                    const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                    const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);
                    const totalOccurrenceA = a.keywords.reduce((total, keyword) => total + keyword.occurrence, 0);
                    const totalOccurrenceB = b.keywords.reduce((total, keyword) => total + keyword.occurrence, 0);
                    const hasKeywordMatchA = a.keywords.some(keyword => Object.values(keyword)[1] > 0);
                    const hasKeywordMatchB = b.keywords.some(keyword => Object.values(keyword)[1] > 0);

                    if (scoreA !== scoreB) {
                        return scoreB - scoreA;
                    } else if (totalOccurrenceA !== totalOccurrenceB) {
                        return totalOccurrenceB - totalOccurrenceA;
                    } else {
                        return hasKeywordMatchB ? 1 : -1;
                    }
                })
                .slice(0, number_of_alternative_links);

            // Update otherItems for the domain
            setOtherItems(prev => ({
                ...prev,
                [updatedItem.blog_domain]: domainOtherItems.map(item => ({
                    ...item,
                    status: "No(Confirmed)" // Set all alternative items to "No(Confirmed)"
                }))
            }));

            // Update screenshotData for otherItems, but keep selectedItem as "No(Confirmed)"
            updatedScreenshotData = updatedScreenshotData.map(item => {
                if (item.id === updatedItem.id) {
                    return { ...item, status: "No(Confirmed)" }; // Ensure selectedItem stays "No(Confirmed)"
                }

                const matchingOtherItem = domainOtherItems.find(other => other.id === item.id);
                return matchingOtherItem ? { ...item, status: "No(Confirmed)" } : item;
            });
        }

        // Update groupedData to reflect changes in updatedScreenshotData
        const updatedGroupedData = await groupDataByDomainCombination(updatedScreenshotData);

        // Update states
        setScreenshotData(updatedScreenshotData);
        setGroupedData(updatedGroupedData);

        // Ensure selectedItem is included in YesConfirmedData
        setYesConfirmedData(prev => {
            const exists = prev.find(item => item.id === updatedItem.id);
            return exists ? prev : [...prev, updatedItem];
        });

        const currPageData = updatedGroupedData[current_domain];
        console.log("currentDomain ", current_domain);
        console.log("currPageData ", currPageData);

        // Calculate top matches and distribute across pages (optional)
        await determineTopMatches(currPageData, number_of_opportunity, number_of_alternative_links, currentPage);

        console.log("Updated ScreenshotData: ", updatedScreenshotData);
        console.log("Updated GroupedData: ", updatedGroupedData);
        await save_screenshot_data_function(updatedScreenshotData)

        // Update button states
        setIsYesClicked(false);
        setIsNoClicked(true);
    };




    const handleFindOwnMatches = () => {
        setShowKeywordsSearch(true);
    };
    const iframeRef = useRef(null);

    // State to manage iframe navigation history
    const [iframeHistory, setIframeHistory] = useState([]);
    const [currentUrlIndex, setCurrentUrlIndex] = useState(-1);

    const handleSelectItem = (item) => {
        setSelectedItem(item);

        // Determine the highlight class based on the item's status
        if (item.status === "Yes(Confirmed)") {
            setIsYesClicked(true);
            setIsNoClicked(false);
        } else if (item.status === "No(Confirmed)") {
            setIsYesClicked(false);
            setIsNoClicked(true);
        } else {
            setIsYesClicked(false);
            setIsNoClicked(false);
        }
    };

    const groupDataByDomainCombination = async (data) => {
        const groupedData = {};
        let topMatchItem = null;

        // Group data by blog domain and determine topMatches for each domain
        data.forEach((item) => {
            if (!groupedData[item.blog_domain]) groupedData[item.blog_domain] = [];
            groupedData[item.blog_domain].push(item);
        });

        // Sorting logic: First by similarity_score, then by keyword match
        const sortedGroupedData = {};
        for (const [domain, items] of Object.entries(groupedData)) {
            // Sort items by similarity_score and then by keyword match
            const sortedItems = items.sort((a, b) => {
                const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);

                // Check if keyword match exists (any keyword occurrence > 0)
                const hasKeywordMatchA = a.keywords.some((keyword) => Object.values(keyword)[1] > 0);
                const hasKeywordMatchB = b.keywords.some((keyword) => Object.values(keyword)[1] > 0);

                // Prioritize by similarity_score first, then by keyword match
                if (scoreA !== scoreB) {
                    return scoreB - scoreA; // Higher score first
                } else {
                    return hasKeywordMatchB ? 1 : -1; // Prefer items with keyword match
                }
            });

            sortedGroupedData[domain] = sortedItems;
        }

        // Return the sorted grouped data
        return sortedGroupedData;
    };
    const [currentTopMatchIndex, setCurrentTopMatchIndex] = useState(1)

    const determineTopMatches = async (data, number_of_opportunity, number_of_alternative_links, currentPageIndex) => {
        if (data.length === 0) return;

        let topMatches = [];
        let otherItems = {};

        // Helper function to calculate total keyword occurrence
        const getTotalKeywordOccurrence = (keywords) => {
            return keywords.reduce((total, keyword) => total + keyword.occurrence, 0);
        };

        // Separate confirmed and non-confirmed items
        const confirmedItems = data.filter(item => item.status === "Yes(Confirmed)");
        const nonConfirmedItems = data.filter(item => item.status !== "Yes(Confirmed)");

        // If number_of_opportunity is 1, set topMatches to the first confirmed item if it exists
        if (number_of_opportunity === 1 && confirmedItems.length > 0) {
            topMatches = [confirmedItems[0]];
        } else {
            // Otherwise, add confirmed items directly as top matches if there are more than 1 opportunity
            topMatches = confirmedItems;
            console.log("topmatches in confirmedItems", topMatches)
            // Sort non-confirmed items by similarity_score, keyword match, and total keyword occurrence
            nonConfirmedItems.sort((a, b) => {
                const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);

                const totalOccurrenceA = getTotalKeywordOccurrence(a.keywords);
                const totalOccurrenceB = getTotalKeywordOccurrence(b.keywords);

                const hasKeywordMatchA = a.keywords.some(keyword => Object.values(keyword)[1] > 0);
                const hasKeywordMatchB = b.keywords.some(keyword => Object.values(keyword)[1] > 0);

                if (scoreA !== scoreB) {
                    return scoreB - scoreA; // Higher score first
                } else if (totalOccurrenceA !== totalOccurrenceB) {
                    return totalOccurrenceB - totalOccurrenceA; // Higher total occurrence first
                } else {
                    return hasKeywordMatchB ? 1 : -1; // Prefer items with keyword match
                }
            });
            console.log("nonConfirmedItems of topmaches", nonConfirmedItems)

            // Add additional non-confirmed items to fill up the top matches if necessary
            const remainingSlots = number_of_opportunity - topMatches.length;
            if (remainingSlots > 0) {
                // Exclude confirmed items from nonConfirmedItems when adding to topMatches
                const nonConfirmedFiltered = nonConfirmedItems.filter(item => !confirmedItems.includes(item));
                topMatches = [...topMatches, ...nonConfirmedFiltered.slice(0, remainingSlots)];
            }
            console.log("remainingSlots ", remainingSlots)
            console.log("topMatches after update", topMatches)

        }

        // Only define otherItems if number_of_alternative_links is greater than 0
        if (number_of_alternative_links > 0) {
            topMatches.forEach(topMatchItem => {
                const remainingItems = [...nonConfirmedItems].filter(item => item !== topMatchItem && !confirmedItems.includes(item));

                // Sort and slice alternative links for the current top match
                let alternatives = remainingItems.sort((a, b) => {
                    const scoreA = parseInt(a.similarity_score.replace("%", ""), 10);
                    const scoreB = parseInt(b.similarity_score.replace("%", ""), 10);

                    const totalOccurrenceA = getTotalKeywordOccurrence(a.keywords);
                    const totalOccurrenceB = getTotalKeywordOccurrence(b.keywords);

                    const hasKeywordMatchA = a.keywords.some(keyword => Object.values(keyword)[1] > 0);
                    const hasKeywordMatchB = b.keywords.some(keyword => Object.values(keyword)[1] > 0);

                    if (scoreA !== scoreB) {
                        return scoreB - scoreA; // Higher score first
                    } else if (totalOccurrenceA !== totalOccurrenceB) {
                        return totalOccurrenceB - totalOccurrenceA; // Higher total occurrence first
                    } else {
                        return hasKeywordMatchB ? 1 : -1; // Prefer items with keyword match
                    }
                }).slice(0, number_of_alternative_links);

                otherItems[topMatchItem.blog_domain] = alternatives;
            });
        }

        // Update states or handle top matches
        setTopMatches(topMatches);
        if (number_of_alternative_links > 0) {
            setOtherItems(otherItems);
        }
        console.log("topMatches ", topMatches);
        console.log("topMatches.length ", topMatches.length);
        console.log("currentPageIndex ", currentPageIndex);


        // If there are confirmed items, set the first confirmed item as the top match
        if (confirmedItems.length === 1 && number_of_opportunity === 1) {
            console.log()
            const selectedItem = confirmedItems[0];
            setCurrentPageTopMatch(selectedItem);
            setSelectedItem(selectedItem);
            handleSelectItem(selectedItem);
        } else {
            let selectedItem;

            // If there's only one item, select it
            if (topMatches.length === 1) {
                selectedItem = topMatches[0];
            } else {
                // Otherwise, calculate the index based on currentPageIndex
                const currentTopMatchIndex = (currentPageIndex - 1) % topMatches.length;
                selectedItem = topMatches[currentTopMatchIndex];
                console.log("currentTopMatchIndex ", currentTopMatchIndex);

            }

            setCurrentPageTopMatch(selectedItem);
            setSelectedItem(selectedItem);
            handleSelectItem(selectedItem);
        }
    };






    const getScreenShotDataWithFilter = async (domainOpportunities, alternativeLinks) => {

        console.log("finalizeOppurtunityPageData ",finalizeOppurtunityPageData)
        try {
            setLoading(true);
            setIsYesClicked(false);
            const response = await fetch(
                `${getFullApiUrl(GET_TARGET_AND_PROSPECT_SCREENSHOT_DATA)}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ campaign_id: campaignId }),
                }
            );

            if (!response.ok) throw new Error("Failed to fetch screenshot data");

            const data = await response.json();

            const grouped = await groupDataByDomainCombination(data);

            setScreenshotData(data);
            setGroupedData(grouped);
            setTotalPages(Object.keys(grouped).length * domainOpportunities); // Update total pages based on number_of_opportunity
            setCurrentPage(1);

            // Get the domain name from the grouped object keys
            const domainNames = Object.keys(grouped);

            // Use currentPage to get the correct domain and its data
            const currentDomain = domainNames[Math.floor((currentPage - 1) / domainOpportunities)];
            const currPageData = grouped[currentDomain];
            console.log("currentDomain ", currentDomain);
            console.log("currPageData ", currPageData);

            // Calculate top matches and distribute across pages
            await determineTopMatches(currPageData, domainOpportunities, alternativeLinks, 1);

        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };


    const goToNextPage = async () => {
        if (currentPage < totalPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);

            // Get the domain and index based on number_of_opportunity
            const currentDomainIndex = Math.floor((newPage - 1) / number_of_opportunity);
            const currentDomain = Object.keys(groupedData)[currentDomainIndex];
            const newPageData = groupedData[currentDomain];
            setCurrentTopMatchIndex(newPage)

            console.log("currentDomain ", currentDomain);
            console.log("newPageData ", newPageData);

            if (newPageData && newPageData.length > 0) {
                try {
                    await determineTopMatches(newPageData, number_of_opportunity, number_of_alternative_links, newPage);
                    console.log(`Navigated to page ${newPage}`);
                } catch (error) {
                    console.error("Error determining top match:", error);
                }
            } else {
                console.warn("No data available for this page.");
            }
        } else {
            setSnackbarMessage("You are already on the last page.");
            setSnackbarOpen(true);

            console.log("You are already on the last page.");
        }
    };

    // Adjust previous page logic similarly
    const goToPreviousPage = async () => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);

            const currentDomainIndex = Math.floor((newPage - 1) / number_of_opportunity);
            const currentDomain = Object.keys(groupedData)[currentDomainIndex];
            const newPageData = groupedData[currentDomain];
            setCurrentTopMatchIndex(newPage)
            console.log("currentDomain ", currentDomain);
            console.log("newPageData ", newPageData);

            if (newPageData && newPageData.length > 0) {
                try {
                    await determineTopMatches(newPageData, number_of_opportunity, number_of_alternative_links, newPage);
                    console.log(`Navigated to page ${newPage}`);
                } catch (error) {
                    console.error("Error determining top match:", error);
                }
            } else {
                console.warn("No data available for this page.");
            }
        } else {
            console.log("You are already on the first page.");
            setSnackbarMessage("You are already on the first page.");
            setSnackbarOpen(true);
        }
    };

    const goToPreviousScreenshotData = () => {
        // Get the current page's data
        const currentKey = Object.keys(groupedData)[currentPage - 1];
        const currentPageData = groupedData[currentKey];

        if (currentPageData) {
            const currentIndex = currentPageData.indexOf(selectedItem);
            if (currentIndex > 0) {
                const previousItem = currentPageData[currentIndex - 1];
                setSelectedItem(previousItem); // Update selected item
                setTopMatches(topMatches); // Keep topMatches unchanged
            } else {
                console.warn("Already at the first item on this page.");
            }
        } else {
            console.warn("No data available for the current page.");
        }
    };

    const goToNextScreenshotData = () => {
        // Get the current page's data
        const currentKey = Object.keys(groupedData)[currentPage - 1];
        const currentPageData = groupedData[currentKey];

        if (currentPageData) {
            const currentIndex = currentPageData.indexOf(selectedItem);
            if (currentIndex < currentPageData.length - 1) {
                const nextItem = currentPageData[currentIndex + 1];
                setSelectedItem(nextItem); // Update selected item
                setTopMatches(topMatches); // Keep topMatches unchanged
            } else {
                console.warn("Already at the last item on this page.");
            }
        } else {
            console.warn("No data available for the current page.");
        }
    };






    useEffect(() => {
        getCampaignDetails();
        fetchData()

    }, []);
    const fetchData = async () => {
        const { domainOpportunities, alternativeLinks } = await getFormDataByCampaignId()
        await getScreenShotDataWithFilter(domainOpportunities, alternativeLinks);
    }




    const getCampaignDetails = async () => {
        try {
            const response = await fetch(
                `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        campaign_id: campaignId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch campaign details");
            }

            const data = await response.json();
            setCampaignData(data);
        } catch (error) {
            console.error(error.message);
        }
    };




    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };


    const handleGoBackClick = () => {
        navigate(`/my_campaign/${campaignId}/finalize_opportunities`); // Adjust the path to match your routing configuration
    };

    const getBarColorClass = (score) => {
        const scoreValue = parseInt(score.replace("%", ""), 10);
        if (scoreValue > 66) {
            return "similarity_green";
        } else if (scoreValue >= 25 && scoreValue <= 66) {
            return "similarity_orange";
        } else {
            return "similarity_red";
        }
    };

    const renderSimilarityBar = (score) => {
        const scoreValue = parseInt(score.replace("%", ""), 10);
        const barColorClass = getBarColorClass(score);

        return (
            <div className="similarity_bar_container">
                <div
                    className={`similarity_bar ${barColorClass}`}
                    style={{ width: `${scoreValue}%` }}
                ></div>
            </div>
        );
    };
    const getTextColorClass = (score) => {
        const scoreValue = parseInt(score.replace("%", ""), 10);
        if (scoreValue > 66) {
            return "text_green";
        } else if (scoreValue >= 25 && scoreValue <= 66) {
            return "text_orange";
        } else {
            return "text_red";
        }
    };


    const fetchScreenshot = async (url) => {
        try {
            setLoadingScreenshot(true);  // Show loading spinner
            const response = await fetch(
                `${getFullApiUrl(GET_SCREENSHOT)}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        url: url,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch the screenshot");
            }

            const data = await response.json(); // Assuming the image URL is in the response
            set_fetched_screenshot(data.screenshot)
            return data.screenshot; // Assuming the image URL is returned in this key
        } catch (error) {
            console.error('Error fetching screenshot:', error);
            // alert('Failed to load the screenshot image');
            return null;
        } finally {
            setLoadingScreenshot(false);  // Hide loading spinner
        }
    };

    const loadScreenshot = async () => {
        const screenshot = await fetchScreenshot(selectedItem.blog_url);
        set_fetched_screenshot(screenshot)
    };


    const handleIframeError1 = () => {
        // If there's an error loading the iframe, use the proxy URL as fallback
        console.log('Iframe error detected, switching to proxy URL');
        setIframeSrc(`${getFullApiUrl(GET_PROXY)}?url=${encodeURIComponent(selectedItem.blog_url)}`);
    };
    useEffect(() => {
        if (selectedItem.blog_url) {
            console.log("New selectedItem detected:", selectedItem.blog_url);
            setIframeHistory([selectedItem.blog_url]);
            setIframeSrc(selectedItem.blog_url);
            setCurrentUrlIndex(0);
        }
    }, [selectedItem.blog_url]);


    const handleIframeLoad = () => {
        console.log("Iframe attempted to load. ", iframeSrc);
        handleIframeError("unknown_error");

    };

    const handleIframeError = async (errorType = "unknown") => {
        try {
            console.warn(`Iframe error detected (${errorType}). Attempting to fetch a screenshot...`);

            // Reset the display states
            set_is_anchor_tag_visible(false);
            setIframeSrc(null);
            set_fetched_screenshot(null);

            // Fetch the screenshot
            const screenshot = await fetchScreenshot(selectedItem.blog_url);

            if (screenshot) {
                console.log("Screenshot fetched successfully.");
                set_fetched_screenshot(screenshot);
            } else {
                console.error("Failed to fetch screenshot. Showing link as fallback.");
                set_anchor_tag_url(selectedItem.blog_url);
                set_is_anchor_tag_visible(true); // Show anchor tag
            }
        } catch (error) {
            console.error("Error during fallback handling:", error);
            set_anchor_tag_url(selectedItem.blog_url);
            set_is_anchor_tag_visible(true); // Show anchor tag
        }
    };


    const handleSelectItemForBlogUrl = (url, currentSelectedItem) => {
        const selectedDomain = currentSelectedItem?.blog_domain;

        // Find the selected item from currentPageTopMatch or otherItems
        const matchedItem =
            currentPageTopMatch?.client_url === url
                ? currentPageTopMatch
                : otherItems?.[selectedDomain]?.find(item => item.client_url === url);

        if (matchedItem) {
            setSelectedItem(matchedItem);

            // Determine the highlight class based on the item's status
            if (matchedItem.status === "Yes(Confirmed)") {
                setIsYesClicked(true);
                setIsNoClicked(false);
            } else if (matchedItem.status === "No(Confirmed)") {
                setIsYesClicked(false);
                setIsNoClicked(true);
            } else {
                setIsYesClicked(false);
                setIsNoClicked(false);
            }
        }
    };


    const save_screenshot_data_function = async (screenshotData) => {
        try {
            const response = await fetch(`${getFullApiUrl(SAVE_TARGET_AND_PROSPECT_SCREENSHOT_DATA)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    campaign_id: campaignId,
                    screenshot_data: screenshotData,
                }),
            });

            if (!response.ok) throw new Error("Failed to save form data");


        } catch (error) {
            console.error(error.message);
        }
    };
    return (
        <div className="page_Section">
            <div className="leftSection_withMenu">
                <LeftSectionWithMenu />
            </div>
            <div className="rightSection_withMenu">
                <div className="headerSection_withMenu">
                    <Header onToggleTheme={toggleTheme} />
                </div>
                <div className="bodySection">
                    <Breadcrumbs
                        id="Breadcrumbs"
                        separator={<NavigateNext />}
                        aria-label="breadcrumb"
                    >
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to="/"
                                color="inherit"
                                underline="hover"
                            >
                                Home
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_teams`}
                                color="inherit"
                                underline="hover"
                            >
                                My Teams
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_team/${campaignData.team_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Details of Team  ( {campaignData.team_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_team_workspaces/${campaignData.team_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Workspaces of Team ( {campaignData.team_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_workspace/${campaignData.workspace_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Details of Workspace ({campaignData.workspace_name})
                            </Link>
                        </div>
                        <div className="breadcrumb_text">
                            <Link
                                component={RouterLink}
                                to={`/my_workspace_campaign/${campaignData.workspace_id}`}
                                color="inherit"
                                underline="hover"
                            >
                                Campaigns of Workspace  ({campaignData.workspace_name} )
                            </Link>
                        </div>
                        <div className="breadcrumb_text"> Details of Campaign ({campaignData.campaign_name})</div>
                        <div className="breadcrumb_text"> Quick Verify Screenshot Page</div>

                    </Breadcrumbs>
                    <FloatingMenuBar />

                    {loading ? (
                        <div className="loading_spinner">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="quickvarify_screenshot_page">
                            <div className="quickvarify_main_content_container">
                                <div className="header">
                                    <div className="go_back_button" onClick={handleGoBackClick}>
                                        <FaArrowLeft />
                                    </div>
                                    <div className="header_input_text_with_button_container">
                                        <div className="header_input_text">
                                            <div>
                                                Opportunities Status :
                                            </div>
                                            <div className="status_container">
                                                {selectedItem?.status ? selectedItem.status : "Yes (Auto)"}
                                            </div>
                                        </div>
                                        <div className="yes_no_button_container">
                                            <button className={`priamry ${isYesClicked ? 'selected' : ''}`}
                                                onClick={handleYesClick}
                                                disabled={isYesClicked}
                                            >YES (F)</button>
                                            <button className={`secondary ${isNoClicked ? 'selected' : ''}`} onClick={handleNoClick}>NO (C)</button>
                                        </div>
                                    </div>
                                    <div className="pagination">
                                        <div className="pagination_icon_container" onClick={goToPreviousPage} disabled={currentPage === 1}>
                                            <HiOutlineChevronLeft />
                                        </div>
                                        <div>{currentPage} / {totalPages}</div>
                                        <div className="pagination_icon_container" onClick={goToNextPage} disabled={currentPage === totalPages}>
                                            <HiOutlineChevronRight />
                                        </div>
                                    </div>
                                </div>
                                <div className="navigation_and_select_box_container">
                                    <div className="navigation_pagination">
                                        <div onClick={goToPreviousScreenshotData} >
                                            <HiOutlineChevronLeft />
                                        </div>
                                        <div onClick={goToNextScreenshotData} >
                                            <HiOutlineChevronRight />
                                        </div>
                                    </div>
                                    <div className="input_container">
                                        <div className="input_content_container">
                                            <div>Prospect URL</div>
                                            <div className="input_content_url_container">
                                                {selectedItem?.blog_url ? (
                                                    <a
                                                        href={selectedItem.blog_url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                                    >
                                                        {selectedItem.blog_url}
                                                    </a>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="input_content_container">
                                            <div>Target URL</div>
                                            <div className="input_select_content_url_container">
                                                <FormControl fullWidth variant="outlined">
                                                    <Select
                                                        value={selectedItem?.client_url || ""}
                                                        onChange={(e) => handleSelectItemForBlogUrl(e.target.value, selectedItem)}
                                                        displayEmpty
                                                        style={{ width: "100%", height: '45px' }}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select a URL
                                                        </MenuItem>
                                                        {Array.from(
                                                            new Set(
                                                                [
                                                                    ...(currentPageTopMatch?.client_url ? [currentPageTopMatch.client_url] : []),
                                                                    ...(otherItems?.[selectedItem?.blog_domain]?.map(item => item.client_url) || [])
                                                                ].filter(Boolean)
                                                            )
                                                        ).map((url, index) => (
                                                            <MenuItem key={index} value={url}>
                                                                {url}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="screenshot_image_container">
                                    <div className="screenshot_image">
                                        {iframeSrc ? (
                                            <iframe
                                                src={iframeSrc}
                                                ref={iframeRef}
                                                className="webpage_iframe"
                                                title="Webpage Iframe"
                                                style={{ border: "none", width: "100%", height: "100%" }}
                                                sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
                                                onLoad={handleIframeLoad}

                                            />
                                        ) : loadingScreenshot ? (
                                            <div className="loading_screenshot_container">
                                                <CircularProgress color="primary" size={60} />
                                                <div className="loading_screenshot_message">
                                                    Please wait while we load the screenshot...
                                                </div>
                                            </div>

                                        ) : fetched_screenshot ? (
                                            <img
                                                src={fetched_screenshot}
                                                alt="Screenshot of the webpage"
                                                className="webpage_screenshot"
                                            />
                                        ) : is_anchor_tag_visible ? (
                                            <a
                                                href={anchor_tag_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="webpage_link"
                                            >
                                                Open webpage in a new tab
                                            </a>
                                        ) : null}
                                    </div>



                                </div>
                            </div>
                            <div className="content_container">
                                {showKeywordsSearch ? (
                                    <KeywordsSearch
                                        selectedItem={selectedItem}
                                        screenshotData={screenshotData}
                                        goBack={() => setShowKeywordsSearch(false)}
                                    />
                                ) : (
                                    <div>
                                        <div className="matches_section">
                                            {topMatches && topMatches.length > 0 ? (
                                                <div>
                                                    <div className="top_match_section">
                                                        <div className="section_title">Top Match</div>
                                                        {currentPageTopMatch ? (
                                                            <div
                                                                key={currentPageTopMatch.client_url} // Using unique property as key
                                                                className={`match_item ${selectedItem.id === currentPageTopMatch.id ? 'quick_verify_selected_item' : ''}`}
                                                                onClick={() => handleSelectItem(currentPageTopMatch)}
                                                            >
                                                                {currentPageTopMatch.status === "Yes(Confirmed)" && (
                                                                    <div className="url_label_title_confirmed_container">
                                                                        <div>Confirmed</div>
                                                                    </div>
                                                                )}
                                                                {currentPageTopMatch.status === "No(Confirmed)" && (
                                                                    <div className="url_label_title_rejected_container">
                                                                        <div>Rejected</div>
                                                                       
                                                                    </div>
                                                                )}
                                                                <div className="url_label">
                                                                    Target URL:
                                                                </div>

                                                                <div className="url_value">{currentPageTopMatch.client_url}</div>
                                                                <div className="url_label">Prospect URL:</div>
                                                                <div className="url_value">{currentPageTopMatch.blog_url}</div>
                                                                <div className="keywords_content_container">
                                                                    <div className="keywords_container_header">🔍 Keywords</div>
                                                                    <div className="keywords_container">
                                                                        {currentPageTopMatch.keywords &&
                                                                            currentPageTopMatch.keywords.every(
                                                                                (keyword) => Object.values(keyword)[1] === 0
                                                                            ) ? (
                                                                            <div className="no_keywords_message">❌ No Keyword Matching Found</div>
                                                                        ) : (
                                                                            currentPageTopMatch.keywords.map(
                                                                                (keyword, index) =>
                                                                                    Object.values(keyword)[1] > 0 && (
                                                                                        <div key={index} className="match_button">
                                                                                            {`${Object.values(keyword)[0]} (${Object.values(keyword)[1]})`}
                                                                                        </div>
                                                                                    )
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="page_type_and_similarity_section_container">
                                                                    <div className="similarity_section">
                                                                        <span className={`similarity_label`}>Similarity:</span>
                                                                        <span
                                                                            className={`similarity_score ${getTextColorClass(
                                                                                currentPageTopMatch.similarity_score
                                                                            )}`}
                                                                        >
                                                                            {currentPageTopMatch.similarity_score}
                                                                        </span>
                                                                        {renderSimilarityBar(currentPageTopMatch.similarity_score)}
                                                                    </div>
                                                                    <div className="page_type_container">
                                                                        <div className="page_type_label">Page Type:</div>
                                                                        <div className="page_type_value">
                                                                            {currentPageTopMatch.page_type}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <p>No matches found for this page.</p>
                                                        )}
                                                    </div>


                                                    {number_of_alternative_links > 0 && (
                                                        <div className="other_matches_section">
                                                            <div className="section_title">More Options</div>
                                                            <div className="items_container">
                                                                {Object.entries(otherItems).map(([domain, items]) => (
                                                                    <div key={domain} className="other_item_container">
                                                                        
                                                                        {items.map((item) => (
                                                                            <div
                                                                                key={item.id}
                                                                                className={`match_item ${selectedItem.id === item.id ? 'quick_verify_selected_item' : ''}`}
                                                                                onClick={() => handleSelectItem(item)}
                                                                            >
                                                                                {item.status === "Yes(Confirmed)" && (
                                                                                    <div className="url_label_title_confirmed_container">
                                                                                        <div>Confirmed</div>
                                                                                        <div>
                                                                                            {/* <FaCheckCircle style={{ marginLeft: "8px", cursor: "pointer" }} /> */}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {item.status === "No(Confirmed)" && (
                                                                                    <div className="url_label_title_rejected_container">
                                                                                        <div>Rejected</div>
                                                                                        {/* <div>
                                                                                                <MdDoNotDisturbAlt style={{ marginLeft: "8px", cursor: "pointer" }} />
                                                                                            </div> */}
                                                                                    </div>
                                                                                )}
                                                                                
                                                                                <div className="url_label">
                                                                                    Target URL:

                                                                                </div>

                                                                                <div className="url_value">
                                                                                    {item.client_url}
                                                                                </div>
                                                                                <div className="url_label">
                                                                                    Prospect URL:
                                                                                </div>
                                                                                <div className="url_value">
                                                                                    {item.blog_url}
                                                                                </div>
                                                                                <div className="keywords_content_container">
                                                                                    <div className="keywords_container_header">
                                                                                        🔍 Keywords
                                                                                    </div>
                                                                                    <div className="keywords_container">
                                                                                        {item.keywords &&
                                                                                            item.keywords.every(
                                                                                                (keyword) =>
                                                                                                    Object.values(
                                                                                                        keyword
                                                                                                    )[1] === 0
                                                                                            ) ? (
                                                                                            <div className="no_keywords_message">
                                                                                                ❌ No Keyword Matching
                                                                                                Found
                                                                                            </div>
                                                                                        ) : (
                                                                                            item.keywords.map(
                                                                                                (keyword, index) =>
                                                                                                    Object.values(
                                                                                                        keyword
                                                                                                    )[1] > 0 && (
                                                                                                        <div
                                                                                                            key={index}
                                                                                                            className="match_button"
                                                                                                        >
                                                                                                            {`${Object.values(
                                                                                                                keyword
                                                                                                            )[0]} (${Object.values(
                                                                                                                keyword
                                                                                                            )[1]})`}
                                                                                                        </div>
                                                                                                    )
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="page_type_and_similarity_section_container">
                                                                                    <div className="similarity_section">
                                                                                        <span
                                                                                            className={`similarity_label`}
                                                                                        >
                                                                                            Similarity:
                                                                                        </span>
                                                                                        <span
                                                                                            className={`similarity_score ${getTextColorClass(
                                                                                                item.similarity_score
                                                                                            )}`}
                                                                                        >
                                                                                            {item.similarity_score}
                                                                                        </span>
                                                                                        {renderSimilarityBar(
                                                                                            item.similarity_score
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="page_type_container">
                                                                                        <div className="page_type_label">
                                                                                            Page Type:
                                                                                        </div>
                                                                                        <div className="page_type_value">
                                                                                            {item.page_type}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="no_match_message">No Top Match Found</div>
                                            )}
                                        </div>

                                        {number_of_alternative_links > 0 && (
                                            <div className="find_matches_button" onClick={handleFindOwnMatches}>
                                                Find Own Matches
                                            </div>
                                        )}


                                    </div>

                                )}


                            </div>
                            <Snackbar
                                open={snackbarOpen}
                                autoHideDuration={3000}
                                onClose={closeSnackbar}
                                message={snackbarMessage}
                            />

                        </div>
                    )}
                </div>
            </div >

        </div >
    );
};

export default QuickVarifyScreenshotPage;
