import React from "react";
import PropTypes from "prop-types";
import "./CustomButton.css";

const CustomButton = ({ title, type, onClick }) => {
  const buttonClass = type === "primary" ? "reset_button" : "proceed_button";

  return (
    <div className="custom_button">
        <button className={`button ${buttonClass}`} onClick={onClick}>
            {title}
        </button>
    </div>
  );
};

CustomButton.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary"]).isRequired,
  onClick: PropTypes.func,
};

export default CustomButton;
