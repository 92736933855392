import React, { useState, useEffect, useRef } from "react";
import { Await, Link, useParams } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Papa from "papaparse";
//import {Router, browserHistory} from 'react-router';
import { useNavigate } from "react-router-dom";
import {
  Input,
  Tabs,
  Tab,
  Box,
  Grid,
  Button,
  Snackbar,
  IconButton,
  Modal,
  Typography,
  Tooltip,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Skeleton,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {} from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from "@mui/material";
//import Tooltip from '@mui/material/Tooltip';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import page_classification_icon from "../../../assets/icons/page_classification_icon2.png";
import save_button_icon from "../../../assets/icons/save_button_icon.png";

import { styled } from "@mui/material/styles";
import TableWithPagination from "./../../../utils/TableWithPagination";
import "./ClassifyResultPagesComponent.css";
import {
  BUCKET_NAME,
  CHECK_CREDIT_CONDITION,
  DELETE_ANALYZED_UPLOAD_IDENTIFIED_PARAGRAPH_DATA,
  GET_ANALYZED_CLIENT_DATA_FILES,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_IDENTIFIED_PARAGRAPHS_DATA_FILTERED,
  GET_POINTS_DETAILS,
  GET_USER_UPLOAD_IDENTIFIED_PARAGRAPH_FILES,
  LOAD_PREVIOUSLY_CLASSIFIED_DATA,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_INDIVIDUAL_CAMPAIGN_DATA,
  USER_UPLOAD_IDENTIFIED_PARAGRAPHS_DATA,
  getFullApiUrl,
} from "../../../utils/apiPath";
import { FaProjectDiagram, FaSave } from "react-icons/fa";
import {
  LAMBDA_FUNCTION_TO_GET_FILTERED_IDENTIFIY_PARAGRAPH,
  LAMBDA_FUNCTION_TO_SAVE_USER_UPLOAD_IDENTIFIY_PARAGRAPH,
} from "../../../utils/lambdaFunctionPath";

const ClassifyResultPagesComponent = ({ props }) => {
  const tableFields = [
    { type: "field", field: "client_url", headerName: "Client URL" },
    { type: "field", field: "blog_url", headerName: "Blog URL" },
    { type: "field", field: "classification", headerName: "Page Type" },
    {
      type: "field",
      field: "similarity_score",
      headerName: "Similarity Score",
    },
  ];
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [
    show_should_we_classify_urls_modal,
    set_show_should_we_classify_urls_modal,
  ] = useState(false);
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [isClassifying, setIsClassifying] = useState(false);
  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");
  const [classificationProgress, setClassificationProgress] = useState({
    processed: 0,
    total: 0,
  });
  const [saving, set_saving] = useState(false);
  const [progressMessage, setProgressMessage] = useState("Classifying...");
  const progressPercentage =
    classificationProgress.total === 0
      ? 0
      : (100 * classificationProgress.processed) / classificationProgress.total;

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );
      console.log("button");

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      console.log("---------------------------------------");
      console.log("points_to_be_deducted");
      console.log(points);
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );

      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
      // setNotificationMessage(`${points} Points deducted successfully`);
      // setOpenSnackbar(true);
      fetchpointsDetails();
      // Close the Modal after deduction
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle_should_we_classify_urls_close = () => {
    set_show_should_we_classify_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
    setErrorMessage("");
  };

  const [identified_paragraphs_data, set_identified_paragraphs_data] = useState(
    []
  );

  //const [initial_data_loaded_client, set_initial_data_loaded_client] = useState(0);
  //const [initial_data_loaded_blog, set_initial_data_loaded_blog] = useState(0);
  const [
    initial_data_loaded_identified_paragraphs,
    set_initial_data_loaded_identified_paragraphs,
  ] = useState(0);

  //const [filter_count_of_keyword_match, set_filter_count_of_keyword_match] = useState(0);
  const [filter_similarity_score, set_filter_similarity_score] = useState({
    from: 33,
    to: 70,
  });

  //const [open, setOpen] = React.useState(false);

  const [filtered_scored_data, set_filtered_scored_data] = useState([]);
  const [errorFetchMassage, setErrorFetchMassage] = useState(null);
  const [filtered_blog_urls, set_filtered_blog_urls] = useState([]);
  //const [search_data, set_search_data] = useState([]);

  const [file_upload_field_key, set_file_upload_field_key] = useState(
    Math.random()
  );
  const [upload_file_fields, set_upload_file_fields] = useState([]);

  const fetchFileList = async () => {
    return fetch(getFullApiUrl(GET_ANALYZED_CLIENT_DATA_FILES), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        return data.files_by_client_id; // Assuming files are grouped by client ID.
      });
  };
  const get_identified_paragraphs_data_filtered = async () => {
    set_initial_data_loaded_identified_paragraphs(1);

    // Step 1: Fetch the file list first
    const fileList = await fetchFileList()
      .then((fileList) => {
        // Step 2: Iterate over each file and fetch the paragraphs data
        const filePromises = [];

        Object.keys(fileList).forEach((clientId) => {
          const files = fileList[clientId];

          files.forEach((file) => {
            // Create a POST request for each file to get paragraph data
            const filePromise = fetch(
              LAMBDA_FUNCTION_TO_GET_FILTERED_IDENTIFIY_PARAGRAPH,
              {
                method: "POST",
                body: JSON.stringify({
                  campaign_id: campaignId,
                  bucket_name: BUCKET_NAME,

                  score_from: parseInt(filter_similarity_score.from),
                  score_to: parseInt(filter_similarity_score.to),
                  file_name: `${clientId}/${file}`, // Pass the client ID and file name
                }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then((response) => response.json())
              .then((inputData) => {
                // Check if data exists and handle appropriately
                const filteredData = inputData.filtered_analyzed_data || [];
                const transformedData = [];
                let search_result_id = 0;

                // Loop over each entry in the filtered data
                filteredData.forEach((entry) => {
                  const clientUrl = entry.client_url;
                  const blogUrls = entry.blog_urls;

                  blogUrls.forEach((blog) => {
                    search_result_id += 1;
                    const transformedEntry = {
                      search_result_id: search_result_id,
                      client_url: clientUrl,
                      blog_url: blog.blog_url,
                      blog_title: blog.title || "", // Default value if blog title is missing
                      keywords: blog.keywords || [], // Default empty array if keywords are missing
                      selected: blog.selected || false,
                      similarity_score: blog.similarity_score || 0,
                    };
                    transformedData.push(transformedEntry);
                  });
                });
                console.log("transformedData ", transformedData);
                return transformedData;
              })
              .catch((error) => {
                console.error("Error fetching file data:", error);
                return [];
              });

            filePromises.push(filePromise);
          });
        });

        // Step 3: Wait for all file data to be fetched and processed
        return Promise.all(filePromises);
      })
      .then((allData) => {
        // Flatten the array of arrays into a single array
        const finalData = allData.flat();
        set_filtered_scored_data(finalData);
        set_initial_data_loaded_identified_paragraphs(0);
        setErrorFetchMassage(null);
        console.log("finalData ", finalData);
      })
      .catch((error) => {
        console.error("Error:", error);
        set_filtered_scored_data([]);
        set_initial_data_loaded_identified_paragraphs(0);
        setErrorFetchMassage("There is no data to classify");
      });
  };

  const [columns_to_select, set_columns_to_select] = useState([
    { key: "client_url", text: "Client URL", csv_field: "" },
    { key: "blog_url", text: "Blog URL", csv_field: "" },
    { key: "blog_title", text: "Blog Title", csv_field: "" },
    { key: "similarity_score", text: "Similarity Score", csv_field: "" },
    { key: "classification", text: "Page Type", csv_field: "" },
  ]);
  const [uploaded_file_data, set_uploaded_file_data] = useState([]);
  const [isFileSelectVisible, setIsFileSelectVisible] = useState(false);
  const handleColumnSelection = (columnName, uploaded_file_field) => {
    console.log("*************************");
    console.log(columnName);
    console.log(uploaded_file_field);
    console.log(columns_to_select);
    if (columnName == "") {
      set_columns_to_select((prevColumnsToSelect) =>
        prevColumnsToSelect.map((column) =>
          column.csv_field === uploaded_file_field
            ? { ...column, csv_field: "" }
            : column
        )
      );
    } else {
      /*
      set_columns_to_select((prevColumnsToSelect) =>
        prevColumnsToSelect.map((column) =>
          column.key === columnName ? { ...column, csv_field: uploaded_file_field } : column
        )
      );
      */
      set_columns_to_select((prevColumnsToSelect) =>
        prevColumnsToSelect.map((column) => {
          return column.key === columnName
            ? { ...column, csv_field: uploaded_file_field }
            : column.csv_field === uploaded_file_field
            ? { ...column, csv_field: "" }
            : column;
        })
      );
    }
  };

  const upload_user_uploaded_file = (event) => {
    console.log("upload_user_uploaded_file");
    const user_uploaded_file = event.target.files[0];
    if (user_uploaded_file) {
      Papa.parse(user_uploaded_file, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          const columnNames = Object.keys(results.data[0] || {});
          setIsFileSelectVisible(true);
          console.log("Column Names:", columnNames);
          set_upload_file_fields(columnNames);
          set_uploaded_file_data(results);
        },
      });
    }
    set_file_upload_field_key(Math.random());
  };

  const load_user_uploaded_file_data = (event) => {
    console.log(columns_to_select);
    var search_result_id = 0;
    const updatedData = uploaded_file_data.data.map((obj) => {
      search_result_id = search_result_id + 1;

      const mappedData = {
        search_result_id: search_result_id,
        client_url: columns_to_select
          .filter(
            (column) => column.key === "client_url" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
        blog_url: columns_to_select
          .filter(
            (column) => column.key === "blog_url" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
        blog_title: columns_to_select
          .filter(
            (column) => column.key === "blog_title" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
        similarity_score: columns_to_select
          .filter(
            (column) =>
              column.key === "similarity_score" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
        classification: columns_to_select
          .filter(
            (column) =>
              column.key === "classification" && column.csv_field !== ""
          )
          .map((column) => obj[column.csv_field])
          .join(""),
      };

      return mappedData;
    });

    console.log(updatedData);
    set_filtered_scored_data(updatedData);
  };
  const deleteUserUploadAnalyzedParagraphData = async () => {
    try {
      const response = await fetch(
        getFullApiUrl(DELETE_ANALYZED_UPLOAD_IDENTIFIED_PARAGRAPH_DATA),
        {
          method: "POST",
          body: JSON.stringify({ campaign_id: campaignId }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error deleting blog URLs data:", error);
    }
  };
  const handleSaveMultiple = async (record_ids) => {
    set_saving(true);
    await deleteUserUploadAnalyzedParagraphData();
    console.log("Saving process started...");
    console.log("filtered_scored_data ", filtered_scored_data);
    // Step 1: Filter data based on selected `record_ids`
    var filteredDataWithRecordIds = filtered_scored_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );
    console.log(
      "Filtered data based on record IDs:",
      filteredDataWithRecordIds
    );

    // Step 2: Map to the structure needed for saving
    var selectedFields = filteredDataWithRecordIds.map((item) => ({
      search_result_id: item.search_result_id,
      client_url: item.client_url,
      blog_url: item.blog_url,
      page_type: item.classification,
      similarity_score: item.similarity_score,
    }));
    console.log("Mapped selected fields for saving:", selectedFields);

    // Step 3: Function to chunk data into batches of size 10000
    const chunkArray = (array, chunkSize) => {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };

    // Step 4: Split data into batches
    const batches = chunkArray(selectedFields, 10000);
    console.log("Batches created:", batches);

    // Step 5: Function to upload a single batch to the Lambda function with file name
    const uploadBatch = async (batch, batchIndex) => {
      const startId = batchIndex * 10000 + 1; // Starts from 1 for the first batch
      const endId = startId + 9999; // Ends at 10000 for the first batch, 20000 for the second, etc.
      const fileName = `${startId}-${endId}`; // Create file name in the format "start-end"
      console.log(`Uploading batch: ${fileName}`, batch);

      return fetch(LAMBDA_FUNCTION_TO_SAVE_USER_UPLOAD_IDENTIFIY_PARAGRAPH, {
        method: "POST",
        body: JSON.stringify({
          campaign_id: campaignId,
          data: batch,
          file_name: fileName,
          bucket_name: BUCKET_NAME,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(`Received response for batch ${fileName}:`, response);
          return response.json();
        })
        .then((data) => {
          console.log(`Batch ${fileName} uploaded successfully:`, data);
        })
        .catch((error) => {
          console.error(`Error uploading batch ${fileName}:`, error);
          throw error; // Handle errors as needed
        });
    };

    // Step 6: Upload all batches concurrently
    try {
      const uploadPromises = batches.map((batch, index) =>
        uploadBatch(batch, index)
      );
      await Promise.all(uploadPromises); // Wait for all uploads to complete
      console.log("All batches uploaded successfully.");
      saveProjectData(); // Call saveProjectData after all batches are uploaded
    } catch (error) {
      console.error("Error uploading data in batches:", error);
    } finally {
      set_saving(false);
      console.log("Saving process finished.");
    }
  };
  const fetchUserUploadFileList = async () => {
    return fetch(getFullApiUrl(GET_USER_UPLOAD_IDENTIFIED_PARAGRAPH_FILES), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        request_data: {
          campaign_id: campaignId,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        return data.files_by_client_id; // Assuming files are grouped by client ID.
      });
  };

  const load_previously_classified_data = async () => {
    // await fetchUserUploadFileList()
    fetch(getFullApiUrl(LOAD_PREVIOUSLY_CLASSIFIED_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        var search_result_id = 0;
        const updatedData = data.data.map((obj) => {
          search_result_id = search_result_id + 1;
          return {
            ...obj,
            search_result_id: search_result_id,
            classification: obj.page_type,
          };
        });
        set_filtered_scored_data(updatedData);
        setErrorFetchMassage(null);
        //console.log('File uploaded successfully:', data);
      })
      .catch((error) => {
        setErrorFetchMassage("There is no data to classify");
        console.error("Error Downloading file:", error);
      });
  };

  const get_blog_urls_from_uploaded_data = () => {};

  const get_prompt = (blog_url) => {
    var classification_prompt = 
    "\n\n The URL of the webpage is " + blog_url["blog_url"] + 
    "\n\n The webpage title is " + blog_url["blog_title"] + 
    "\n\n Instructions for Classifying Page Type (Only Output the Category Name)" + 
    "\n\n Start with the URL." + 
    "\n\n Look for exact matches to known patterns (for example, “/about” → About Us Page, “/contact” → Contact Page)." + 
    "\n If the URL alone clearly identifies the category, select that category immediately." + 
    "\n Use the title if the URL is inconclusive." + 
    "\n\n Check the title for strong keywords that might indicate a particular page type (for example, “Case Study,” “FAQ,” “Blog,” “vs,” “Top 10,” etc.)." + 
    "\n If the title aligns well with a known category, choose that category." + 
    "\n Resolve conflicts between URL and title." + 
    "\n\n If the URL suggests one category but the title suggests another, decide which signal appears stronger or more reliable." + 
    "\n When in doubt, pick the category that best fits the page’s likely function." + 
    "\n Make an educated guess if the page type is unclear." + 
    "\n\n When neither the URL nor the title provides enough clues, select the category that seems most plausible based on any partial matches." + 
    "\n\n Output only the category name." + 
    "\n\n Do not include explanations, reasoning, or extra text." + 
    "\n Choose from the categories listed below." + 
    "\n\n Page Type Categories" + 
    "\n\n Blog Page Types" + 
    "\n- Blog Roll Page (main hub with links to multiple blog posts)" + 
    "\n- Advice Listicle Blog Page (list-based tips or strategies, often titled with a number like “10 Ways to…”)"+
    "\n- Recommendation Listicle Blog Page (list-based product or service recommendations, often titled “Top 10…” or “Best [keyword]…”)"+
    "\n- Blog Post (single in-depth article, often informational or how-to)" + 
    "\n- Blog Category Page (lists blog posts under a particular category)" + 
    "\n- Blog Tag Page (lists blog posts under a specific tag)" + 
    "\n- Blog Author Page (shows an author’s profile and related articles)" + 
    "\n- Resource Page (curated links or references to external resources)" + 
    "\n- Comparison Blog Page (compares products or services, often with “vs” in the title)" + 
    "\n\nE-commerce Page Types" + 
    "\n- Ecommerce Product Page (showcases a single product for purchase)" + 
    "\n- Ecommerce Category Page (lists products under a specific category)" + 
    "\n- Glossary Blog Page (defines industry-related terms)" + 
    "\n\nStandard Business Page Types" + 
    "\n- About Us Page (organizational background, mission, and team info)" + 
    "\n- Contact Page (contact details, map, or inquiry form)" + 
    "\n- FAQ Page (organized Q&A format)" + 
    "\n- Privacy Policy Page (legal page explaining data usage and privacy)" + 
    "\n- Terms of Service Page (legal conditions for using the site)" + 
    "\n- Support/Help Page (customer support or help center content)" + 
    "\n\nMarketing & Conversion Page Types" + 
    "\n- Homepage (main entry point, often the root domain)" + 
    "\n- Landing Page (conversion-focused for marketing campaigns)" + 
    "\n- Service Page (describes or lists services offered)" + 
    "\n- Pricing Page (details pricing plans or options)" + 
    "\n- Case Study Page (presents success stories or project outcomes)" + 
    "\n- Testimonials/Reviews Page (client feedback or reviews)" + 
    "\n- Portfolio Page (highlights examples of work or projects)" + 
    "\n- PDF Page (direct link to or display of a PDF)" + 
    "\n\nNews & Updates Page Types" + 
    "\n- News Page (announcements or updates, often time-specific)" + 
    "\n- Press Release Page (official statements for media)" + 
    "\n- Events Page (lists upcoming or past events)" + 
    "\n\nCommunity & User-Generated Page Types" + 
    "\n- Forum Page (user discussion boards, often “/forum” in the URL)" + 
    "\n- Job Listing Page (employment opportunities)" + 
    "\n- Directory Listing Page (organizes entries by category or type)" + 
    "\n- Wiki Page (centralized reference, often “wiki” in the URL)" + 
    "\n- Video Page (focused on embedded or hosted video content)" + 
    "\n- Social Media Post (individual updates on social platforms)" + 
    "\n- Profile Page (personal or company profiles)" + 
    "\n\n Remember:" + 
    "\n\n Always output only the chosen category name." + 
    "\n Use the URL first, then the title if needed." + 
    "\n If still uncertain, make your best guess or choose your fallback category.";
  
    return classification_prompt;
  };
  const apiEndpoints1 = [
    "https://m5p5ee4g5l.execute-api.us-east-2.amazonaws.com",
  ];
  const apiEndpoints = [
    "https://2o6rr7idubwsuwbwa67lyxbj2y0kwvrv.lambda-url.us-east-2.on.aws/",
  ];

  const handleClassifyMultiple1 = async (record_ids) => {
    set_show_should_we_classify_urls_modal(false);
    // Filter out already classified URLs
    let unclassifiedData = filtered_scored_data.filter(
      (item) => !item.classification // Assuming "classification" property indicates if URL is already classified
    );

    let filteredDataWithRecordIds = unclassifiedData.filter((item) =>
      record_ids.includes(item.search_result_id)
    );

    let BlogURLs_list = [
      ...new Set(
        filteredDataWithRecordIds.map((item) => ({
          blog_url: item.blog_url,
          blog_title: item.blog_title,
        }))
      ),
    ];
    let uniqueBlogURLs = BlogURLs_list.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.blog_url === value.blog_url)
    );

    console.log(uniqueBlogURLs);

    const totalRows = uniqueBlogURLs.length;
    const batchSize = 10; // Number of API calls per batch
    const totalBatches = Math.ceil(totalRows / batchSize);

    let updatedData = [...filtered_scored_data];

    const classifyURL = async (blogURL) => {
      const index = uniqueBlogURLs.indexOf(blogURL);
      const record_id = record_ids[index];
      const classification_prompt = get_prompt(blogURL);
      const apiIndex = index % apiEndpoints.length;

      // If credit points deducted successfully, proceed with URL classification
      const response = await fetch(apiEndpoints[apiIndex], {
        method: "POST",
        body: JSON.stringify({
          request_data: { prompt: classification_prompt },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(
          `Classification result for url ${blogURL["blog_url"]}:`,
          data.response
        );

        // Update data based on blog_url
        updatedData = updatedData.map((item) =>
          item.blog_url === blogURL["blog_url"]
            ? { ...item, classification: data.response }
            : item
        );

        set_filtered_scored_data(updatedData);
        setErrorFetchMassage(null);
      } else {
        setErrorFetchMassage("There is no data to classify");
        console.error(
          `Error classifying url ${blogURL["blog_url"]}:`,
          response.statusText
        );
      }
    };

    // Classify only unclassified URLs
    const classifyBatch = async (batchIndex) => {
      const startIndex = batchIndex * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batchUniqueBlogURLs = uniqueBlogURLs.slice(startIndex, endIndex);

      const classifyBatchURLs = batchUniqueBlogURLs.map(classifyURL);
      await Promise.all(classifyBatchURLs);

      if (startIndex + batchSize < totalRows) {
        classifyBatch(batchIndex + 1); // Proceed to the next batch
      }
    };

    classifyBatch(0); // Start with the first batch
  };
  const handleClassifyMultiple2 = async (record_ids) => {
    set_show_should_we_classify_urls_modal(false);
    setIsClassifying(true);
    setProgressMessage("Classifying...");

    // Filter the data for the provided record IDs
    let filteredDataWithRecordIds = filtered_scored_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );

    // Create a unique list of blog URLs
    let BlogURLs_list = [
      ...new Set(
        filteredDataWithRecordIds.map((item) => ({
          blog_url: item.blog_url,
          blog_title: item.blog_title,
        }))
      ),
    ];

    // Remove duplicates based on blog_url
    let uniqueBlogURLs = BlogURLs_list.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.blog_url === value.blog_url)
    );

    const totalRows = uniqueBlogURLs.length;
    let updatedData = [...filtered_scored_data];

    setClassificationProgress({ processed: 0, total: totalRows });

    // Queue to manage the batch processing
    let queue = [...uniqueBlogURLs]; // Initialize queue with all the blog URLs

    // Helper function to classify a single URL
    const classifyURL = async (blogURL, record_id, apiIndex) => {
      const classification_prompt = get_prompt(blogURL);

      try {
        const response = await fetch(apiEndpoints[apiIndex], {
          method: "POST",
          body: JSON.stringify({
            request_data: { prompt: classification_prompt },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          updatedData = updatedData.map((item) =>
            item.blog_url === blogURL.blog_url
              ? { ...item, classification: data.response }
              : item
          );
          set_filtered_scored_data(updatedData);
          setErrorFetchMassage(null);
        } else {
          console.error(
            `Error classifying url ${blogURL.blog_url}:`,
            response.statusText
          );
        }
      } catch (error) {
        console.error(`Error classifying url ${blogURL.blog_url}:`, error);
      }
    };

    // Function to process the queue in batches
    const processQueue = async () => {
      const BATCH_SIZE = 900;
      let batchIndex = 0;

      while (queue.length > 0) {
        const currentBatch = queue.slice(0, BATCH_SIZE); // Take the first BATCH_SIZE elements
        queue = queue.slice(BATCH_SIZE); // Remove processed elements from queue

        const classifyBatch = currentBatch.map(async (blogURL, index) => {
          const record_id = record_ids[batchIndex + index];
          const apiIndex = (batchIndex + index) % apiEndpoints.length;
          await classifyURL(blogURL, record_id, apiIndex);
        });

        await Promise.all(classifyBatch); // Wait for the batch to complete

        // Update classification progress
        setClassificationProgress((prevProgress) => ({
          processed: prevProgress.processed + currentBatch.length,
          total: prevProgress.total,
        }));

        batchIndex += BATCH_SIZE;
      }

      // Once all batches are processed
      setProgressMessage("Classified");
      setIsClassifying(false); // Set isClassifying to false after all batches are done
    };

    // Start processing the queue
    await processQueue();
  };

  const handleClassifyMultiple = async (record_ids) => {
    set_show_should_we_classify_urls_modal(false);
    setIsClassifying(true);
    setProgressMessage("Classifying...");

    // Filter the data for the provided record IDs
    let filteredDataWithRecordIds = filtered_scored_data.filter((item) =>
      record_ids.includes(item.search_result_id)
    );

    // Create a unique list of blog URLs
    let BlogURLs_list = [
      ...new Set(
        filteredDataWithRecordIds.map((item) => ({
          blog_url: item.blog_url,
          blog_title: item.blog_title,
        }))
      ),
    ];

    // Remove duplicates based on blog_url
    let uniqueBlogURLs = BlogURLs_list.filter(
      (value, index, self) =>
        index === self.findIndex((obj) => obj.blog_url === value.blog_url)
    );

    const totalRows = uniqueBlogURLs.length;
    let updatedData = [...filtered_scored_data];

    setClassificationProgress({ processed: 0, total: totalRows });

    // Initialize progress percentage
    let progressPercentage = 0;

    // Queue to manage the batch processing
    let queue = [...uniqueBlogURLs]; // Initialize queue with all the blog URLs

    // Helper function to classify a single URL
    const classifyURL = async (blogURL, record_id, apiIndex) => {
      const classification_prompt = get_prompt(blogURL);

      try {
        const response = await fetch(apiEndpoints[apiIndex], {
          method: "POST",
          body: JSON.stringify({
            request_data: { prompt: classification_prompt },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          updatedData = updatedData.map((item) =>
            item.blog_url === blogURL.blog_url
              ? { ...item, classification: data.response }
              : item
          );
          set_filtered_scored_data(updatedData);
          setErrorFetchMassage(null);
        } else {
          console.error(
            `Error classifying url ${blogURL.blog_url}:`,
            response.statusText
          );
        }
      } catch (error) {
        console.error(`Error classifying url ${blogURL.blog_url}:`, error);
      }
    };

    // Function to process the queue in batches
    const processQueue = async () => {
      const BATCH_SIZE = 900;
      let batchIndex = 0;

      while (queue.length > 0) {
        const currentBatch = queue.slice(0, BATCH_SIZE); // Take the first BATCH_SIZE elements
        queue = queue.slice(BATCH_SIZE); // Remove processed elements from queue

        const classifyBatch = currentBatch.map(async (blogURL, index) => {
          const record_id = record_ids[batchIndex + index];
          const apiIndex = (batchIndex + index) % apiEndpoints.length;
          await classifyURL(blogURL, record_id, apiIndex);

          // Update the progress percentage dynamically after each classification
          const processedCount = batchIndex + index + 1;
          progressPercentage = (processedCount / totalRows) * 100;
          setClassificationProgress({
            processed: processedCount,
            total: totalRows,
          });
        });

        await Promise.all(classifyBatch); // Wait for the batch to complete

        // Update classification progress
        setClassificationProgress((prevProgress) => ({
          processed: prevProgress.processed + currentBatch.length,
          total: prevProgress.total,
        }));

        batchIndex += BATCH_SIZE;
      }

      // Once all batches are processed
      setProgressMessage("Classified");
      setIsClassifying(false); // Set isClassifying to false after all batches are done
    };

    // Start processing the queue
    await processQueue();
  };

  const [value, setValue] = React.useState("2");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setIsFileSelectVisible(false);
  };
  const [selected_blog_url_for_classify, set_selected_blog_url_for_classify] =
    useState([]);
  const [isBlogUrlUpdated, setIsBlogUrlUpdated] = useState(false);

  const update_selected_blog_url_for_classify = (params) => {
    try {
      set_selected_blog_url_for_classify(params);
      setIsBlogUrlUpdated(true); // Update the status
    } catch (error) {
      console.error("Error setting selected blog URL for classify:", error);
      setIsBlogUrlUpdated(false); // Update the status to false in case of error
    }
  };
  const validate_classify_urls = async (action, params) => {
    if (!params || !Array.isArray(params) || params.length === 0) {
      console.error("Invalid or empty params:", params);
      return; // Exit the function early
    }

    // Extracting search_result_id list from params
    const selectedIds = params;

    update_selected_blog_url_for_classify(params);

    if (isBlogUrlUpdated) {
      const deduction_point = await getCreditPointToDeductByButton(action);

      const totalRows = selectedIds.length;
      const credits_to_be_deducted = parseFloat(
        (totalRows * parseFloat(deduction_point)).toFixed(3)
      );
      console.log("credits_to_be_deducted");
      console.log(credits_to_be_deducted);

      setDeductionPoints(credits_to_be_deducted);
      console.log("deductionPoints");
      console.log(deductionPoints);
      const data = await fetchpointsDetails(credits_to_be_deducted);
      const threshold_value = data.threshold_value;

      // Check conditions separately
      let shouldOpenModal = false;

      if (
        !data.does_owner_have_enough_credits &&
        !data.does_user_have_enough_credits_week_limit
      ) {
        setErrorMassageForTotalCredits("Insufficient Credit Points");
        setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
        shouldOpenModal = true;
      } else if (!data.does_owner_have_enough_credits) {
        setErrorMassageForTotalCredits("Insufficient Credit Points");
        shouldOpenModal = true;
      } else if (!data.does_user_have_enough_credits_week_limit) {
        setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
        shouldOpenModal = true;
      } else if (credits_to_be_deducted === 0) {
        setErrorMessage(
          "There are no blog URLs available for scraping at the moment."
        );
        shouldOpenModal = true;
      }

      // Check if modal should be opened or execute processScrapeAndUpdate
      if (shouldOpenModal) {
        set_show_should_we_classify_urls_modal(true);
      } else if (threshold_value > credits_to_be_deducted) {
        await handleClassifyMultiple(selected_blog_url_for_classify);

        await updateCreditPoints(credits_to_be_deducted);
      } else {
        set_show_should_we_classify_urls_modal(true);
      }
    }
  };

  const processClassifyProcessAndUpdate = async () => {
    await handleClassifyMultiple(selected_blog_url_for_classify);
    // if (!ClassifyProcessInProgress) {
    //   await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    // }
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    // setIsClassifying(false);
  };
  const saveProjectData = () => {
    fetch(getFullApiUrl(UPDATE_INDIVIDUAL_CAMPAIGN_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, classify: 1 },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // set_saving(false);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  useEffect(() => {
    console.log("Filtered scored data updated:", filtered_scored_data);
  }, [filtered_scored_data]);

  return (
    <>
      <div className="classify_page_container">
        <div className="data_option_section_container">
          <TabContext value={value}>
            <Box sx={{ width: "51rem", bgcolor: "background.paper" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  component="div"
                  label={
                    <div className="classify_tab_label_text">
                      Previously Classified data
                    </div>
                  }
                  value="1"
                />
                <Tab
                  component="div"
                  label={
                    <div className="classify_tab_label_text">
                      Classify on existing data
                    </div>
                  }
                  value="2"
                />
                <Tab
                  component="div"
                  label={
                    <div className="classify_tab_label_text">
                      Classify on Uploaded data
                    </div>
                  }
                  value="3"
                />
              </TabList>
            </Box>

            <TabPanel value="1">
              <div style={{ margin: "auto", width: "fit-content" }}>
                <Button
                  component="label"
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  onClick={load_previously_classified_data}
                >
                  Load
                </Button>
              </div>
            </TabPanel>

            <TabPanel value="2">
              I want to classify blog urls which has similarity score between{" "}
              <TextField
                id="outlined-number"
                type="number"
                variant="standard"
                value={filter_similarity_score.from}
                onChange={(event) => {
                  var inputValue = event.target.value;
                  console.log(inputValue);
                  console.log(filter_similarity_score.to);
                  if (
                    inputValue >= -100 &&
                    inputValue <= 100 &&
                    inputValue <= parseInt(filter_similarity_score.to)
                  ) {
                    console.log("condition pass");
                    set_filter_similarity_score({
                      from: inputValue,
                      to: filter_similarity_score.to,
                    });
                  }
                }}
                sx={{
                  width: "55px",
                }}
              />{" "}
              and{" "}
              <TextField
                id="outlined-number"
                type="number"
                variant="standard"
                value={filter_similarity_score.to}
                onChange={(event) => {
                  var inputValue = event.target.value;
                  console.log(filter_similarity_score.from);
                  console.log(inputValue);
                  if (
                    inputValue >= -100 &&
                    inputValue <= 100 &&
                    inputValue >= parseInt(filter_similarity_score.from)
                  ) {
                    console.log("condition pass");
                    set_filter_similarity_score({
                      from: filter_similarity_score.from,
                      to: inputValue,
                    });
                  }
                }}
                sx={{
                  width: "50px",
                }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                sx={{
                  marginRight: "10px",
                }}
                variant="text"
                color="secondary"
                size="small"
                onClick={() => {
                  get_identified_paragraphs_data_filtered();
                }}
              >
                load the data
              </Button>
            </TabPanel>

            <TabPanel value="3">
              <div className="fileUploadContainer">
                <Button
                  className="fileUploadButton"
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <Input
                    type="file"
                    id="file-input"
                    onChange={upload_user_uploaded_file}
                    key={file_upload_field_key}
                    style={{
                      clip: "rect(0 0 0 0)",
                      clipPath: "inset(50%)",
                      height: 1,
                      overflow: "hidden",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      whiteSpace: "nowrap",
                      width: 1,
                    }}
                  />
                </Button>
              </div>
            </TabPanel>
          </TabContext>
        </div>
        {isFileSelectVisible ? (
          <>
            <div className="uploadedFileContainer">
              <div>
                {upload_file_fields.map((field, index) => (
                  <div key={index} className="uploadedFileItem">
                    <span>
                      {/* Display corresponding field header name from tableFields */}
                      {tableFields.find((column) => column.field === field)
                        ?.headerName || ` ${field}`}
                    </span>
                    <FormControl>
                      <InputLabel>Select Field</InputLabel>
                      <Select
                        value={
                          columns_to_select.find(
                            (column) => column.csv_field === field
                          )?.key || ""
                        }
                        onChange={(e) =>
                          handleColumnSelection(e.target.value, field)
                        }
                      >
                        <MenuItem value="">
                          <em>Select Field</em>
                        </MenuItem>
                        {columns_to_select.map((option) => (
                          <MenuItem
                            key={option.key}
                            value={option.key}
                            disabled={columns_to_select.some(
                              (column) =>
                                column.key === option.key &&
                                column.csv_field !== ""
                            )}
                          >
                            {option.text}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Choose a field to map</FormHelperText>
                    </FormControl>
                  </div>
                ))}
              </div>
              <Button
                className="columnSelectionContainer"
                variant="contained"
                size="small"
                onClick={() => {
                  load_user_uploaded_file_data();
                }}
                style={{
                  marginTop: "2%",
                }}
                disabled={
                  columns_to_select.some(
                    (column) =>
                      column.key === "blog_url" && column.csv_field !== ""
                  )
                    ? false
                    : true
                }
              >
                Load the Data
              </Button>
            </div>
          </>
        ) : null}
        <div className="data_table_section_container">
          {initial_data_loaded_identified_paragraphs === 1 ? (
            <div style={{ position: "relative", width: "100%", height: "70%" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {isClassifying && (
                <div className="classification_progress_bar">
                  <LinearProgress
                    sx={{
                      height: "5px",
                      "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#26d426",
                      },
                      "& .MuiLinearProgress-bar2Buffer": {
                        backgroundColor: "#f15757",
                      },
                      "& .css-8ub8io-MuiLinearProgress-dashed": {
                        backgroundImage:
                          "radial-gradient(rgb(255 255 255) 0%, rgb(0 127 255) 16%, #ffffff 42%);",
                      },
                    }}
                    variant="determinate"
                    value={
                      (classificationProgress.processed /
                        classificationProgress.total) *
                      100
                    }
                  />

                  <Typography
                    style={{
                      position: "absolute",
                      color: "#00f",
                      top: -22,
                      left: `${
                        (classificationProgress.processed /
                          classificationProgress.total) *
                        50
                      }%`,
                      transform: "translateX(-25%)",
                    }}
                  >
                    {(
                      (classificationProgress.processed /
                        classificationProgress.total) *
                      100
                    ).toFixed(0)}
                    %
                  </Typography>
                  <div className="classification_progress_bar_title">
                    {progressMessage}
                  </div>
                </div>
              )}

              {errorFetchMassage ? (
                <div className="alert_massage_container">
                  <Alert severity="error">{errorFetchMassage}</Alert>
                </div>
              ) : (
                <TableWithPagination
                  file_name={"classify_prospect_page_types_data"}
                  data={filtered_scored_data}
                  fields={[
                    {
                      type: "id",
                      field: "search_result_id",
                      headerName: "",
                      minWidth: 1,
                      width: 1,
                      maxWidth: 90,
                    },
                    {
                      type: "field",
                      field: "client_url",
                      headerName: "Client URL",
                      minWidth: 200,
                      width: 350,
                      maxWidth: 1500,
                      search_type: "text_search",
                    },
                    {
                      type: "field",
                      field: "blog_url",
                      headerName: "Blog URL",
                      minWidth: 200,
                      width: 350,
                      maxWidth: 1000,
                      search_type: "text_search",
                    },
                    {
                      type: "field",
                      field: "classification",
                      headerName: "Page Type",
                      minWidth: 100,
                      width: 300,
                      maxWidth: 1000,
                      search_type: "text_search",
                    },
                    {
                      type: "field",
                      field: "similarity_score",
                      headerName: "Similarity Score",
                      minWidth: 100,
                      width: 200,
                      maxWidth: 500,
                      search_type: "number_search",
                    },
                  ]}
                  bulk_actions={[
                    {
                      action: "save",
                      renderCell: (params) => {
                        return (
                          <>
                            {saving ? (
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={true}
                                startIcon={<CircularProgress size={20} />}
                              >
                                Saving...
                              </Button>
                            ) : (
                              <Tooltip title="Save">
                                <IconButton
                                  onClick={() => {
                                    handleSaveMultiple(params);
                                  }}
                                >
                                  <FaSave className="table_action_icon_link_exchange_row" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        );
                      },
                    },
                    {
                      action: "classify",
                      renderCell: (params) => {
                        return (
                          <Tooltip title="classify">
                            <IconButton
                              onClick={() => {
                                validate_classify_urls("Classify URL", params);
                                // handleClassifyMultiple(params);
                              }}
                            >
                              <FaProjectDiagram className="table_action_icon_link_exchange_row" />
                            </IconButton>
                          </Tooltip>
                        );
                      },
                    },
                  ]}
                />
              )}
            </>
          )}
        </div>
        <Modal
          open={show_should_we_classify_urls_modal}
          onClose={handle_should_we_classify_urls_close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box id="LinkExchange_model_box">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={handle_should_we_classify_urls_close}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="LinkExchange_modal_header">Current Credits</div>
            <div className="LinkExchange_modal_text_heading">
              Before proceeding with this action, please review the following:
            </div>
            <div className="LinkExchange_modal_text">
              Available Credits:{" "}
              <div className="LinkExchange_modal_number">
                <div
                  style={{
                    color: errorMassageForTotalCredits ? "red" : "inherit",
                  }}
                >
                  {totalCreditPoints}
                </div>
              </div>
            </div>
            <div className="LinkExchange_modal_text">
              Weekly Credits Limit Left:{" "}
              <div className="LinkExchange_modal_number">
                <div
                  style={{
                    color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                  }}
                >
                  {creditPointsLimit}
                </div>
              </div>
            </div>
            {errorMessage !==
              "There are no blog URLs available for scraping at the moment." && (
              <div className="LinkExchange_modal_text_deduct">
                Performing this action will deduct{" "}
                <div className="LinkExchange_modal_deduct_number">
                  {" "}
                  {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
                </div>{" "}
                from your balance.
              </div>
            )}
            {(errorMessage ||
              errorMassageForTotalCredits ||
              errorMassageForCreditWeekLimits) && (
              <div
                style={{
                  color: "red",
                  textAlign: "center",

                  fontWeight: "bolder",
                  fontFamily: "monospace",
                  fontSize: "larger",
                }}
              >
                <div>{errorMassageForTotalCredits}</div>
                <div>{errorMassageForCreditWeekLimits}</div>
                <div>{errorMessage}</div>
              </div>
            )}

            {!(
              errorMessage ||
              errorMassageForTotalCredits ||
              errorMassageForCreditWeekLimits
            ) && (
              <>
                <div className="LinkExchange_modal_header">After Deduction</div>
                <div className="LinkExchange_modal_text">
                  Balance Credits :
                  <div className="LinkExchange_modal_number">
                    {totalCreditAfterDeduction}
                  </div>
                </div>
                <div className="LinkExchange_modal_text">
                  Weekly Credits Limit Left :{" "}
                  <div className="LinkExchange_modal_number">
                    {creditWeekLimitAfterDeduction}
                  </div>
                </div>

                <div className="LinkExchange_form_group">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={processClassifyProcessAndUpdate}
                    color="primary"
                  >
                    Confirm Action
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={notificationMessage}
        />
      </div>
    </>
  );
};

export default ClassifyResultPagesComponent;
