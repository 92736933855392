import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button} from '@mui/material';
//import { useNavigate } from 'react-router-dom';

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';

import './LinkInsertionCampaignFlow.css';
import {  GET_CAMPAIGN_DATA, getFullApiUrl} from '../../../utils/apiPath';


const LinkInsertionCampaignFlow = ({ data_from_LIC_to_Flow, send_data_from_Flow_to_LIC }) =>
{
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [campaign_data, set_campaign_data] = useState(data_from_LIC_to_Flow["campaign_data"]);

  useEffect(() => {
    fetch(getFullApiUrl(GET_CAMPAIGN_DATA), {
      method: 'POST',
      body: JSON.stringify({ "request_data": { "campaign_id": campaignId } }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((data) => {
      on_load_set_campaign_data(data["data"]);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, []);

  const goto = (page) =>
  {
      navigate(`/my_campaign/${campaignId}/${page}`);
  }
/*
<Button sx={{marginRight: '10px'}}
  variant="contained"
  color="secondary"
  size="small"
  onClick={() => {sendDataToParentOnClick("active_bloggers")}}>Check Active Bloggers</Button>
*/
  var initialNodes = [
    { id: '1', position: { x: 260, y: 0 }, data: { label: <div onClick={() => {goto("client_urls")}}>Add Target URLs</div> }, for:'add_client_urls' },
    // { id: '2', position: { x: 60, y: 100 }, data: { label: <div onClick={() => {goto("check_active_bloggers_using_serper")}}>Check Bloggers<br/> with Apify</div> }, for:'check_active_bloggers_using_serper' },
    { id: '3', position: { x: 260, y: 60 }, data: { label: <div onClick={() => {goto("check_active_bloggers_using_serper")}}> Scrape Prospect URLs<br/>From Google</div> }, for:'check_active_bloggers_using_serper' },
    { id: '4', position: { x: 35, y: 30 }, data: { label: 'Condition Check For Search' } },
    { id: '5', position: { x: 35, y: 110 }, data: { label: <div onClick={() => {goto("black_list")}}>Black List</div> }, for: 'black_list' },

    // { id: '6', position: { x: 550, y: 160 }, data: { label: 'Combine all Result' } },
    { id: '7', position: { x: 260, y: 140 }, data: { label: <div onClick={() => {goto("blog_urls")}}>Prepare Prospects for Analysis </div> }, for:'scrap_blog_pages' },
    { id: '8', position: { x: 260, y: 215 }, data: { label: <div onClick={() => {goto("bulk_process")}}>Match Prospects with Target URLs</div> }, for:'run_bulk_similarity_check' },
    { id: '9', position: { x: 260, y: 290 }, data: { label: <div onClick={() => {goto("classify_result_page")}}>Classify Prospect Page Types</div> }, for:'classify' },
    { id: '14', position: { x: 260, y: 365 }, data: { label: <div onClick={() => {goto("page_quality_check")}}>Page Quality Check</div> }, for:'page_quality_check' },
    // { id: '9', position: { x: 300, y: 395 }, data: { label: <div onClick={() => {sendDataToParentOnClick("classify_result_pages")}}> Classify </div> }, for:'classify' },
    // { id: '14', position: { x: 300, y: 455 }, data: { label: <div onClick={() => {sendDataToParentOnClick("page_quality_check")}}> Page Quality Check </div> }, for:'page_quality_check' },
                                            // data: { label: 'Classify' } },classify_result_pages
    { id: '10', position: { x: 260, y: 425 }, data: { label: <div onClick={() => {goto("identify_paragraphs")}}>Inspect Matches Individually</div> }, for:'selection_page' },
    { id: '11', position: { x: 40, y: 460 }, data: { label: <div onClick={() => {goto("screenshot")}}>My Screenshot tool</div>  } , for: 'screenshot'},
    { id: '12', position: { x: 260, y: 500 }, data: { label: 'Email Setup Page' } },

    // { id: '13', position: { x: 550, y: 220 }, className:"instruction", data: { label: <div className="instruction_text"></div> } },
  ];
  var initialEdges = [
    { id: 'e1-3', source: '1', target: '3', type: 'smoothstep', for: 'check_active_bloggers_using_serper' },
    // { id: 'e1-4', source: '1', target: '4', type: 'smoothstep', for: 'explore_a_blogger_website' },
    // { id: 'e1-5', source: '1', target: '5', type: 'smoothstep', for: 'black_list' },

    { id: 'e4-3', source: '1', target: '4' },
    { id: 'e4-6', source: '3', target: '5' },
    { id: 'e5-6', source: '5', target: '6', type: 'smoothstep' },

    { id: 'e6-7', source: '3', target: '7', type: 'smoothstep', for: 'scrap_blog_pages' },
    { id: 'e7-8', source: '7', target: '8', type: 'smoothstep', for: 'run_bulk_similarity_check' },
    { id: 'e8-9', source: '8', target: '9', type: 'smoothstep' , for :'classify' },
    { id: 'e9-10', source: '9', target: '10', type: 'smoothstep', for: 'selection_page' },

    { id: 'e10-11', source: '10', target: '11' },
    { id: 'e10-12', source: '10', target: '12', type: 'smoothstep' , for: 'screenshot' },
];

  var [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  var [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  var onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const addClassToLabel = (nodeId, classNameToAdd) => {
    // Find the node you want to update by its ID
    const updatedNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        console.log(node.id)
        // Clone the node and add the new class
        node = {
          ...node,
          data: {
            ...node.data,
            label: React.cloneElement(node.data.label, {
              className: `${node.data.label.props.className} ${classNameToAdd}`,
            }),
          },
        };

        node = {
          ...node,
          className: `${node.className} ${classNameToAdd}`,
        };
        console.log(node)

      }
      return node;
    });

    // Update the state with the new nodes
    setNodes(updatedNodes);
  };
  const addArrowToEdge = (edgeId) => {
    // Find the edge you want to update by its ID
    const updatedEdges = edges.map((edge) => {
      if (edge.id === edgeId) {
        // Update the edge's properties
        edge.markerEnd = {
          type: MarkerType.ArrowClosed,
          width: 14,
          color: '#219a14',
        };
        edge.style = {
          strokeWidth: 2,
          stroke: '#219a14',
        };
      }
      return edge;
    });

    // Update the state with the new edges
    setEdges(updatedEdges);
  };

  // update_nodes.push({"id":initialNodes[i].id, "class":"work_done_here"})

  const set_instructions = (instructions, update_nodes) => {
    const nodeId = "13";
    const instructions_html = (
      <div className="instruction_texts">
        {instructions.map((instruction, index) => (
          <div className="instruction_text" key={index}>{instruction}</div>
        ))}
      </div>
    );
    //console.log(instructions_html);
    const updatedNodes = nodes.map((node) => {
      if (node.id === nodeId)
      {
        node = {
          ...node,
          data:{label: instructions_html},
        };
      }
      if (update_nodes.includes(node.id))
      {
        node = {
          ...node,
          className: `${node.className} ${"work_done_here"}`,
        };
      }
      return node;
    });

    setNodes(updatedNodes);
  };


  const on_load_set_instructions = (instructions_to_be_sent, update_nodes) => {
    let i = 0;
    let j = 0;
    var speed = 80;
    var instructions_to_be_print = []
    const displayInstructions = () => {
      if (i < instructions_to_be_sent.length)
      {
        const currentInstruction = instructions_to_be_sent[i];
        if (j < currentInstruction.length)
        {
          instructions_to_be_print = instructions_to_be_sent.slice(0, i + 1)
          instructions_to_be_print[instructions_to_be_print.length - 1] = instructions_to_be_print[instructions_to_be_print.length - 1].slice(0, j + 1);
          //console.log("*************************************");
          //console.log(instructions_to_be_print);
          //console.log(instructions_to_be_print[instructions_to_be_print.length - 1] = instructions_to_be_print[instructions_to_be_print.length - 1].slice(0, j + 1));
          //set_instructions(instructions_to_be_sent.slice(0, i + 1));
          set_instructions(instructions_to_be_print, update_nodes);
          if(speed>30)
            speed=speed-1;
          //console.log(speed)
          setTimeout(displayInstructions, speed);
          j++;
        }
        else {
          i++;
          j = 0;
          displayInstructions();
        }
      }
    };
    displayInstructions();
  };

/*
  const on_load_set_instructions = (instructions_to_be_sent) => {
    let i = 0;
    const displayInstructions = () => {
      if (i < instructions_to_be_sent.length)
      {
        set_instructions(instructions_to_be_sent.slice(0, i + 1));
        i++;
        setTimeout(displayInstructions, 1500);
      }
    };
    displayInstructions();
  };
*/

  const on_load_decide_instructions = (new_campaign_data, update_nodes) => {
    var instructions = {
        "add_client_urls" : [
          "It seems you didn't added client Urls",
          "to start with please add some client urls first",
          "to add client urls you need to click on the first node : [Add  your Client links] "
        ],
        "check_active_bloggers" : [
          "It seems you added client Urls",
          "now it's time for finding blog pages",
          "for that we have 4 options [Add  your Client links], [Explore a blogger website], [Google search], [ahref search]"
        ],
        "scrap_blog_pages" : [
          "It seems you added client Urls and found blog pages also",
          "now it's time to scrape those blog pages",
          "for that you need to click on the 4rth level node : [Scrap all blog pages] "
        ],
        "run_bulk_similarity_check" : [
          "It seems you added client Urls and scrapped blog pages also",
          "now it's time to do a similarity check",
          "for that you need to click on the 5'th level node : [Run Bulk Similarity Check] "
        ],
        "selection_page" : [
          "It seems you added client Urls and scrapped blog pages also and got the similarity scores",
          "now it's time to finalize the blog pages",
          "for that you need to click on the 6'th level node : [Selection page] and/or [My Screenshot tool]"
        ]
    }
    var instructions_to_be_sent = [
      "Hey there! 👋",
      "How's your day going?",
    ]
    var add_instruction = 0
    for (const key in instructions)
    {
      if (!(key in new_campaign_data) || new_campaign_data[key] === 0)
      {
        //add_instruction = true;
        instructions_to_be_sent.push(...instructions[key]);
        break;
      }
    }
    if(add_instruction)
    {
      //instructions_to_be_sent.push(...instructions[key]);
    }
    //console.log(instructions_to_be_sent);
    on_load_set_instructions(instructions_to_be_sent, update_nodes)
  }

  const on_load_set_campaign_data = (new_campaign_data) => {
    //console.log(new_campaign_data)
    for (let i = 0; i < initialEdges.length; i++)
    {
      if (new_campaign_data !== undefined)
      {
        if (initialEdges[i].for)
        {
          if(new_campaign_data.hasOwnProperty(initialEdges[i].for))
          {
            if(parseInt(new_campaign_data[initialEdges[i].for]) === 1)
            {
              addArrowToEdge(initialEdges[i].id)
            }
          }
        }
      }
    }
    var update_nodes = []
    for (let i = 0; i < initialNodes.length; i++)
    {
      //console.log("got 5")
      if (new_campaign_data !== undefined)
      {
        //console.log("got 4")
        if (initialNodes[i].for)
        {
          //console.log("got 3")
          if(new_campaign_data.hasOwnProperty(initialNodes[i].for))
          {
            //console.log("got 2")
            if(parseInt(new_campaign_data[initialNodes[i].for]) === 1)
            {
              //console.log("got 1")
              //console.log(initialNodes[i])
              //addClassToLabel(initialNodes[i].id, "work_done_here")
              //update_nodes.push({"id":initialNodes[i].id, "class":"work_done_here"})
              update_nodes.push(initialNodes[i].id)
            }
          }
        }
      }
      }
    // on_load_decide_instructions(new_campaign_data, update_nodes);
  }
  //on_load_set_campaign_data(campaign_data);


  const sendDataToParentOnClick = (which_component) => {
    var dataToSend = {}
    dataToSend["which_component"] = which_component;
    send_data_from_Flow_to_LIC(dataToSend);
  };

  return (
    <div className="flow_Container">
      <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
        >
          <MiniMap />
          <Controls />
          <Background />
      </ReactFlow>
    </div>
  );

}
export default LinkInsertionCampaignFlow;
